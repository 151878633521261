<template>
  <div id="WIPKPI">
    <h6>Round 1</h6>
    <div id="wip-round1" class="pb-8">
      <table
        class="tg"
        id="basicWIPTable"
        v-if="round1 != undefined && !round1.isAdvanced"
      >
        <thead>
          <tr>
            <th class="main whiteFont font fontMedium bold size td">Role</th>
            <th class="main whiteFont font fontMedium bold size td">Inbox</th>
            <th class="main whiteFont font fontMedium bold size tdd">Outbox</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td class="main whiteFont font bold alignCenter">
              CL INCOMING GOODS
            </td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">
              HD INCOMING GOODS
            </td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">CL ROUTE TARIF</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">
              CL WEIGHT TARIF
            </td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">CL ACCOUNTING</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">HD FINANCE</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">
              CL OUTGOING GOODS
            </td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">
              HD OUTGOING GOODS
            </td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">CUSTOMER</td>
            <td class="inputBox borderedBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">MESSENGER</td>
            <td class="inputBox borderedBox"></td>
            <td class="inputBox">---</td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">COURIER</td>
            <td class="inputBox borderedBox"></td>
            <td class="inputBox">---</td>
          </tr>
          <tr>
            <td class="accent whiteFont font bold alignCenter">OVERALL WIP</td>
            <td class="inputBox"></td>
          </tr>
        </tbody>
      </table>

      <table
        class="tg"
        id="advWIPTable"
        v-else-if="false && round1 != undefined"
      >
        <thead>
          <tr>
            <th class="main whiteFont font fontMedium bold size td">
              Activity
            </th>
            <th class="main whiteFont font fontMedium bold size td">Inbox</th>
            <th class="main whiteFont font fontMedium bold size tdd">Outbox</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td class="main whiteFont font bold alignCenter">1</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">2</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">3</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">4</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">5</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="accent whiteFont font bold alignCenter">OVERALL WIP</td>
            <td class="inputBox"></td>
          </tr>
        </tbody>
      </table>

      <div v-else-if="round1 && round1.isAdvanced && round1 != undefined">
        <div v-if="lang == 'de'">
          Keine WIP-Analyse für Improvement Runden! (1. Runde)
        </div>
        <div v-else>No WIP Analysis for Improvement Rounds! (1. Round)</div>
      </div>
    </div>

    <h6>Round 2</h6>
    <div id="wip-round2" class="pb-8">
      <table
        class="tg"
        id="basicWIPTable"
        v-if="round2 != undefined && !round2.isAdvanced"
      >
        <thead>
          <tr>
            <th class="main whiteFont font fontMedium bold size td">Role</th>
            <th class="main whiteFont font fontMedium bold size td">Inbox</th>
            <th class="main whiteFont font fontMedium bold size tdd">Outbox</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td class="main whiteFont font bold alignCenter">
              CL INCOMING GOODS
            </td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">
              HD INCOMING GOODS
            </td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">CL ROUTE TARIF</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">
              CL WEIGHT TARIF
            </td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">CL ACCOUNTING</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">HD FINANCE</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">
              CL OUTGOING GOODS
            </td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">
              HD OUTGOING GOODS
            </td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">CUSTOMER</td>
            <td class="inputBox borderedBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">MESSENGER</td>
            <td class="inputBox borderedBox"></td>
            <td class="inputBox">---</td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">COURIER</td>
            <td class="inputBox borderedBox"></td>
            <td class="inputBox">---</td>
          </tr>
          <tr>
            <td class="accent whiteFont font bold alignCenter">OVERALL WIP</td>
            <td class="inputBox"></td>
          </tr>
        </tbody>
      </table>

      <table
        class="tg"
        id="advWIPTable"
        v-else-if="false && round2 != undefined"
      >
        <thead>
          <tr>
            <th class="main whiteFont font fontMedium bold size td">
              Activity
            </th>
            <th class="main whiteFont font fontMedium bold size td">Inbox</th>
            <th class="main whiteFont font fontMedium bold size tdd">Outbox</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td class="main whiteFont font bold alignCenter">1</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">2</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">3</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">4</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">5</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="accent whiteFont font bold alignCenter">OVERALL WIP</td>
            <td class="inputBox"></td>
          </tr>
        </tbody>
      </table>

      <div v-else-if="round2 && round2.isAdvanced && round2 != undefined">
        <div v-if="lang == 'de'">
          Keine WIP-Analyse für Improvement Runden! (2. Runde)
        </div>
        <div v-else>No WIP Analysis for Improvement Rounds! (2. Round)</div>
      </div>
    </div>

    <h6>Round 3</h6>
    <div id="wip-round3">
      <table
        class="tg"
        id="basicWIPTable"
        v-if="round3 != undefined && !round3.isAdvanced"
      >
        <thead>
          <tr>
            <th class="main whiteFont font fontMedium bold size td">Role</th>
            <th class="main whiteFont font fontMedium bold size td">Inbox</th>
            <th class="main whiteFont font fontMedium bold size tdd">Outbox</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td class="main whiteFont font bold alignCenter">
              CL INCOMING GOODS
            </td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">
              HD INCOMING GOODS
            </td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">CL ROUTE TARIF</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">
              CL WEIGHT TARIF
            </td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">CL ACCOUNTING</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">HD FINANCE</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">
              CL OUTGOING GOODS
            </td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">
              HD OUTGOING GOODS
            </td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">CUSTOMER</td>
            <td class="inputBox borderedBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">MESSENGER</td>
            <td class="inputBox borderedBox"></td>
            <td class="inputBox">---</td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">COURIER</td>
            <td class="inputBox borderedBox"></td>
            <td class="inputBox">---</td>
          </tr>
          <tr>
            <td class="accent whiteFont font bold alignCenter">OVERALL WIP</td>
            <td class="inputBox"></td>
          </tr>
        </tbody>
      </table>

      <table
        class="tg"
        id="advWIPTable"
        v-else-if="false && round3 != undefined"
      >
        <thead>
          <tr>
            <th class="main whiteFont font fontMedium bold size td">
              Activity
            </th>
            <th class="main whiteFont font fontMedium bold size td">Inbox</th>
            <th class="main whiteFont font fontMedium bold size tdd">Outbox</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td class="main whiteFont font bold alignCenter">1</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">2</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">3</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">4</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="main whiteFont font bold alignCenter">5</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr>
            <td class="accent whiteFont font bold alignCenter">OVERALL WIP</td>
            <td class="inputBox"></td>
          </tr>
        </tbody>
      </table>

      <div v-else-if="round3 && round3.isAdvanced && round3 != undefined">
        <div v-if="lang == 'de'">
          Keine WIP-Analyse für Improvement Runden! (3. Runde)
        </div>
        <div v-else>No WIP Analysis for Improvement Rounds! (3. Round)</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "financeKPI",

  props: ["options", "tableData"],

  data() {
    return {
      lang: localStorage.getItem("lang"),
      round1: undefined,
      round2: undefined,
      round3: undefined,
    };
  },

  components: {},

  watch: {
    tableData: function () {
      if (!this.tableData.rounds || this.tableData.rounds.length == 0) return;

      let data = [];
      let sortingHelper = [
        this.tableData.round1,
        this.tableData.round2,
        this.tableData.round3,
      ];
      for (let i = 0; i < this.tableData.rounds.length; i++) {
        if (
          this.tableData.rounds[i].id == this.tableData.round1 ||
          this.tableData.rounds[i].id == this.tableData.round2 ||
          this.tableData.rounds[i].id == this.tableData.round3
        ) {
          data[sortingHelper.indexOf(this.tableData.rounds[i].id)] =
            this.tableData.rounds[i].data;
        }
      }

      if (data.length == 0) return;

      this.round1 = data[0];
      this.fillWipTable(data[0], 1);

      if (data[1]) {
        this.round2 = data[1];
        this.fillWipTable(data[1], 2);
      }

      if (data[2]) {
        this.round3 = data[2];
        this.fillWipTable(data[2], 3);
      }
    },
  },
  methods: {
    fillWipTable(dataTable, number) {
      let result;

      if (dataTable.unfinishedParcels == undefined) return;

      if (dataTable.isAdvanced) {
        let refinedInboxData = [];
        let refinedOutboxData = [];
        let roleOrder = ["1", "2", "3", "4", "5"];

        let additionalCarrierParcels = 0;

        let total = 0;

        for (let i = 0; i < roleOrder.length; i++) {
          if (dataTable.unfinishedParcels[roleOrder[i]] != undefined) {
            refinedInboxData.push(dataTable.unfinishedParcels[roleOrder[i]][0]);
            total += dataTable.unfinishedParcels[roleOrder[i]][0];

            refinedOutboxData.push(
              dataTable.unfinishedParcels[roleOrder[i]][1]
            );

            additionalCarrierParcels +=
              dataTable.unfinishedParcels[roleOrder[i]][1];
          } else {
            refinedInboxData.push("");
            refinedOutboxData.push("");
          }
        }

        total += additionalCarrierParcels;

        // refinedInboxData[roleOrder.indexOf("MESSENGER")] = refinedInboxData[roleOrder.indexOf("MESSENGER")] + " +" + additionalMessengerParcels;
        // refinedInboxData[roleOrder.indexOf("COURIER")] = refinedInboxData[roleOrder.indexOf("COURIER")] + " +" + additionalCourierParcels;

        refinedInboxData.push(total);
        refinedOutboxData.push("-");

        result = {
          inbox: refinedInboxData,
          outbox: refinedOutboxData,
        };
      } else {
        let refinedInboxData = [];
        let refinedOutboxData = [];
        let roleOrder = [
          "CL_INCOMING_GOODS",
          "HD_INCOMING_GOODS",
          "CL_ROUTE_TARIF",
          "CL_WEIGHT_TARIF",
          "CL_ACCOUNTING",
          "HD_FINANCE",
          "CL_OUTGOING_GOODS",
          "HD_OUTGOING_GOODS",
          "CUSTOMER",
          "MESSENGER",
          "COURIER"
        ];

        let additionalMessengerParcels = 0;
        let additionalCourierParcels = 0;

        let total = 0;

        for (let i = 0; i < roleOrder.length; i++) {
          if (dataTable.unfinishedParcels[roleOrder[i]] != undefined) {
            refinedInboxData.push(dataTable.unfinishedParcels[roleOrder[i]][0]);
            total += dataTable.unfinishedParcels[roleOrder[i]][0];

            refinedOutboxData.push(
              dataTable.unfinishedParcels[roleOrder[i]][1]
            );

            if (
              roleOrder[i] == "CUSTOMER" ||
              roleOrder[i] == "HD_OUTGOING_GOODS"
            ) {
              additionalCourierParcels +=
                dataTable.unfinishedParcels[roleOrder[i]][1];
            } else {
              additionalMessengerParcels +=
                dataTable.unfinishedParcels[roleOrder[i]][1];
            }
          } else {
            refinedInboxData.push("");
            refinedOutboxData.push("");
          }
        }

        total += additionalMessengerParcels;
        total += additionalCourierParcels;

        refinedInboxData[roleOrder.indexOf("MESSENGER")] =
          refinedInboxData[roleOrder.indexOf("MESSENGER")] +
          " +" +
          additionalMessengerParcels;
        refinedInboxData[roleOrder.indexOf("COURIER")] =
          refinedInboxData[roleOrder.indexOf("COURIER")] +
          " +" +
          additionalCourierParcels;

        refinedInboxData.push(total);
        refinedOutboxData.push("-");

        result = {
          inbox: refinedInboxData,
          outbox: refinedOutboxData,
        };
      }

      if (!result) return;

      let name = "wip-round" + number;

      this.waitForTableLoad(name, result, number);

      return result;
    },

    waitForTableLoad(id, result, number, maxIterations = 10) {
      if (!document.getElementById(id).children[0] && maxIterations > 0) {
        --maxIterations;
        setTimeout(
          function () {
            this.waitForTableLoad(id, result, number, maxIterations);
          }.bind(this),
          1000
        );
      } else {
        this.setupFillWIPTable(undefined, result, number);
      }
    },

    setupTable(object, result, number) {
      object.setupFillWIPTable(object, result, number);
    },

    setupFillWIPTable(object, result, number) {
      let mainDiv = document
        .getElementById("wip-round" + number)
        .getElementsByTagName("tbody");
      let parentElem = mainDiv[0];

      if(!parentElem || !parentElem.children) return;
      if (result.inbox.length > 7) {
        for (let i = 0; i < parentElem.children.length; i++) {
          //iterate over table rows
          parentElem.children[i].children[1].innerHTML = result.inbox[i]; //items stored as array
          if (parentElem.children[i].children[2])
            parentElem.children[i].children[2].innerHTML = result.outbox[i];
        }
      } else {
        for (let i = 0; i < parentElem.children.length; i++) {
          //iterate over table rows
          parentElem.children[i].children[1].innerHTML = result.inbox[i]; //items stored as array
          if (parentElem.children[i].children[2])
            parentElem.children[i].children[2].innerHTML = result.outbox[i];
        }
      }
    },
  },

  mounted() {},
};
</script>
