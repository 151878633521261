<template>
  <div id="timeKPI">

    <br />

    <div id="statisticsLegendRow">
      <div style="text-align: center">
        <div style="width: 10vw">1. Quartil</div>
        <div style="width: 10vw">Median</div>
        <div style="width: 10vw">3. Quartil</div>
      </div>
      <div>
        <div v-if="lang == 'de'" width="15vw">Schnellstes Paket</div>
        <div v-else width="15vw">Fastest Parcel</div>

        <svg width="30vw" height="20px">
          <rect
            x="2%"
            y="0"
            width="1%"
            height="20px"
            stroke="none"
            stroke-width="0"
            fill="#5e5e5e"
          ></rect>
          <rect
            x="3%"
            y="8px"
            width="15%"
            height="4px"
            stroke="none"
            stroke-width="0"
            fill="#5e5e5e"
          ></rect>

          <rect
            x="18%"
            y="0"
            width="32%"
            height="20px"
            stroke="none"
            stroke-width="0"
            fill="#ff714d"
          ></rect>
          <rect
            x="50%"
            y="0"
            width="32%"
            height="20px"
            stroke="none"
            stroke-width="0"
            fill="#AD4C33"
          ></rect>

          <rect
            x="82%"
            y="8px"
            width="15%"
            height="4px"
            stroke="none"
            stroke-width="0"
            fill="#5e5e5e"
          ></rect>
          <rect
            x="97%"
            y="0"
            width="1%"
            height="20px"
            stroke="none"
            stroke-width="0"
            fill="#5e5e5e"
          ></rect>
        </svg>

        <div v-if="lang == 'de'" width="15vw">Langsamstes Paket</div>
        <div v-else width="15vw">Slowest Parcel</div>
      </div>
    </div>

    <br />

    <br />
    <div v-if="tableData.rounds">
      <h6 class="mb-1">Round 1</h6>
      <div v-if="hideRound1" class="pb-8">
        <div v-if="lang == 'de'">
          Keine Zeit-Analyse für Improvement Runden! (1. Runde)
        </div>
        <div v-else>No Time Analysis for Improvement Rounds! (1. Round)</div>
      </div>
      <GChart
        v-if="this.tableData.rounds"
        :settings="{ packages: ['timeline'] }"
        type="Timeline"
        :data="collectData(1)"
        :options="options"
        id="gChart_time1"
        @ready="cleanupTimeChart(1, dataTables[1], 'gChart_time1')"
        :style="{ display: hideRound1 ? 'none' : 'block' }"
      />
    </div>

    <div v-if="tableData.rounds && roundNumbers > 1">
      <h6>Round 2</h6>
      <div v-if="hideRound2" class="pb-8">
        <div v-if="lang == 'de'">
          Keine Zeit-Analyse für Improvement Runden! (2. Runde)
        </div>
        <div v-else>No Time Analysis for Improvement Rounds! (2. Round)</div>
      </div>
      <GChart
        v-if="this.tableData.rounds && roundNumbers > 1"
        :settings="{ packages: ['timeline'] }"
        type="Timeline"
        :data="collectData(2)"
        :options="options"
        id="gChart_time2"
        @ready="cleanupTimeChart(2, dataTables[2], 'gChart_time2')"
        :style="{ display: hideRound2 ? 'none' : 'block' }"
      />
    </div>

    <div v-if="tableData.rounds && roundNumbers > 2">
      <h6>Round 3</h6>
      <div v-if="hideRound3">
        <div v-if="lang == 'de'">
          Keine Zeit-Analyse für Improvement Runden! (3. Runde)
        </div>
        <div v-else>No Time Analysis for Improvement Rounds! (3. Round)</div>
      </div>
      <GChart
        v-if="this.tableData.rounds && roundNumbers > 2"
        :settings="{ packages: ['timeline'] }"
        type="Timeline"
        :data="collectData(3)"
        :options="options"
        id="gChart_time3"
        @ready="cleanupTimeChart(3, dataTables[3], 'gChart_time3')"
        :style="{ display: hideRound3 ? 'none' : 'block' }"
      />
    </div>
  </div>
</template>

<script>
import { GChart } from "vue-google-charts";

export default {
  name: "timeKPI",

  props: ["options", "tableData"],

  data() {
    return {
      lang: localStorage.getItem("lang"),
      roundNumbers: 1,
      hideRound1: false,
      hideRound2: false,
      hideRound3: false,
      dataTables: [],
    };
  },

  components: {
    GChart,
  },

  methods: {
    collectData(number) {
      if (!this.tableData.rounds || this.tableData.rounds.length == 0) return;

      let data = [];
      let sortingHelper = [
        this.tableData.round1,
        this.tableData.round2,
        this.tableData.round3,
      ];
      for (let i = 0; i < this.tableData.rounds.length; i++) {
        if (
          this.tableData.rounds[i].id == this.tableData.round1 ||
          this.tableData.rounds[i].id == this.tableData.round2 ||
          this.tableData.rounds[i].id == this.tableData.round3
        ) {
          data[sortingHelper.indexOf(this.tableData.rounds[i].id)] =
            this.tableData.rounds[i].data;
        }
      }

      if (data.length == 0) return;

      this.roundNumbers = data.length;
      data = data[number - 1];

      if (number == 1) {
        this.hideRound1 = data.isAdvanced;
      } else if (number == 2) {
        this.hideRound2 = data.isAdvanced;
      } else if (number == 3) {
        this.hideRound3 = data.isAdvanced;
      }

      let dataTable = [["Position", "Name", "Start", "End"]];

      dataTable.push([" ", "1", 0, 0]);
      dataTable.push([" ", "2", 0, 0]);
      dataTable.push([" ", "3", 0, 0]);
      dataTable.push([" ", "0", 0, 0]);
      dataTable.push([" ", "1", 0, 0]);
      dataTable.push([" ", "4", 0, 0]);
      dataTable.push([" ", "5", 0, 0]);
      dataTable.push([" ", "4", 0, 0]);
      dataTable.push([" ", "5", 0, 0]);

      let tempData;

      if (data.isAdvanced) {
        tempData = {
          0: [0, 0],
          1: [0, 0],
          2: [0, 0],
          3: [0, 0],
          4: [0, 0],
          5: [0, 0],
          6: [0, 0],
        };
      } else {
        tempData = {
          MESSENGER: [0, 0],
          COURIER: [0, 0],
          CL_INCOMING_GOODS: [0, 0],
          HD_INCOMING_GOODS: [0, 0],
          CL_WEIGHT_TARIF: [0, 0],
          CL_ROUTE_TARIF: [0, 0],
          CL_ACCOUNTING: [0, 0],
          HD_FINANCE: [0, 0],
          CL_OUTGOING_GOODS: [0, 0],
          HD_OUTGOING_GOODS: [0, 0],
        };
      }

      for (let k in data.timeData) {
        if (tempData[k] != undefined) {
          let sorted = false;

          while (!sorted) {
            sorted = true;

            for (let i = 0; i < data.timeData[k].length - 1; i++) {
              if (data.timeData[k][i][1] > data.timeData[k][i + 1][1]) {
                let temp = data.timeData[k][i][1];
                data.timeData[k][i][1] = data.timeData[k][i + 1][1];
                data.timeData[k][i + 1][1] = temp;

                sorted = false;
              }
            }
          }

          for (let i = data.timeData[k].length - 1; i >= 0; i--) {
            if (data.timeData[k][i][1] == 0) {
              data.timeData[k].splice(i, 1);
            }
          }

          if (data.timeData[k].length > 0) {
            //tempdata will contain following values:
            //[min, avg, max, quartil1, quartil2];
            tempData[k][0] = data.timeData[k][0][1];
            tempData[k][2] = data.timeData[k][data.timeData[k].length - 1][1];

            if ((data.timeData[k].length - 1) % 2 == 0) {
              tempData[k][1] =
                data.timeData[k][(data.timeData[k].length - 1) / 2][1];
            } else {
              tempData[k][1] =
                (data.timeData[k][
                  Math.floor((data.timeData[k].length - 1) / 2)
                ][1] +
                  data.timeData[k][
                    Math.floor((data.timeData[k].length - 1) / 2) + 1
                  ][1]) /
                2;
            }

            if (data.timeData[k].length < 4) {
              tempData[k][3] = tempData[k][0] - 1;
              tempData[k][4] = tempData[k][2] + 1;
            } else {
              tempData[k][3] = tempData[k][0];
              tempData[k][4] = tempData[k][2];

              //quartil is medium of leftover halfes
              if (Math.floor(data.timeData[k].length / 2) % 2 == 0) {
                //middle is in between 2 values
                let quartil1Index, quartil2Index;
                quartil1Index = Math.floor(
                  (Math.floor(data.timeData[k].length / 2) - 1) / 2
                );
                quartil2Index = data.timeData[k].length - 1 - quartil1Index;

                if (
                  data.timeData[k].length % 2 == 1 ||
                  data.timeData[k].length == 4
                ) {
                  // quartil1Index--;
                  quartil2Index--;
                }

                tempData[k][0] =
                  (data.timeData[k][quartil1Index][1] +
                    data.timeData[k][quartil1Index + 1][1]) /
                  2;
                tempData[k][2] =
                  (data.timeData[k][quartil2Index][1] +
                    data.timeData[k][quartil2Index + 1][1]) /
                  2;
              } else {
                //middle is one specific value
                let quartil1Index, quartil2Index;
                quartil1Index =
                  (Math.floor(data.timeData[k].length / 2) - 1) / 2;
                quartil2Index = data.timeData[k].length - 1 - quartil1Index;

                tempData[k][0] = data.timeData[k][quartil1Index][1];
                tempData[k][2] = data.timeData[k][quartil2Index][1];
              }
            }

            //clean up inconsistencies (should not happen.. just make sure!)
            //small problem: due to changes later into the program the value order
            //is 3, 0, 1, 2, 4
            //probably needs refactoring

            if(tempData[k][0] < 5){
              tempData[k][0] = 5;
            }
            if(tempData[k][1] < 5){
              tempData[k][2] = 5;
            }
            if(tempData[k][3] < 5){
              tempData[k][3] = 5;
            }
            if(tempData[k][4] < 5){
              tempData[k][4] = 5;
            }
            if(tempData[k][5] < 5){
              tempData[k][5] = 5;
            }

            if (
              tempData[k][3] > tempData[k][0] ||
              Math.abs(tempData[k][3] - tempData[k][0]) < 2000
            ) {
              tempData[k][0] = tempData[k][3] + 2000;
            }
            if (
              tempData[k][0] > tempData[k][1] ||
              Math.abs(tempData[k][0] - tempData[k][1]) < 2000
            ) {
              tempData[k][1] = tempData[k][0] + 2000;
            }
            if (
              tempData[k][1] > tempData[k][2] ||
              Math.abs(tempData[k][1] - tempData[k][2]) < 2000
            ) {
              tempData[k][2] = tempData[k][1] + 2000;
            }
            if (
              tempData[k][2] > tempData[k][4] ||
              Math.abs(tempData[k][2] - tempData[k][4]) < 2000
            ) {
              tempData[k][4] = tempData[k][2] + 2000;
            }
          } else {
            tempData[k][0] = 15003;
            tempData[k][1] = 15004;
            tempData[k][2] = 15005;
            tempData[k][3] = 15006;
            tempData[k][4] = 15010;
          }
        }
      }

      //stores references to marks that still need to be added into timieline later
      //those exist for each stamp that lays over 15sec mark
      //once every "safe" mark has been added one more mark PER COLOR will also be added to timeline
      //to make it look as one cohesive timeline marker even, if the interval
      //overlays the big red line
      let leftovers = [];

      //push timestamps
      for (const [key, value] of Object.entries(tempData)) {
        let name = `${key}`.split("_");
        switch (name.length) {
          case 1:
            name = name[0];
            break;
          case 2:
          case 3:
            name = name[0] + " " + name[1];
            break;
        }

        if (value[0] < 15000 && value[2] > 15000) {
          //check if smaller value exceeds timeline or bigger value exceeds it
          if (value[1] <= 15000) {
            //middle value left of 15sek mark
            //push everything from first mark to middle mark
            dataTable.push(
              this.generateTimeAnalysisRow(name, [value[0], value[1]], 0)
            );

            //push from 15sek mark to end stamp
            let newVal = [15001, value[2]];
            dataTable.push(this.generateTimeAnalysisRow(name, newVal, 1));

            //push empty for first stamp
            //push everything left from second stamp from before mark up until mark
            leftovers.push([0, 0, name, 0]);
            leftovers.push([value[1], 14999, name, 1]);
          } else {
            //middle value right of 15sek mark
            //push from start stamp to 15sek mark
            let newVal = [value[0], 14999];
            dataTable.push(this.generateTimeAnalysisRow(name, newVal, 0));

            //push everything after middle mark to end mark
            dataTable.push(
              this.generateTimeAnalysisRow(name, [value[1], value[2]], 1)
            );

            //add everything left from first mark
            //push empry for end mark
            leftovers.push([15002, value[1] - 2, name, 0]);
            leftovers.push([0, 0, name, 1]);
          }
        } else if (value[0] == 15000) {
          value[0] = 15001;
          dataTable.push(
            this.generateTimeAnalysisRow(name, [value[0], value[1]], 0)
          );
          dataTable.push(
            this.generateTimeAnalysisRow(name, [value[1], value[2]], 1)
          );
        } else if (value[2] == 15000) {
          value[2] = 14999;
          dataTable.push(
            this.generateTimeAnalysisRow(name, [value[0], value[1]], 0)
          );
          dataTable.push(
            this.generateTimeAnalysisRow(name, [value[1], value[2]], 1)
          );
        } else {
          dataTable.push(
            this.generateTimeAnalysisRow(name, [value[0], value[1]], 0)
          );
          dataTable.push(
            this.generateTimeAnalysisRow(name, [value[1], value[2]], 1)
          );
        }
      }

      //at this point all "basic" timestamps are set
      //red line needs to be set next, as online item 3 each row will be red

      //push red line
      if (data.isAdvanced) {
        dataTable.push(
          ["0", "3", 15000, 15000],
          ["1", "3", 15000, 15000],

          ["2", "3", 15000, 15000],
          ["3", "3", 15000, 15000],
          ["4", "3", 15000, 15000],
          ["5", "3", 15000, 15000],

          ["6", "3", 15000, 15000]
        );
      } else {
        dataTable.push(
          ["CL INCOMING", "3", 15000, 15000],
          ["HD INCOMING", "3", 15000, 15000],

          ["CL ROUTE", "3", 15000, 15000],
          ["CL WEIGHT", "3", 15000, 15000],
          ["CL ACCOUNTING", "3", 15000, 15000],
          ["HD FINANCE", "3", 15000, 15000],

          ["CL OUTGOING", "3", 15000, 15000],
          ["HD OUTGOING", "3", 15000, 15000],

          ["MESSENGER", "3", 15000, 15000],
          ["COURIER", "3", 15000, 15000]
        );
      }

      //Now add all leftovers to fill in spaces
      //item 4 each row will be same color as item 1, item 5 will be as item 2
      for (let i = 0; i < leftovers.length; i++) {
        dataTable.push(
          this.generateTimeAnalysisRow(
            leftovers[i][2],
            [leftovers[i][0], leftovers[i][1]],
            leftovers[i][3]
          )
        );
      }

      let counters = [];
      for (let i = 1; i < dataTable.length; i++) {
        counters[dataTable[i][0]] =
          counters[dataTable[i][0]] == undefined
            ? 1
            : counters[dataTable[i][0]] + 1;
      }

      for (let i in counters) {
        if (counters[i] == 3) {
          dataTable.push([i + "", "0", 0, 0]);
          dataTable.push([i + "", "1", 0, 0]);
        }
      }

      //finally add quartils as 1 sec grey timestamps .. mind the 15sec mark!
      for (let i in tempData) {
        if (tempData[i][3] >= 14999 && tempData[i][3] <= 15001)
          tempData[i][3] = 15002;
        if (tempData[i][4] >= 14999 && tempData[i][4] <= 15001)
          tempData[i][4] = 15002;

        if (tempData[i][3] <= 5) tempData[i][3] = 10;
        if (tempData[i][4] <= 5) tempData[i][4] = 10;

        let name = i.split("_");
        switch (name.length) {
          case 1:
            name = name[0];
            break;
          case 2:
          case 3:
            name = name[0] + " " + name[1];
            break;
        }

        //why it needs an invisible "4" in its row to work? no idea. it does, though
        dataTable.push([name, "4", tempData[i][3] - 1, tempData[i][3]]);
        dataTable.push([name, "4", tempData[i][4] - 1, tempData[i][4]]);

        dataTable.push([name, "5", tempData[i][3], tempData[i][3] + 1]);
        dataTable.push([name, "5", tempData[i][4], tempData[i][4] + 1]);
      }

      this.dataTables[number] = dataTable;

      return dataTable;
    },

    generateTimeAnalysisRow(name, value, index) {
      let newRow = [];
      newRow.push(name);
      newRow.push(index + 1 + "");
      newRow.push(value[0]);
      newRow.push(value[1]);

      return newRow;
    },

    cleanupTimeChart(number, dataTable, parentID) {
      let chartElement = document
        .getElementById("gChart_time" + number)
        .getElementsByTagName("svg");

      // if (chartElement.length == 0) {
        setTimeout(function () {
          // chartElement = chartElement[0];
          for (let i = 0; i < chartElement.length; i++) {
            if (chartElement[i].getElementsByTagName("rect").length > 0) {
              chartElement = chartElement[i];
              break;
            }
          }

          let medianTimes = [];
          let finishedMedianRows = []; //only 1 rect per median
          let roleOrder = [];

          for (let i = 1; i < dataTable.length; i++) {
            if (
              dataTable[i][0] != " " &&
              dataTable[i][1] == "1"
            ) {
              let found = false;
              for (let m = 0; m < medianTimes.length; m++) {
                if (
                  medianTimes[m].role == dataTable[i][0]
                ) {
                  found = true;
                  break;
                }
              }

              if (!found) {
                //case light orange overlays center
                if (
                  roleOrder.indexOf(dataTable[i][0]) ==
                  -1
                ) {
                  roleOrder.push(dataTable[i][0]);
                }
                if (dataTable[i][3] != 14999) {
                  let medianInMinutes = Math.floor(
                    dataTable[i][3] / 1000
                  );

                  medianInMinutes =
                    Math.floor(medianInMinutes / 60) +
                    ":" +
                    (medianInMinutes % 60 < 10
                      ? "0" + (medianInMinutes % 60)
                      : medianInMinutes % 60);

                  //empty rows
                  let removeLater = false;
                  if (
                    dataTable[i][2] == 15003 &&
                    dataTable[i][3] == 15004
                  )
                    removeLater = true;

                  medianTimes.push({
                    median: medianInMinutes,
                    role: dataTable[i][0],
                    removeLater: removeLater,
                  });
                }
              }
            }
          }

          for (let m = medianTimes.length - 1; m >= 0; m--) {
            if (medianTimes[m].removeLater) {
              medianTimes.splice(m, 1);
            }
          }

          //when light orange is over 15sec mark order may get messed up, sort just in case!
          //also a great time to remove those unneeded ones!
          let medianCopy = medianTimes.splice(0, medianTimes.length);

          for (let i = medianCopy.length - 1; i >= 0; i--) {
            if (medianCopy[i].removeLater) {
              medianCopy.splice(i, 1);
              continue;
            }

            medianTimes[roleOrder.indexOf(medianCopy[i].role)] = medianCopy[i];
          }

          for (let i = medianTimes.length - 1; i >= 0; i--) {
            if (medianTimes[i] == undefined) medianTimes.splice(i, 1);
          }

          //some more basic stuff
          let rectParentContainer = chartElement.getElementsByTagName("g")[2];

          let rowHeight = chartElement
            .getElementsByTagName("rect")[0]
            .getAttribute("height");
          let elemWidth = 0; //needed to remove blanks later

          let elemsToCheck = chartElement.getElementsByTagName("rect");

          let minMaxEndPoints = [];

          let nextMedianValue = 0;

          for (let i = 0; i < elemsToCheck.length; i++) {
            if (elemsToCheck[i].getAttribute("fill") == "#ff0000") {
              let elemHeight = elemsToCheck[i].getAttribute("height");

              elemsToCheck[i].setAttribute(
                "y",
                elemsToCheck[i].getAttribute("y") - (rowHeight - elemHeight) / 2
              );
              elemsToCheck[i].setAttribute("height", rowHeight);
              elemWidth = elemsToCheck[i].getAttribute("width");
            }
          }

          for (let i = 0; i < elemsToCheck.length; i++) {
            if (elemsToCheck[i].getAttribute("fill") == "#ff0000") {
              let elemHeight = elemsToCheck[i].getAttribute("height");

              elemsToCheck[i].setAttribute(
                "y",
                elemsToCheck[i].getAttribute("y") - (rowHeight - elemHeight) / 2
              );
              elemsToCheck[i].setAttribute("height", rowHeight);
              elemWidth = elemsToCheck[i].getAttribute("width");
            } else if (
              elemsToCheck[i].getAttribute("fill") == "#ff714d" ||
              elemsToCheck[i].getAttribute("fill") == "#ad4c33"
            ) {
              //points to blank stamp that has only been added as a filler
              //make it invisible
              if (elemsToCheck[i].getAttribute("width") == elemWidth) {
                elemsToCheck[i].setAttribute("width", 0);
              } else if (elemsToCheck[i].getAttribute("fill") == "#ad4c33") {
                if (
                  parseInt(elemsToCheck[i].getAttribute("y")) - rowHeight / 2 <
                  0
                )
                  continue;
                else if (
                  finishedMedianRows.indexOf(
                    elemsToCheck[i].getAttribute("y") - rowHeight / 2
                  ) >= 0
                )
                  continue;

                finishedMedianRows.push(
                  elemsToCheck[i].getAttribute("y") - rowHeight / 2
                );

                let group = document.createElementNS(
                  "http://www.w3.org/2000/svg",
                  "g"
                );
                group.setAttribute(
                  "x",
                  parseInt(elemsToCheck[i].getAttribute("x")) - rowHeight * 0.75
                );
                group.setAttribute(
                  "y",
                  parseInt(elemsToCheck[i].getAttribute("y")) - rowHeight / 2
                );

                group.setAttribute("height", rowHeight * 0.75);
                group.setAttribute("width", rowHeight * 1.5);

                let numberText = document.createElementNS(
                  "http://www.w3.org/2000/svg",
                  "text"
                );

                numberText.classList.add("heavy");
                numberText.innerHTML = medianTimes[nextMedianValue].median;

                numberText.setAttribute("height", rowHeight * 0.75);
                numberText.setAttribute("width", rowHeight * 1.5);

                numberText.setAttribute(
                  "x",
                  parseInt(group.getAttribute("x")) + 12
                );
                numberText.setAttribute(
                  "y",
                  parseInt(group.getAttribute("y")) + rowHeight * 0.55
                );

                //add boxes containing median value
                let numberBox = document.createElementNS(
                  "http://www.w3.org/2000/svg",
                  "rect"
                );

                numberBox.setAttribute("fill", "#ffffff");
                numberBox.classList.add("svgBorderedBox");
                numberBox.setAttribute("height", rowHeight * 0.75);
                numberBox.setAttribute("width", rowHeight * 1.5);
                numberBox.setAttribute("x", group.getAttribute("x"));
                numberBox.setAttribute("y", group.getAttribute("y"));

                group.appendChild(numberBox);
                group.appendChild(numberText);

                chartElement.appendChild(group);

                nextMedianValue++;
              }
            } else if (elemsToCheck[i].getAttribute("fill") == "#5e5e5e") {
              minMaxEndPoints.push(elemsToCheck[i]);
            }
          }

          while (minMaxEndPoints.length > 0) {
            let firstPoint = minMaxEndPoints.splice(0, 1)[0];

            for (let i = 0; i < minMaxEndPoints.length; i++) {
              if (
                minMaxEndPoints[i].getAttribute("y") ==
                firstPoint.getAttribute("y")
              ) {
                let newRect = document.createElementNS(
                  "http://www.w3.org/2000/svg",
                  "rect"
                );
                newRect.setAttribute("height", 4);
                newRect.setAttribute(
                  "width",
                  Math.abs(
                    parseInt(firstPoint.getAttribute("x")) -
                      parseInt(minMaxEndPoints[i].getAttribute("x"))
                  )
                );

                if (
                  parseInt(firstPoint.getAttribute("x")) <
                  parseInt(minMaxEndPoints[i].getAttribute("x"))
                ) {
                  newRect.setAttribute("x", firstPoint.getAttribute("x"));
                } else {
                  newRect.setAttribute(
                    "x",
                    minMaxEndPoints[i].getAttribute("x")
                  );
                }

                newRect.setAttribute(
                  "y",
                  parseInt(firstPoint.getAttribute("y")) +
                    parseInt(firstPoint.getAttribute("height")) / 2 -
                    1
                );

                newRect.setAttribute("fill", "#5e5e5e");

                rectParentContainer.insertBefore(
                  newRect,
                  rectParentContainer.children[0]
                );

                minMaxEndPoints.splice(i, 1);
                break;
              }
            }
          }

          //sort red lines to the end to make them appear on top
          let lastIndex = elemsToCheck.length;
          for (let i = 0; i < lastIndex; i++) {
            if (elemsToCheck[i].getAttribute("fill") == "#ff0000") {
              elemsToCheck[i].parentNode.appendChild(elemsToCheck[i]);
              i--;
              lastIndex--;
            }
          }

          //lastly add images to the right side
          roleOrder = [
            "MESSENGER",
            "COURIER",
            "CL_INCOMING_GOODS",
            "HD_INCOMING_GOODS",
            "CL_WEIGHT_TARIF",
            "CL_ROUTE_TARIF",
            "CL_ACCOUNTING",
            "HD_FINANCE",
            "CL_OUTGOING_GOODS",
            "HD_OUTGOING_GOODS",
          ];

          let imgParent =
            document.getElementById(parentID).children[0].children[0];

          for (let i = 0; i < roleOrder.length; i++) {
            let newElem = document.createElement("img");
            newElem.src =
              require("../../views/FAQViews/workInstructions/img/" +
              roleOrder[i] +
              "/mainImg.png");
            imgParent.appendChild(newElem);
            newElem.classList.add("timeStatisticsImg");
            newElem.style.height = newElem.style.width = rowHeight + "px";
            newElem.style.right = -1 * rowHeight + "px";
            newElem.style.top = (i + 1) * rowHeight + "px";
          }

          //remove that "undefined" text that taunts me since that fix down in line 798..
          let textElems = chartElement.getElementsByTagName("text");

          for (let i = 0; i < textElems.length; i++) {
            if (textElems[i].innerHTML == "undefined") {
              textElems[i].style.display = "none";
            }
          }

          //also put a big white box infront of that top line that also taunts me
          let whiteBox = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "rect"
          );
          whiteBox.setAttribute("height", rowHeight);
          whiteBox.setAttribute(
            "width",
            parseInt(chartElement.getAttribute("width"))
          );

          whiteBox.setAttribute("fill", "#ffffff");

          chartElement.getElementsByTagName("g")[3].appendChild(whiteBox);
        }, 2000);
      // }
    },
  },
};
</script>
