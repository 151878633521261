<template>
  <div id="fragenbereich" style="height: 70vh">

      <v-container fluid>
      <v-row>     
        <v-spacer />
        <v-spacer />
        <v-col class="mx-" cols="auto">
          <v-btn @click="backToFaq()" class="white--text mr-">{{ $t("message.back") }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
      
    <v-row justify="center" class="mt-5 mr-10 ml-10">
      <v-expansion-panels inset >
        <v-expansion-panel
           v-for="i in questions"
          :key="i.id"
        >
          <v-expansion-panel-header> {{i.question}} </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container fluid>
              <v-row>
                <v-col  v-html="i.answer">
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
        </v-expansion-panels>
    </v-row>    
  </div>
</template>

<script>

import germanQuestions from './questions/germanData.json'
import englishQuestions from './questions/englishData.json'

export default {
  name: "fragenbereich",

  data() {
    return {

      questions: localStorage.getItem("lang") == "de" ? germanQuestions : englishQuestions
     
    };
  },
  

  methods: {
    backToFaq(){
      this.$router.push("/overview/faq");
    }
  },

};
</script>