import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en/index'
import de from './de/index'



Vue.use(VueI18n)

const messages = {
    en: en,
    de: de,
}
  
  
export default new VueI18n({
    locale: localStorage.getItem("lang"),
    messages,
});