<template>
  <div id="ticketComponent">
    <v-card
      v-if="refinedData"
      @click="showTicketDetail()"
      height="300px"
      class="pa-4 mx-4 rounded-xl"
    >
      <v-icon
        v-if="refinedData.feedbackimage_set.length > 0"
        class="ticketContainerFileIcon"
        >mdi-file-multiple</v-icon
      >

      <v-container class="pa-0 ticketContainer">
        <v-row class="pa-0 ma-0">
          <v-col
            cols="auto"
            v-for="tag in shownTags"
            :key="tag.id"
            style="padding: 1px"
          >
            <v-chip
              class="chipStyle"
              :style="{
                'border-color': tag.color,
                'background-color': tag.secondaryColor,
              }"
              >{{ tag.name }}</v-chip
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="py-0">
            <p style="text-align: justify; color: rgba(0, 0, 0, 0.7)">
              {{ refinedData.text }}
            </p>
          </v-col>
        </v-row>
      </v-container>
      
      <v-container class="overlappingTicketContainer">
        <v-row>
          <v-spacer />
          <v-col cols="auto" class="py-0">
            <v-chip
              v-if="data.finished == false"
              class="chipStyle"
              style="border-color: #e9f542; background-color: #f6fca2"
              >Open</v-chip
            >
            <v-chip
              v-else-if="data.finished == true"
              class="chipStyle"
              style="border-color: #85ff38; background-color: #c3fc9f"
              >Done</v-chip
            >
            <v-chip
              v-else
              class="chipStyle"
              style="border-color: #666666; background-color: #b3b3b3"
              >Cancelled</v-chip
            >
          </v-col>
          <v-col cols="auto" class="py-0">
            <v-chip
              v-if="data.very_important"
              class="chipStyle"
              style="border-color: #ff2b2b; background-color: #ff8a8a"
              ><v-icon>mdi-exclamation-thick</v-icon></v-chip
            >
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>


<script>
const axios = require("axios");
export default {
  name: "ticketComponent",

  props: ["data", "allTags"],

  data() {
    return {
      shownTags: [],
      refinedData: undefined,
    };
  },

  methods: {
    showTicketDetail() {
      this.$router.push("/overview/tickets/" + this.data.id);
    },
    switchMarkImportant() {
      let formData = {
        id: this.refinedData.id,
        text: this.refinedData.text,
        very_important: !this.refinedData.very_important,
      };

      axios
        .patch(
          axios.defaults.baseURL + "feedback/" + this.data.id + "/",
          formData
        )
        .then(() => {
          this.refinedData.very_important = !this.refinedData.very_important;
        })
        .catch((error) => console.log(error));
    },
    switchMarkFinished() {
      let formData = {
        id: this.refinedData.id,
        finished: !this.refinedData.finished,
      };

      axios
        .patch(
          axios.defaults.baseURL + "feedback/" + this.data.id + "/",
          formData
        )
        .then(() => {
          this.refinedData.finished = !this.refinedData.finished;
        })
        .catch((error) => console.log(error));
    },
  },

  mounted() {
    this.refinedData = this.data;

    for (let i = 0; i < this.data.tags.length; i++) {
      for (let j = 0; j < this.allTags.length; j++) {
        if (this.allTags[j].id == this.data.tags[i]) {
          this.shownTags.push(this.allTags[j]);
        }
      }
    }
  },
};
</script>