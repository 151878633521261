<template>
  <div id="workInstructions">
    <v-container fluid style="background-color: white">
      <v-row>
        <v-spacer />
        <v-col cols="auto">
          <v-btn @click="backToFaq()" class="white--text mr-">{{
            $t("message.back")
          }}</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mb-5">
      <v-row>
        <v-col v-if="showGerman">
          <b>Hallo Trainer!</b><br />
          Hier findest du einen Überblick über alle Arbeitsanweisungen aller
          Rollen, inklusive ihrer Arbeitstabellen und Extras.<br />
          Folgende Tabs stehen im Spiel zur Verfügung:
          <ul>
            <li>
              <v-icon>mdi-clipboard-file-outline</v-icon>: Arbeitsanweisungen
            </li>
            <li>
              <v-icon>mdi-table</v-icon>: Tabellen, zum Ausfüllen oder
              Information auslesen
            </li>
            <li>
              <v-icon>mdi-star</v-icon>: Zusatzinformation, die die Rolle
              benötigt, um ihre Aufgabe zu erfüllen
            </li>
          </ul>
        </v-col>
        <v-col v-else> </v-col>
      </v-row>
    </v-container>

    <v-tabs>
      <v-tab><v-icon>mdi-clipboard-file-outline</v-icon></v-tab>
      <v-tab><v-icon>mdi-table</v-icon></v-tab>
      <v-tab><v-icon>mdi-star</v-icon></v-tab>

      <v-tab-item>
        <v-container v-if="showGerman">
          <v-row>
            <v-col>
              <div id="CUSTOMER_WI">
    <h1 id="workTitle"> WORK INSTRUCTIONS </h1>

    <div class="row">
        <div class="column">
            <img class="mainImg" src="./workInstructions/img/CUSTOMER/mainImg.png">
        </div>
        <div class="column borderedDiv">
            <h1 id="roleTitle" class="textCenter"> KUNDE</h1>
            <p id="roleText" class="textCenter">
                Dein Standort ist eine von vier Niederlassungen (Nord-Süd-Ost-West) der Product AG. Du bist für den
                Versand von Paketen an Mitarbeiter anderer Standorte verantwortlich.
            </p>
        </div>
    </div>
    <br>

    <div class="trainerHeadline">
        <h2>Aufgabe 1:</h2>
    </div>
    <br>
    <div class="comicStripAnchor">
        <img src="./workInstructions/img/CUSTOMER/CUSTOMER_1.png" class="generatedImg Center">
        <div class="comicStripText">
            <div>1. Einmal pro Minute ein Paket senden. Beginne sofort bei Minute 0!</div>
            <div></div>
            <div></div>
        </div>
    </div>
    <br>
    <div class="trainerHeadline">
        <h2>Aufgabe 2:</h2>
    </div>
    <br>
    <div class="comicStripAnchor">
        <img src="./workInstructions/img/CUSTOMER/customer_1_de.png" class="generatedImg Center">
        <div class="comicStripText">
            <div>1. Paket von Inbox auf den Schreibtisch ziehen</div>
            <div>2. Differenzzeit berechnen</div>
            <div>3. Falls Differenz > 7: Rejected-Stempel wählen</div>
        </div>
    </div>
    <br>
    <br>
    <div class="comicStripAnchor">
        <img src="./workInstructions/img/CUSTOMER/customer_2_de.png" class="generatedImg Center">
        <div class="comicStripText">
            <div>4. Paket stempeln</div>
            <div>5. Sternstempel prüfen</div>
            <div>6. Akzeptiere Sterne prüfen</div>
        </div>
    </div>
    <br>
    <br>
    <div class="comicStripAnchor">
        <img src="./workInstructions/img/CUSTOMER/customer_3_de.png" class="generatedImg Center">
        <div class="comicStripText">
            <div>7. Wenn Stern falsch: Paket stempeln</div>
            <div>8. Absender prüfen</div>
            <div>9. Mitarbeiter Liste prüfen</div>
        </div>
    </div>
    <br>
    <br>
    <div class="comicStripAnchor">
        <img src="./workInstructions/img/CUSTOMER/customer_4_de.png" class="generatedImg Center">
        <div class="comicStripText">
            <div>10. Wenn Mitarbeiter falsch: Paket stempeln</div>
            <div>11. Falls keine Fehler vorhanden: Paket mit Approved-Stempel stempeln</div>
            <div>12. Paket in Outbox ziehen</div>
        </div>
    </div>
    <br>
    <h1 class="bar"></h1>
</div>

              <div id="COURIER_WI">
                <h1 id="workTitle">WORK INSTRUCTIONS</h1>

                <div class="row">
                  <div class="column">
                    <img
                      class="mainImg"
                      src="./workInstructions/img/COURIER/mainImg.png"
                    />
                  </div>
                  <div class="column borderedDiv">
                    <h1 id="roleTitle" class="textCenter">KURIER</h1>
                    <p id="roleText" class="textCenter">
                      Es ist deine Aufgabe die Pakete an den vier Standorten der
                      Product AG (Nord, Süd, Ost, West) abzuholen und die
                      bearbeiteten Pakete wieder an den jeweiligen Standorten
                      abzuliefern.
                    </p>
                  </div>
                </div>
                <br />
                <div class="trainerHeadline">
                  <h2>Aufgabe 1:</h2>
                </div>
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/COURIER/courier_1.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>
                      1. Kundenstandort aufsuchen (Reihenfolge beachten!)
                    </div>
                    <div>2. Pakete ins Inventar ziehen</div>
                    <div>3. Botentisch auswählen</div>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/COURIER/courier_2.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>4. Pakete auf Botentisch legen</div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <br />
                <div class="trainerHeadline">
                  <h2>Aufgabe 2:</h2>
                </div>
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/COURIER/courier_4.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>1. Zum LEIT. WARENAUSGANG gehen</div>
                    <div>2. Pakete aus Outbox in Inventar legen</div>
                    <div>3. Empfängernummer prüfen</div>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/COURIER/courier_5.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>
                      4. <i class="fa fa-table"></i> Tab öffnen und prüfen zu
                      welchem Kunden Paket muss
                    </div>
                    <div>5. Zum Kunden gehen</div>
                    <div>6. Paket in Inbox des Kunden legen</div>
                  </div>
                </div>
                <br />
                <br />
                <h1 class="bar"></h1>
              </div>

              <div id="MESSENGER_WI">
                <h1 id="workTitle">WORK INSTRUCTIONS</h1>

                <div class="row">
                  <div class="column">
                    <img
                      class="mainImg"
                      src="./workInstructions/img/MESSENGER/mainImg.png"
                    />
                  </div>
                  <div class="column borderedDiv">
                    <h1 id="roleTitle" class="textCenter">BOTE</h1>
                    <p id="roleText" class="textCenter">
                      Es ist deine Aufgabe, eingehende Pakete anhand der
                      vorgegebenen Reihenfolge zu verteilen.
                    </p>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <img
                    src="./workInstructions/img/MESSENGER/MESSENGER_1.png"
                    class="generatedImg Center"
                  />
                  <div class="comicStripText">
                    <div>1. Zum Botentisch gehen</div>
                    <div>2. Pakete von Inbox in Inventar ziehen</div>
                    <div>3. Reihenfolge für Pakete prüfen</div>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <img
                    src="./workInstructions/img/MESSENGER/MESSENGER_2.png"
                    class="generatedImg Center"
                  />
                  <div class="comicStripText">
                    <div>
                      4. Auf nächsten Schreibtisch in der Reihenfolge klicken
                    </div>
                    <div>5. Pakete aus Inventar in die Inbox legen</div>
                    <div></div>
                  </div>
                </div>
                <br />
                <h1 class="bar"></h1>
              </div>

              <div id="CL_INCOMING_GOODS_WI">
                <h1 id="workTitle">WORK INSTRUCTIONS</h1>

                <div class="row">
                  <div class="column">
                    <img
                      class="mainImg"
                      src="./workInstructions/img/CL_INCOMING_GOODS/mainImg.png"
                    />
                  </div>
                  <div class="column borderedDiv">
                    <h1 id="roleTitle" class="textCenter">
                      SACHB.<br />WARENEINGANG
                    </h1>
                    <p id="roleText" class="textCenter">
                      Es ist deine Aufgabe, das Paket mit den richtigen Sender-
                      und Empfängercodes der Produkt AG zu versehen
                    </p>
                  </div>
                  <br />
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <img
                    src="./workInstructions/img/CL_INCOMING_GOODS/clerk_incoming_goods_1.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>1. Sender- und Empfänger prüfen</div>
                    <div>
                      2. Standort von EMPFÄNGER im
                      "List" Tab suchen
                    </div>
                    <div>
                      3. Code für Standort im <i class="fa fa-table"></i> Tab
                      suchen
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <img
                    src="./workInstructions/img/CL_INCOMING_GOODS/clerk_incoming_goods_2.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>4. Stempel für EMPFÄNGER wählen</div>
                    <div>5. Feld UNTEN RECHTS stempeln</div>
                    <div>
                      6. Standort von ABSENDER im
                      "List" Tab suchen
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <img
                    src="./workInstructions/img/CL_INCOMING_GOODS/clerk_incoming_goods_3.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>
                      7. Code für Standort im <i class="fa fa-table"></i> Tab
                      suchen
                    </div>
                    <div>8. Stempel für ABSENDER wählen</div>
                    <div>9. Feld UNTEN LINKS stempeln</div>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <img
                    src="./workInstructions/img/CL_INCOMING_GOODS/outbox.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>10. Paket in Outbox legen</div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <br />
                <h1 class="bar"></h1>
              </div>

              <div id="HD_INCOMING_GOODS_WI">
                <h1 id="workTitle">WORK INSTRUCTIONS</h1>

                <div class="row">
                  <div class="column">
                    <img
                      class="mainImg"
                      src="./workInstructions/img/HD_INCOMING_GOODS/mainImg.png"
                    />
                  </div>
                  <div class="column borderedDiv">
                    <h1 id="roleTitle" class="textCenter">
                      LEIT. <br />WARENEINGANG
                    </h1>
                    <p id="roleText" class="textCenter">
                      Deine Aufgabe ist es die bearbeiteten Pakete für die
                      Buchhaltung zu zählen.
                    </p>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <img
                    src="./workInstructions/img/HD_INCOMING_GOODS/HD_INCOMING_GOODS_1.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>1. Paket aus Inbox auf den Schreibtisch ziehen</div>
                    <div>2. Absender- und Empfängeradresse prüfen</div>
                    <div>
                      3. <i class="fa fa-table"></i> Tab öffnen und auf
                      richtiges Feld klicken
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <img
                    src="./workInstructions/img/HD_INCOMING_GOODS/HD_INCOMING_GOODS_2.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>4. Paket in Outbox ziehen</div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <br />
                <h1 class="bar"></h1>
              </div>

              <div id="CL_WEIGHT_TARIF_WI">
                <h1 id="workTitle">WORK INSTRUCTIONS</h1>

                <div class="row">
                  <div class="column">
                    <img
                      class="mainImg"
                      src="./workInstructions/img/CL_WEIGHT_TARIF/mainImg.png"
                    />
                  </div>
                  <div class="column borderedDiv">
                    <h1 id="roleTitle" class="textCenter">
                      SACHB. GEWICHTSTARIF
                    </h1>
                    <p id="roleText" class="textCenter">
                      Es ist deine Aufgabe, das Paket mit dem richtigen
                      Gewichtstarif zu versehen.
                    </p>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <img
                    src="./workInstructions/img/CL_WEIGHT_TARIF/clerk_weight_tarif_1.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>1. Paket aus Inbox auf den Schreibtisch ziehen</div>
                    <div>2. Gewicht prüfen</div>
                    <div>3. Tarif in Tabelle suchen</div>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <img
                    src="./workInstructions/img/CL_WEIGHT_TARIF/clerk_weight_tarif_2.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>4. Richtigen Stempel wählen</div>
                    <div>5. Feld mittig oben stempeln</div>
                    <div>6. Paket in Outbox ziehen</div>
                  </div>
                </div>
                <br />
                <h1 class="bar"></h1>
              </div>

              <div id="CL_ROUTE_TARIF_WI">
                <h1 id="workTitle">WORK INSTRUCTIONS</h1>

                <div class="row">
                  <div class="column">
                    <img
                      class="mainImg"
                      src="./workInstructions/img/CL_ROUTE_TARIF/mainImg.png"
                    />
                  </div>
                  <div class="column borderedDiv">
                    <h1 id="roleTitle" class="textCenter">
                      SACHB. <br />ROUTENTARIF
                    </h1>
                    <p id="roleText" class="textCenter">
                      Es ist deine Aufgabe, das Paket mit dem richtigen
                      Streckentarif zu versehen
                    </p>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <img
                    src="./workInstructions/img/CL_ROUTE_TARIF/clerk_route_tarif_1.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>1. Paket aus Inbox auf den Schreibtisch ziehen</div>
                    <div>2. Absender- & Empfängernummer prüfen</div>
                    <div>
                      3. <i class="fa fa-table"></i> Tab öffnen korrekten Tarif
                      suchen
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <img
                    src="./workInstructions/img/CL_ROUTE_TARIF/clerk_route_tarif_2.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>4. Passenden Stempel wählen</div>
                    <div>5. Feld oben links stempeln</div>
                    <div>6. Paket in Outbox ziehen</div>
                  </div>
                </div>
                <br />
                <h1 class="bar"></h1>
              </div>

              <div id="CL_ACCOUNTING_WI">
                <h1 id="workTitle">WORK INSTRUCTIONS</h1>

                <div class="row">
                  <div class="column">
                    <img
                      class="mainImg"
                      src="./workInstructions/img/CL_ACCOUNTING/mainImg.png"
                    />
                  </div>
                  <div class="column borderedDiv">
                    <h1 id="roleTitle" class="textCenter">
                      SACHB.<br />
                      BUCHHALTUNG
                    </h1>
                    <p id="roleText" class="textCenter">
                      Es ist deine Aufgabe, den Gesamttarif pro Paket zu
                      berechnen.
                    </p>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <img
                    src="./workInstructions/img/CL_ACCOUNTING/clerk_accounting_1.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>1. Paket aus Inbox auf den Schreibtisch ziehen</div>
                    <div>
                      2. Routentarif und Gewichtstarif addieren um Gesamttarif
                      zu erhalten
                    </div>
                    <div>3. Passenden Stempel wählen</div>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <img
                    src="./workInstructions/img/CL_ACCOUNTING/clerk_accounting_2.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>4. Feld oben rechts stempeln</div>
                    <div>5. Roten Marker auswählen</div>
                    <div>6. Gesamttarif und Senderadresse markieren</div>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <img
                    src="./workInstructions/img/CL_ACCOUNTING/clerk_accounting_3.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>7. Paket in Outbox ziehen</div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <br />
                <h1 class="bar"></h1>
              </div>

              <div id="HD_FINANCE_WI">
                <h1 id="workTitle">WORK INSTRUCTIONS</h1>

                <div class="row">
                  <div class="column">
                    <img
                      class="mainImg"
                      src="./workInstructions/img/HD_FINANCE/mainImg.png"
                    />
                  </div>
                  <div class="column borderedDiv">
                    <h1 id="roleTitle" class="textCenter">
                      LEIT. <br />FINANZEN
                    </h1>
                    <p id="roleText" class="textCenter">
                      Du bist für die Buchhaltung und Rechnungsstellung an
                      unsere Kunden zuständig.
                    </p>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/HD_FINANCE/head_finance_1.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>1. Paket aus Inbox auf den Schreibtisch ziehen</div>
                    <div>2. Prüfen ob Gesamttarif korrekt</div>
                    <div>3. Absendernummer prüfen</div>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/HD_FINANCE/head_finance_2.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>
                      4. Im <i class="fa fa-table"></i> Tab Gesamttarif in
                      Spalte des Senders eintragen
                    </div>
                    <div>5. Paket in Outbox ziehen</div>
                    <div></div>
                  </div>
                </div>
                <br />
                <h1 class="bar"></h1>
              </div>

              <div id="CL_OUTGOING_GOODS_WI">
                <h1 id="workTitle">WORK INSTRUCTIONS</h1>

                <div class="row">
                  <div class="column">
                    <img
                      class="mainImg"
                      src="./workInstructions/img/CL_OUTGOING_GOODS/mainImg.png"
                    />
                  </div>
                  <div class="column borderedDiv">
                    <h1 id="roleTitle" class="textCenter">
                      SACHB.<br />
                      WARENAUSGANG
                    </h1>
                    <p id="roleText" class="textCenter">
                      Es ist deine Aufgabe, das Paket um ein zusätzliches
                      Qualitätsmerkmal zu erweitern. Dieses Merkmal wurde vom
                      Kunden gewünscht.
                    </p>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <img
                    src="./workInstructions/img/CL_OUTGOING_GOODS/CL_OUTGOING_GOODS_1.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>1. Paket aus Inbox auf den Schreibtisch ziehen</div>
                    <div>2. Wähle einen Stempel...</div>
                    <div>3. ...und eine Farbe aus</div>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <img
                    src="./workInstructions/img/CL_OUTGOING_GOODS/CL_OUTGOING_GOODS_2.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>4. Irgendwo stempeln</div>
                    <div>5. Paket in Outbox ziehen</div>
                    <div></div>
                  </div>
                </div>
                <br />
                <h1 class="bar"></h1>
              </div>

              <div id="HD_OUTGOING_GOODS_WI">
                <h1 id="workTitle">WORK INSTRUCTIONS</h1>

                <div class="row">
                  <div class="column">
                    <img
                      class="mainImg"
                      src="./workInstructions/img/HD_OUTGOING_GOODS/mainImg.png"
                    />
                  </div>
                  <div class="column borderedDiv">
                    <h1 id="roleTitle" class="textCenter">
                      LEIT. <br />WARENAUSGANG
                    </h1>
                    <p id="roleText" class="textCenter">
                      Deine Aufgabe ist es die bearbeiteten Pakete für die
                      Buchhaltung zu zählen.
                    </p>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <img
                    src="./workInstructions/img/HD_OUTGOING_GOODS/hd_outgoing_goods_1.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>1. Paket aus Inbox auf den Schreibtisch ziehen</div>
                    <div>2. Absender- und Empfängeradresse prüfen</div>
                    <div>
                      3. <i class="fa fa-table"></i> Tab öffnen und
                      richtiges Feld anklicken
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <img
                    src="./workInstructions/img/HD_OUTGOING_GOODS/hd_outgoing_goods_2.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>4. Paket in Outbox ziehen</div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <br />
                <h1 class="bar"></h1>
              </div>

              <div id="MANAGING_DIRECTOR_WI">
                <h1 id="workTitle">WORK INSTRUCTIONS</h1>

                <div class="row">
                  <div class="column">
                    <img
                      class="mainImg"
                      src="./workInstructions/img/MANAGING_DIRECTOR/mainImg.png"
                    />
                  </div>
                  <div class="column borderedDiv">
                    <h1 id="roleTitle" class="textCenter">GESCHÄFTSFÜHRER</h1>
                    <p id="roleText" class="textCenter">
                      Du bist der BIG BOSS! Deine Aufgabe ist es, das
                      Tagesgeschäft zu managen, indem du für einen reibungslosen
                      Ablauf der einzelnen Funktionen sorgst.
                    </p>
                  </div>
                </div>
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/MANAGING_DIRECTOR/Managing_Director.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>1. Überwache und kontrolliere den Gesamtablauf</div>
                    <div>
                      2. Motiviere deine Kollegen und sorge für ein angenehmes
                      Betriebsklima
                    </div>
                    <div>
                      3. Im <i class="fa fa-table"></i> Tab findest du einen
                      Notizeditor
                    </div>
                  </div>
                </div>
                <br />
                <div id="bar"></div>
              </div>

              <div id="SALES_MANAGER_WI">
                <div class="row">
                  <div class="column">
                    <img
                      class="mainImg"
                      src="./workInstructions/img/SALES_MANAGER/mainImg.png"
                    />
                  </div>
                  <div class="column borderedDiv">
                    <h1 id="roleTitle" class="textCenter">VERKAUFSLEITER</h1>
                    <p id="roleText" class="textCenter">
                      Es is deine Aufgabe die Kundenzufriedenheit
                      sicherzustellen, indem du regelmäßig Feedback bei den
                      Kundenstandorten einholst und an den GESCHÄFTSFÜHRER
                      weitergibst.
                    </p>
                  </div>
                </div>
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/SALES_MANAGER/Sales_Manager.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>1. Suche regelmäßig die Kundenstandorte auf</div>
                    <div>2. Hole Feedback ein</div>
                    <div>
                      3. Gib das Feedback an den GESCHÄFTSFÜHRER weiter
                      (vorausgesetzt er ist nicht im Urlaub)
                    </div>
                  </div>
                </div>
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/CONTROLLER/notizen.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>
                      4. Im <i class="fa fa-table"></i> Tab findest du einen
                      Notizeditor
                    </div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <br />
                <div id="bar"></div>
              </div>

              <div id="CONTROLLER_WI">
                <h1 id="workTitle">WORK INSTRUCTIONS</h1>
                <div class="row">
                  <div class="column">
                    <img
                      class="mainImg"
                      src="./workInstructions/img/CONTROLLER/mainImg.png"
                    />
                  </div>
                  <br />
                  <div class="column borderedDiv">
                    <h1 id="roleTitle" class="textCenter">PRÜFER</h1>
                    <p id="roleText" class="textCenter">
                      Es ist deine Aufgabe den internen Prozess im Auge zu behalten und an deinen GESCHÄFTSFÜHRER
                      weiterzuleiten.
                    </p>
                  </div>
                </div>
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/CONTROLLER/Controller.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>1. Überwache die einzelnen Prozesse</div>
                    <div>2. Beobachte wie lange die einzelnen Arbeitsschritte etwa dauern</div>
                    <div>
                      3. Melde die Zeiten deinem GESCHÄFTSFÜHRER (vorausgesetzt
                      er ist nicht im Urlaub)
                    </div>
                  </div>
                </div>
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/CONTROLLER/notizen.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>
                      4. Im <i class="fa fa-table"></i> Tab findest du einen
                      Notizeditor
                    </div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <br />
                <div id="bar"></div>
              </div>

              <div style="display:none">
                <div id="WI_ADV">
                  <div id="ACT_IN_WI">
                    <div class="row">
                      <div class="column">
                        <img
                          class="mainImg"
                          src="./workInstructions/img/ADV_RETRIEVE/RETRIEVE.png"
                        />
                      </div>
                      <div class="column borderedDiv">
                        <h1 id="roleTitle" class="textCenter">EMPFANGEN</h1>
                        <p id="roleText" class="textCenter">
                          Spieler mit dieser Aufgabe sind für die Abholung von
                          Paketen von den KUNDENSTANDORTEN und PRODUKTIONSLINIEN
                          verantwortlich.
                        </p>
                      </div>
                    </div>
                    <br />

                    <div class="configOption generatedText marginHelper">
                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_RETRIEVE/Empfangen_reihenfolge_1.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>
                              1. Beachte die Reihenfolge im
                              <i class="fa fa-table"></i> Tab, in welcher die
                              Pakete abgeholt werden sollen
                            </div>
                            <div>2. Navigiere zu den Kunden</div>
                            <div>
                              3. Nimm die Pakete und lege sie in dein Inventar
                            </div>
                          </div>
                        </div>
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_RETRIEVE/Empfangen_reihenfolge_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>
                              4. Gehe zu dem Anfang der PRODUKTIONSLINIEN
                              welcher sich am äußerst rechten Schreibtisch
                              befindet
                            </div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_RETRIEVE/Empfangen_anywhere_1.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>1. Suche dir einen beliebigen Kunden aus</div>
                            <div>2. Klicke auf den Kunden</div>
                            <div>
                              3. Nimm die Pakete und lege sie in dein Inventar
                            </div>
                          </div>
                        </div>
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_RETRIEVE/Empfangen_anywhere_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>
                              4. Gehe zu dem Anfang der PRODUKTIONSLINIEN
                              welcher sich am äußerst rechten Schreibtisch
                              befindet
                            </div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <br />
                  </div>

                  <div id="ACT_OUT_WI">
                    <div class="row">
                      <div class="column">
                        <img
                          class="mainImg"
                          src="./workInstructions/img/ADV_DELIVER/DELIVER.png"
                        />
                      </div>
                      <div class="column borderedDiv">
                        <br /><br />
                        <h1 id="roleTitle" class="textCenter">LIEFERN</h1>
                        <p id="roleText" class="textCenter">
                          Spieler mit dieser Aufgabe sind dafür zuständig die
                          Pakete von dem Ende der PRODUKTIONSLINIEN zu den vier
                          KUNDENSTANDORTEN zu liefern.
                        </p>
                      </div>
                    </div>
                    <br />

                    <div class="configOption generatedText marginHelper">
                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_DELIVER/Liefern_anywhere_1.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>
                              1. Gehe zu dem Ende der PRODUKTIONSLINIEN, welches
                              sich am äußerst linken Schreibtisch befindet
                            </div>
                            <div>
                              2. Nimm dir ein Paket aus der Outbox und ziehe es
                              in dein Inventar
                            </div>
                            <div>
                              3. Prüfe die Empfängernummer im UNTEREN RECHTEN
                              Feld
                            </div>
                          </div>
                        </div>
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_DELIVER/Liefern_anywhere_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>
                              4. Öffne deinen <i class="fa fa-table"></i> Tab um
                              zu überprüfen welcher Code zu welchem Kunden
                              gehört
                            </div>
                            <div>5. Navigiere zu den Kunden</div>
                            <div></div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_DELIVER/Liefern_buchstaben_1.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>
                              1. Gehe zu dem Ende der PRODUKTIONSLINIEN, welches
                              sich am äußerst linken Schreibtisch befindet
                            </div>
                            <div>
                              2. Nimm dir ein Paket aus der Outbox und ziehe es
                              in dein Inventar
                            </div>
                            <div>
                              3. Prüfe die Empfängernummer im UNTEREN RECHTEN
                              Feld
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_DELIVER/Liefern_buchstaben_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>4. Navigiere zu den Kunden</div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <br />
                  </div>

                  <div id="ACT_STAR_WI">
                    <div class="row">
                      <div class="column">
                        <img
                          class="mainImg"
                          src="./workInstructions/img/ADV_STAR/STAR.png"
                        />
                      </div>
                      <div class="column borderedDiv">
                        <h1 id="roleTitle" class="textCenter">STERN</h1>
                        <p id="roleText" class="textCenter">
                          Es ist deine Aufgabe, das Paket mit einem zusätzlichen
                          Qualitätsmerkmal in Form einer STERN-Briefmarke
                          aufzuwerten.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="generatedText marginHelper">
                      <div class="comicStripAnchor">
                        <img
                          src="./workInstructions/img/ADV_STAR/adv_stern_1.png"
                          class="Center"
                        />
                        <div class="comicStripText">
                          <div>1. Wähle einen Stern-Stempel</div>
                          <div>2. Wähle eine Farbe</div>
                          <div>
                            3. Stemple eine beliebige Stelle auf dem Paket
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                    </div>
                    <br />
                    <br />
                    <br />
                  </div>

                  <div id="ACT_FIN_WI">
                    <div class="row">
                      <div class="column">
                        <img
                          class="mainImg"
                          src="./workInstructions/img/ADV_BILLING/BILLING.png"
                        />
                      </div>
                      <div class="column borderedDiv">
                        <h1 id="roleTitle" class="textCenter">BUCHHALTUNG</h1>
                        <p id="roleText" class="textCenter">
                          Spieler mit dieser Aufgabe sind für die Buchhaltung
                          zuständig.
                        </p>
                      </div>
                    </div>
                    <div class="configOption generatedText marginHelper">
                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_BILLING/berechnung_1.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>1. Prüfe den Sender- und Empfängercode</div>
                            <div>
                              2. Öffne den <i class="fa fa-table"></i> Tab um
                              den korrekten Routentarif zu finden. Merke dir
                              diesen.
                            </div>
                            <div>3. Prüfe das Paketgewicht</div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_BILLING/berechnung_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>
                              4. Öffne den <i class="fa fa-table"></i> Tab um
                              den korrekten Gewichtstarif zu finden.
                            </div>
                            <div>
                              5. Addiere Routen- und Gewichtstarif um den
                              Gesamttarif zu berechnen
                            </div>
                            <div>
                              6. Öffne den <i class="fa fa-table"></i> Tab und
                              trage den Gesamttarif in die Tabelle ein.
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                      </div>
                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_BILLING/berechnung_option_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>
                              1. Addiere Routen- und Gewichtstarif um den
                              Gesamttarif zu berechnen
                            </div>
                            <div>
                              2. Öffne den <i class="fa fa-table"></i> Tab und
                              trage den Gesamttarif in die Tabelle ein.
                            </div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_BILLING/berechnung_option_3.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>1. Prüfe den Gesamttarif</div>
                            <div>
                              2. Öffne den <i class="fa fa-table"></i> Tab und
                              trage den Gesamttarif in die Tabelle ein.
                            </div>
                            <div></div>
                          </div>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>

                  <div id="ACT_ROUTE_WI">
                    <div class="row">
                      <div class="column">
                        <img
                          class="mainImg"
                          src="./workInstructions/img/ADV_ROUTE/ROUTE.png"
                        />
                      </div>
                      <div class="column borderedDiv">
                        <h1 id="roleTitle" class="textCenter">ROUTE</h1>
                        <p id="roleText" class="textCenter">
                          Spieler mit dieser Aufgabe sind dafür zuständig die
                          korrekten SENDER und EMPFÄNGER Codes auf die Pakete zu
                          stempeln.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="configOption generatedText marginHelper">
                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_ROUTE/route_1.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>1. Sender- und Empfänger prüfen</div>
                            <div>
                              2. Standort von EMPFÄNGER im
                              "List" Tab suchen
                            </div>
                            <div>
                              3. Code für Standort im
                              <i class="fa fa-table"></i> Tab suchen
                            </div>
                          </div>
                        </div>
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_ROUTE/route_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>4. Stempel für EMPFÄNGER wählen</div>
                            <div>5. Feld UNTEN RECHTS stempeln</div>
                            <div>
                              6. Standort von ABSENDER im
                              "List" Tab suchen
                            </div>
                          </div>
                        </div>
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_ROUTE/route_3.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>
                              7. Code für Standort im
                              <i class="fa fa-table"></i> Tab suchen
                            </div>
                            <div>8. Stempel für ABSENDER wählen</div>
                            <div>9. Feld UNTEN LINKS stempeln</div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_ROUTE/route_adv_1.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>1. Sender- und Empfänger prüfen</div>
                            <div>
                              2. Standort für EMPFÄNGER im
                              "List" Tab suchen
                            </div>
                            <div>3. Stempel für EMPFÄNGER wählen</div>
                          </div>
                        </div>
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_ROUTE/route_adv_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>4. Feld UNTEN RECHTS stempeln</div>
                            <div>
                              5. Standort für ABSENDER im
                              <i class="fa fa-table"></i> Tab suchen
                            </div>
                            <div>6. Stempel für ABSENDER wählen</div>
                          </div>
                        </div>
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_ROUTE/route_adv_3.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>7. Feld UNTEN LINKS stempeln</div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_ROUTE/route_sender_number_1.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>1. ABSENDER prüfen</div>
                            <div>
                              2. Standort für ABSENDER im
                              <i class="fa fa-table"></i> Tab suchen
                            </div>
                            <div>
                              3. Code für Standort im
                              <i class="fa fa-table"></i> Tab suchen
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_ROUTE/route_sender_number_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>4. Stempel für ABSENDER wählen</div>
                            <div>5. Feld UNTEN LINKS stempeln</div>
                            <div></div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_ROUTE/route_sender_letter_1.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>1. ABSENDER prüfen</div>
                            <div>
                              2. Standort für ABSENDER im
                              <i class="fa fa-table"></i> Tab suchen
                            </div>
                            <div>3. Stempel für ABSENDER wählen</div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_ROUTE/route_sender_letter_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>4. Feld UNTEN LINKS stempeln</div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_ROUTE/route_receiver_number_1.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>1. Empfänger prüfen</div>
                            <div>
                              2. Standort für EMPFÄNGER im
                              "List" Tab suchen
                            </div>
                            <div>
                              3. Code für Standort mit
                              <i class="fa fa-table"></i> Tab suchen
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_ROUTE/route_receiver_number_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>4. Stempel für EMPFÄNGER wählen</div>
                            <div>5. Feld UNTEN RECHTS stempeln</div>
                            <div></div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_ROUTE/route_receiver_letter_1.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>1. Empfänger prüfen</div>
                            <div>
                              2. Standort für EMPFÄNGER im
                              "List" Tab suchen
                            </div>
                            <div>3. Stempel für EMPFÄNGER wählen</div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_ROUTE/route_receiver_letter_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>4. Feld UNTEN RECHTS stempeln</div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <br />
                  </div>

                  <div id="ACT_PRICE_WI">
                    <div class="row">
                      <div class="column">
                        <img
                          class="mainImg"
                          src="./workInstructions/img/ADV_PRICE/PRICE.png"
                        />
                      </div>
                      <div class="column borderedDiv">
                        <h1 id="roleTitle" class="textCenter">BEPREISUNG</h1>
                        <p id="roleText" class="textCenter">
                          Spieler mit dieser Aufgabe berechnen den GEWICHT-,
                          STRECKEN- und GESAMTTARIF der Pakete
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="configOption generatedText marginHelper">
                      <div>
                        <div class="trainerHeadline">
                          <h2 class="trainer_customer">Aufgabe 1:</h2>
                        </div>
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_PRICE/bepreisung_1.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>
                              1. Paket aus der Inbox auf den Schreibtisch ziehen
                            </div>
                            <div>2. Sender- und Empfängernummer prüfen</div>
                            <div>3. Tarif in Tabelle suchen...</div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_PRICE/bepreisung_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>4. Passenden Stempel wählen</div>
                            <div>5. Feld OBEN LINKS stempeln</div>
                            <div></div>
                          </div>
                        </div>
                        <div class="trainerHeadline">
                          <h2 class="trainer_customer">Aufgabe 2:</h2>
                        </div>
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_PRICE/bepreisung_3.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>1. Gewicht prüfen</div>
                            <div>2. Gewichtstarif in Tabelle suchen</div>
                            <div>3. Korrekten Stempel wählen</div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_PRICE/bepreisung_4.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>4. OBERES MITTLERES Feld stempeln</div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                        <br />
                        <div class="trainerHeadline">
                          <h2 class="trainer_customer">Aufgabe 3:</h2>
                        </div>
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_PRICE/bepreisung_5.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>1. ROUTENTARIF und GEWICHTSTARIF addieren</div>
                            <div>2. Korrekten Stempel wählen</div>
                            <div>3. Feld OBEN RECHTS stempeln</div>
                          </div>
                        </div>
                        <br />
                        <br />
                      </div>
                      <br />
                      <div>
                        <div class="trainerHeadline">
                          <h2 class="trainer_customer">Aufgabe 1:</h2>
                        </div>
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_PRICE/bepreisung_1.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>
                              1. Paket aus der Inbox auf den Schreibtisch ziehen
                            </div>
                            <div>2. Sender- und Empfängernummer prüfen</div>
                            <div>3. Tarif in Tabelle suchen...</div>
                          </div>
                        </div>
                        <br />
                        <br />

                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_PRICE/bepreisung_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>4. Korrekten Stempel wählen</div>
                            <div>5. Feld OBEN LINKS stempeln</div>
                            <div></div>
                          </div>
                        </div>

                        <div class="trainerHeadline">
                          <h2 class="trainer_customer">Aufgabe 2:</h2>
                        </div>
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_PRICE/bepreisung_3.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>1. Gewicht prüfen</div>
                            <div>2. Gewichtstarif in Tabelle suchen</div>
                            <div>3. Korrekten Stempel wählen</div>
                          </div>
                        </div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_PRICE/Bepreisung_weight.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>4. Feld OBEN MITTE stempeln</div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_PRICE/bepreisung_5_1.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>1. Sender- und Empfängercode beachten</div>
                            <div>2. Routentarif codieren</div>
                            <div>3. Paketgewicht prüfen</div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_PRICE/bepreisung_5_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>4. Gewichtstarif codieren</div>
                            <div>5. Routentarif und Gewichtstarif adieren</div>
                            <div>6. Korrekten Stempel wählen</div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_PRICE/bepreisung_5_3.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>7. Gesamttarif stempeln (OBEN RECHTS)</div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <p>
                          Du musst für diese Aufgabe nichts tun, da dein Team
                          sich dazu entschieden hat deine AUfgabe an anderer
                          Stelle zu erledigen!
                        </p>
                      </div>
                    </div>
                    <div class="configOption generatedText marginHelper">
                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_PRICE/bepreisung_marker.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>1. Wähle den roten Marker</div>
                            <div>
                              2. Markiere den Gesamttarif und den Absendercode
                            </div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>

        <v-container v-else>
          <v-row>
            <v-col>
              <div id="CUSTOMER_WI">
    <h1 id="workTitle"> WORK INSTRUCTIONS </h1>

    <div class="row">
        <div class="column">
            <img class="mainImg" src="./workInstructions/img/CUSTOMER/mainImg.png">
        </div>
        <div class="column borderedDiv">
            <h1 id="roleTitle" class="textCenter"> CUSTOMER</h1>
            <p id="roleText" class="textCenter">
                Your location is one of four branches (North-South-East-West) of Product AG. You
                are responsible for dispatching parcels to employees of other sites.
            </p>
        </div>
    </div>
    <br>

    <div class="trainerHeadline">
        <h2>Task 1:</h2>
    </div>
    <br>
    <div class="comicStripAnchor">
        <img src="./workInstructions/img/CUSTOMER/CUSTOMER_1.png" class="generatedImg Center">
        <div class="comicStripText">
            <div>1. Send a Parcel once per Minute. Start at Minute 0!</div>
            <div></div>
            <div></div>
        </div>
    </div>

    <div class="trainerHeadline">
        <h2>Task 2:</h2>
    </div>
    <br>
    <div class="comicStripAnchor">
        <img src="./workInstructions/img/CUSTOMER/customer_1_en.png" class="generatedImg Center">
        <div class="comicStripText">
            <div>1. Drag a parcel from your inbox onto your desk</div>
            <div>2. Check the time difference</div>
            <div>3. Pick the Rejected-Stamp</div>
        </div>
    </div>
    <br>
    <br>
    <div class="comicStripAnchor">
        <img src="./workInstructions/img/CUSTOMER/customer_2_en.png" class="generatedImg Center">
        <div class="comicStripText">
            <div>4. Stamp onto the parcel</div>
            <div>5. Check the star stamp</div>
            <div>6. Check the accepted stars</div>
        </div>
    </div>
    <br>
    <br>
    <div class="comicStripAnchor">
        <img src="./workInstructions/img/CUSTOMER/customer_3_en.png" class="generatedImg Center">
        <div class="comicStripText">
            <div>7. Wrong star? Stamp onto the parcel</div>
            <div>8. Check the sender</div>
            <div>9. Check the employee list</div>
        </div>
    </div>
    <br>
    <br>
    <div class="comicStripAnchor">
        <img src="./workInstructions/img/CUSTOMER/customer_4_en.png" class="generatedImg Center">
        <div class="comicStripText">
            <div>10. Sender not in list? Stamp onto the parcel</div>
            <div>11. No errors? Stamp onto the parcel with the approved-stamp</div>
            <div>12. Drag the parcel into the outbox</div>
        </div>
    </div>
    <br>
    <h1 class="bar"></h1>
</div>

              <div id="COURIER_WI">
                <h1 id="workTitle">WORK INSTRUCTIONS</h1>

                <div class="row">
                  <div class="column">
                    <img
                      class="mainImg"
                      src="./workInstructions/img/COURIER/mainImg.png"
                    />
                  </div>
                  <div class="column borderedDiv">
                    <h1 id="roleTitle" class="textCenter">COURIER</h1>
                    <p id="roleText" class="textCenter">
                      It is your job to pick up the parcels from the four sites
                      of Product AG (North, South, East, West) and to deliver
                      processed parcels.
                    </p>
                  </div>
                </div>
                <div class="trainerHeadline">
                  <h2>Task 1:</h2>
                </div>
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/COURIER/courier_1.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>
                      1. Go to one of the 4 customer sites
                      (Mind the order!)
                    </div>
                    <div>2. Drag the parcels into your inventory</div>
                    <div>3. Go to the messenger's desk</div>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/COURIER/courier_2.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>4. Drag the parcels to the inbox</div>
                    <div></div>
                    <div></div>
                  </div>
                </div>

                <div class="trainerHeadline">
                  <h2>Task 2:</h2>
                </div>
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/COURIER/courier_4.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>1. Go to the desk of the HD Outgoing Goods</div>
                    <div>2. Drag parcels from the outbox to your inventory</div>
                    <div>3. Check the receiver number</div>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/COURIER/courier_5.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>
                      4. Open the <i class="fa fa-table"></i> Tab and look up
                      the correct location for the parcel
                    </div>
                    <div>5. Go to the correct location...</div>
                    <div>6. ...and drag the parcel to the customer's inbox</div>
                  </div>
                </div>
                <br />
                <h1 class="bar"></h1>
              </div>

              <div id="MESSENGER_WI">
                <h1 id="workTitle">WORK INSTRUCTIONS</h1>

                <div class="row">
                  <div class="column">
                    <img
                      class="mainImg"
                      src="./workInstructions/img/MESSENGER/mainImg.png"
                    />
                  </div>
                  <div class="column borderedDiv">
                    <h1 id="roleTitle" class="textCenter">MESSENGER</h1>
                    <p id="roleText" class="textCenter">
                      It is your job to move the parcels through the various
                      process steps of General Express once received from the
                      courier.
                    </p>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/MESSENGER/MESSENGER_1.png"
                    class="generatedImg Center"
                  />
                  <div class="comicStripText">
                    <div>1. Click on your desk</div>
                    <div>2. Drag a parcel into your inventory</div>
                    <div>
                      3. Check your "List"Tab for the
                      running order
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/MESSENGER/MESSENGER_2.png"
                    class="generatedImg Center"
                  />
                  <div class="comicStripText">
                    <div>4. Click on the next desk in the order</div>
                    <div>
                      2. Drag a parcel from your inventory into the inbox
                    </div>
                    <div></div>
                  </div>
                </div>
                <br />
                <h1 class="bar"></h1>
              </div>

              <div id="CL_INCOMING_GOODS_WI">
                <h1 id="workTitle">WORK INSTRUCTIONS</h1>

                <div class="row">
                  <div class="column">
                    <img
                      class="mainImg"
                      src="./workInstructions/img/CL_INCOMING_GOODS/mainImg.png"
                    />
                  </div>
                  <div class="column borderedDiv">
                    <h1 id="roleTitle" class="textCenter">
                      CLERK INCOMING GOODS
                    </h1>
                    <p id="roleText" class="textCenter">
                      It is your job to code the parcel based on the sender and
                      receiver information of the respective Product AG
                      branches.
                    </p>
                  </div>
                </div>
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/CL_INCOMING_GOODS/clerk_incoming_goods_1.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>1. Check the sender and receiver</div>
                    <div>
                      2. Look up the receiver's location in the
                      "List" tab
                    </div>
                    <div>
                      3. Look up the location code in the
                      <i class="fa fa-table"></i> tab
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/CL_INCOMING_GOODS/clerk_incoming_goods_2.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>4. Pick the correct stamp for the receiver</div>
                    <div>5. Stamp the BOTTOM RIGHT slot</div>
                    <div>
                      6. Look up the sender's location in the
                      "List" tab
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/CL_INCOMING_GOODS/clerk_incoming_goods_3.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>
                      7. Look up the location code in the
                      <i class="fa fa-table"></i> tab
                    </div>
                    <div>8. Pick the correct stamp for the sender</div>
                    <div>9. Stamp the BOTTOM LEFT slot</div>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/CL_INCOMING_GOODS/outbox.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>10. Drag the parcel to your outbox</div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <br />
                <h1 class="bar"></h1>
              </div>

              <div id="HD_INCOMING_GOODS_WI">
                <h1 id="workTitle">WORK INSTRUCTIONS</h1>

                <div class="row">
                  <div class="column">
                    <img
                      class="mainImg"
                      src="./workInstructions/img/HD_INCOMING_GOODS/mainImg.png"
                    />
                  </div>
                  <div class="column borderedDiv">
                    <h1 id="roleTitle" class="textCenter">
                      HEAD OF INCOMING GOODS
                    </h1>
                    <p id="roleText" class="textCenter">
                      It is your job to count the processed parcels for the
                      finance department.
                    </p>
                  </div>
                </div>
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/HD_INCOMING_GOODS/HD_INCOMING_GOODS_1.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>
                      1. Take a parcel from your inbox and drag it to your desk
                    </div>
                    <div>2. Check the sender and receiver number</div>
                    <div>
                      3. Open your <i class="fa fa-table"></i> Tab and click on
                      the correct slot
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/HD_INCOMING_GOODS/HD_INCOMING_GOODS_2.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>4. Drag the parcel to your outbox</div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <br />
                <h1 class="bar"></h1>
              </div>

              <div id="CL_WEIGHT_TARIF_WI">
                <h1 id="workTitle">WORK INSTRUCTIONS</h1>

                <div class="row">
                  <div class="column">
                    <img
                      class="mainImg"
                      src="./workInstructions/img/CL_WEIGHT_TARIF/mainImg.png"
                    />
                  </div>
                  <div class="column borderedDiv">
                    <h1 id="roleTitle" class="textCenter">
                      CLERK<br />WEIGHT TARIFF
                    </h1>
                    <p id="roleText" class="textCenter">
                      It is your job to put the right weight tarif onto the
                      parcel.
                    </p>
                  </div>
                </div>
                <br />
                <div class="comicStripAnchor">
                  <img
                    src="./workInstructions/img/CL_WEIGHT_TARIF/clerk_weight_tarif_1.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>
                      1. Take a parcel from your inbox and drag it to your desk
                    </div>
                    <div>2. Check the weight value</div>
                    <div>
                      3. Look up the tariff in your
                      <i class="fa fa-table"> tab</i>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <img
                    src="./workInstructions/img/CL_WEIGHT_TARIF/clerk_weight_tarif_2.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>4. Choose the correct stamp...</div>
                    <div>
                      5. ...and stamp it onto the UPPER CENTER slot on your
                      parcel
                    </div>
                    <div>6. Drag the parcel to your outbox</div>
                  </div>
                </div>
                <br />
                <h1 class="bar"></h1>
              </div>

              <div id="CL_ROUTE_TARIF_WI">
                <h1 id="workTitle">WORK INSTRUCTIONS</h1>

                <div class="row">
                  <div class="column">
                    <img
                      class="mainImg"
                      src="./workInstructions/img/CL_ROUTE_TARIF/mainImg.png"
                    />
                  </div>
                  <div class="column borderedDiv">
                    <h1 id="roleTitle" class="textCenter">
                      CLERK<br />ROUTE TARIFF
                    </h1>
                    <p id="roleText" class="textCenter">
                      It is your job to put the right route tariff onto the
                      parcel.
                    </p>
                  </div>
                </div>
                <br />
                <div class="comicStripAnchor">
                  <img
                    src="./workInstructions/img/CL_ROUTE_TARIF/clerk_route_tarif_1.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>
                      1. Take a parcel from your inbox and drag it to your desk
                    </div>
                    <div>2. Check the sender and receiver number</div>
                    <div>
                      3.Open the <i class="fa fa-table"></i> Tab and look up the
                      correct pricing
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <img
                    src="./workInstructions/img/CL_ROUTE_TARIF/clerk_route_tarif_2.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>4. Choose the correct stamp...</div>
                    <div>
                      5. ...and stamp it on the TOP LEFT slot on the parcel
                    </div>
                    <div>6. Drag the parcel into your outbox</div>
                  </div>
                </div>
                <br />
                <h1 class="bar"></h1>
              </div>

              <div id="CL_ACCOUNTING_WI">
                <h1 id="workTitle">WORK INSTRUCTIONS</h1>

                <div class="row">
                  <div class="column">
                    <img
                      class="mainImg"
                      src="./workInstructions/img/CL_ACCOUNTING/mainImg.png"
                    />
                  </div>
                  <div class="column borderedDiv">
                    <h1 id="roleTitle" class="textCenter">
                      CLERK<br />ACCOUNTING
                    </h1>
                    <p id="roleText" class="textCenter">
                      It is your job to calculate the overall tarif per parcel.
                    </p>
                  </div>
                </div>
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/CL_ACCOUNTING/clerk_accounting_1.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>
                      1. Take a parcel from your inbox and drag it to your desk
                    </div>
                    <div>
                      2. Calculate the OVERALL TARIFF by adding the ROUTE TARIFF
                      and the WEIGHT TARIFF
                    </div>
                    <div>3. Choose the correct stamp</div>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/CL_ACCOUNTING/clerk_accounting_2.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>
                      4. Stamp the pricing into the TOP RIGHT slot on the parcel
                    </div>
                    <div>5. Pick the red marker on your desk</div>
                    <div>6. Mark the OVERALL TARIFF and the SENDER ADDRESS</div>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/CL_ACCOUNTING/clerk_accounting_3.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>7. Drag the parcel into your outbox</div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <br />
                <h1 class="bar"></h1>
              </div>

              <div id="HD_FINANCE_WI">
                <h1 id="workTitle">WORK INSTRUCTIONS</h1>

                <div class="row">
                  <div class="column">
                    <img
                      class="mainImg"
                      src="./workInstructions/img/HD_FINANCE/mainImg.png"
                    />
                  </div>
                  <div class="column borderedDiv">
                    <h1 id="roleTitle" class="textCenter">
                      HEAD OF<br />FINANCE
                    </h1>
                    <p id="roleText" class="textCenter">
                      It is your job to keep track of the accounting and
                      billing.
                    </p>
                  </div>
                </div>
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/HD_FINANCE/head_finance_1.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>
                      1. Take a parcel from your inbox and drag it to your desk
                    </div>
                    <div>
                      2. Check if the Overall Tariff is calculated correctly
                    </div>
                    <div>3. Check the sender number</div>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/HD_FINANCE/head_finance_2.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>
                      4. Write the OVERALL TARIFF in the correct coloumn
                    </div>
                    <div>5. Drag the parcel to your outbox</div>
                    <div></div>
                  </div>
                </div>
                <br />
                <h1 class="bar"></h1>
              </div>

              <div id="CL_OUTGOING_GOODS_WI">
                <h1 id="workTitle">WORK INSTRUCTIONS</h1>

                <div class="row">
                  <div class="column">
                    <img
                      class="mainImg"
                      src="./workInstructions/img/CL_OUTGOING_GOODS/mainImg.png"
                    />
                  </div>
                  <div class="column borderedDiv">
                    <h1 id="roleTitle" class="textCenter">
                      CLERK<br />OUTGOING GOODS
                    </h1>
                    <p id="roleText" class="textCenter">
                      It is your job to enhance the parcel with an additional
                      quality feature. This feature has been requested by our
                      customers.
                    </p>
                  </div>
                </div>
                <br />
                <div class="comicStripAnchor">
                  <img
                    src="./workInstructions/img/CL_OUTGOING_GOODS/CL_OUTGOING_GOODS_1.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>
                      1. Take a parcel from your inbox and drag it to your desk
                    </div>
                    <div>2. Pick a star stamp...</div>
                    <div>3. ...and a color</div>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <img
                    src="./workInstructions/img/CL_OUTGOING_GOODS/CL_OUTGOING_GOODS_2.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>4. Stamp a random place on the parcel</div>
                    <div>5. Drag the parcel into your outbox</div>
                    <div></div>
                  </div>
                </div>
                <br />
                <h1 class="bar"></h1>
              </div>

              <div id="HD_OUTGOING_GOODS_WI">
                <h1 id="workTitle">WORK INSTRUCTIONS</h1>

                <div class="row">
                  <div class="column">
                    <img
                      class="mainImg"
                      src="./workInstructions/img/HD_OUTGOING_GOODS/mainImg.png"
                    />
                  </div>
                  <div class="column borderedDiv">
                    <h1 id="roleTitle" class="textCenter">
                      HEAD OF<br />OUTGOING GOODS
                    </h1>
                    <p id="roleText" class="textCenter">
                      It is your job to count the processed parcels for the
                      finance department.
                    </p>
                  </div>
                </div>
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/HD_OUTGOING_GOODS/hd_outgoing_goods_1.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>
                      1. Take a parcel from your inbox and drag it to your desk
                    </div>
                    <div>2. Check the sender and receiver number</div>
                    <div>
                      3. Open your <i class="fa fa-table"></i> Tab and click on
                      the correct slot
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/HD_OUTGOING_GOODS/hd_outgoing_goods_2.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>4. Drag the parcel to your outbox</div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <br />
                <h1 class="bar"></h1>
              </div>

              <div id="MANAGING_DIRECTOR_WI">
                <h1 id="workTitle">WORK INSTRUCTIONS</h1>

                <div class="row">
                  <div class="column">
                    <img
                      class="mainImg"
                      src="./workInstructions/img/MANAGING_DIRECTOR/mainImg.png"
                    />
                  </div>
                  <div class="column borderedDiv">
                    <h1 id="roleTitle" class="textCenter">MANAGING DIRECTOR</h1>
                    <p id="roleText" class="textCenter">
                      You are the BIG BOSS! It is your job to manage the daily
                      business by ensuring smooth operations for each function.
                    </p>
                  </div>
                </div>
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/MANAGING_DIRECTOR/Managing_Director.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>1. Observate and control the overall process</div>
                    <div>2. Try to motivate your co-workers</div>
                    <div>
                      3. You can find a Texteditor in your
                      <i class="fa fa-table"></i> Tab
                    </div>
                  </div>
                </div>
                <br />
                <div id="bar"></div>
              </div>

              <div id="SALES_MANAGER_WI">
                <div class="row">
                  <div class="column">
                    <img
                      class="mainImg"
                      src="./workInstructions/img/SALES_MANAGER/mainImg.png"
                    />
                  </div>
                  <div class="column borderedDiv">
                    <h1 id="roleTitle" class="textCenter">SALES MANAGER</h1>
                    <p id="roleText" class="textCenter">
                      It is your job to ensure the customers satisfaction by
                      regularly gathering feedback at the customer sites. Make
                      sure to report the feedback to your Managing Director.
                    </p>
                  </div>
                </div>
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/SALES_MANAGER/Sales_Manager.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>1. Check the customer sites regularly</div>
                    <div>2. Gather feedback</div>
                    <div>
                      3. Report the feedback to your Managing Director (provided
                      he is not on vacation)
                    </div>
                  </div>
                </div>
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/CONTROLLER/notizen.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>
                      4. You can find a Texteditor in your
                      <i class="fa fa-table"></i> Tab
                    </div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <br />
                <div id="bar"></div>
              </div>

              <div id="CONTROLLER_WI">
                <h1 id="workTitle">WORK INSTRUCTIONS</h1>
                <div class="row">
                  <div class="column">
                    <img
                      class="mainImg"
                      src="./workInstructions/img/CONTROLLER/mainImg.png"
                    />
                  </div>
                  <div class="column borderedDiv">
                    <h1 id="roleTitle" class="textCenter">CONTROLLER</h1>
                    <p id="roleText" class="textCenter">
                      It is your job to ensure a smooth process for each task.
                      Keep track of the process times and make sure to report
                      them to your Managing Director.
                    </p>
                  </div>
                </div>
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/CONTROLLER/Controller.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>1. Watch the process</div>
                    <div>2. Keep track of the process times</div>
                    <div>
                      3. Report the process times to your Managing Director
                      (provided he is not on vacation)
                    </div>
                  </div>
                </div>
                <br />
                <div class="comicStripAnchor">
                  <!-- <h4 id="tableHead">Customer visits courier</h4> -->
                  <img
                    src="./workInstructions/img/CONTROLLER/notizen.png"
                    class="Center"
                  />
                  <div class="comicStripText">
                    <div>
                      4. You can find a Texteditor in your
                      <i class="fa fa-table"></i> Tab
                    </div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <br />
                <div id="bar"></div>
              </div>

              <div style="display:none">
                <div id="WI_ADV">
                  <div id="ACT_IN_WI">
                    <div class="row">
                      <div class="column">
                        <img
                          class="mainImg"
                          src="./workInstructions/img/ADV_RETRIEVE/RETRIEVE.png"
                        />
                      </div>
                      <div class="column borderedDiv">
                        <h1 id="roleTitle" class="textCenter">RETRIEVE</h1>
                        <p id="roleText" class="textCenter">
                          Players with this task are responsible for the picking
                          up parcels from the CUSTOMER SITES and PRODUCTION
                          LINES.
                        </p>
                      </div>
                    </div>
                    <br />

                    <div class="configOption generatedText marginHelper">
                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_RETRIEVE/Empfangen_reihenfolge_1.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>
                              1. Open the <i class="fa fa-table"></i> Tab and
                              check the order in which the parcels have to be
                              collected
                            </div>
                            <div>2. Click the correct customer site</div>
                            <div>
                              3. Drag and drop parcels into your inventory
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_RETRIEVE/Empfangen_reihenfolge_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>
                              4. Navigate to the start of the production line/s
                              which is the desk furthest to the right
                            </div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_RETRIEVE/Empfangen_anywhere_1.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>1. Choose a customer site</div>
                            <div>2. Click the customer site</div>
                            <div>
                              3. Drag parcels from the outbox to your inventory
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_RETRIEVE/Empfangen_anywhere_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>
                              4. Click the start of the PRODUCTION LINE/S which
                              is the desk furthest to the right
                            </div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="ACT_OUT_WI">
                    <div class="row">
                      <div class="column">
                        <img
                          class="mainImg"
                          src="./workInstructions/img/ADV_DELIVER/DELIVER.png"
                        />
                      </div>
                      <div class="column borderedDiv">
                        <br /><br />
                        <h1 id="roleTitle" class="textCenter">DELIVER</h1>
                        <p id="roleText" class="textCenter">
                          Players with this task are responsible for delivering
                          the parcels from PRODUCTION LINE/S to the CUSTOMER
                          SITES.
                        </p>
                      </div>
                    </div>
                    <br />

                    <div class="configOption">
                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_DELIVER/Liefern_anywhere_1.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>
                              1. Navigate to the END of the PRODUCTION LINE/S
                              which is located at the desk furthest to the left
                            </div>
                            <div>
                              2. Drag parcels from the outbox to your inventory
                            </div>
                            <div>
                              3. Check the receiver's code in the BOTTOM RIGHT
                              slot
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_DELIVER/Liefern_anywhere_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>4. Translate the location code</div>
                            <div>5. Go to a customer site and click it</div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_DELIVER/Liefern_buchstaben_1.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>
                              1. Navigate to the END of the PRODUCTION LINE/S
                              which is located at the desk furthest to the left
                            </div>
                            <div>
                              2. Drag parcels from the outbox to your inventory
                            </div>
                            <div>
                              3. Check the receiver's code in the BOTTOM RIGHT
                              slot
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_DELIVER/Liefern_buchstaben_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>
                              4. Go to the customer site thats next in the order
                              and click it
                            </div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="ACT_STAR_WI">
                    <div class="row">
                      <div class="column">
                        <img
                          class="mainImg"
                          src="./workInstructions/img/ADV_STAR/STAR.png"
                        />
                      </div>
                      <div class="column borderedDiv">
                        <h1 id="roleTitle" class="textCenter">STAR</h1>
                        <p id="roleText" class="textCenter">
                          It is your task, to enhance the parcel with an
                          additional quality feature in form of a STAR stamp.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="generatedText marginHelper">
                      <div class="comicStripAnchor">
                        <img
                          src="./workInstructions/img/ADV_STAR/adv_stern_1.png"
                          class="Center"
                        />
                        <div class="comicStripText">
                          <div>1. Pick a star stamp</div>
                          <div>2. Pick a color</div>
                          <div>3. Stamp a random place on the package</div>
                        </div>
                      </div>
                      <br />
                      <br />
                    </div>
                  </div>

                  <div id="ACT_FIN_WI">
                    <div class="row">
                      <div class="column">
                        <img
                          class="mainImg"
                          src="./workInstructions/img/ADV_BILLING/BILLING.png"
                        />
                      </div>
                      <div class="column borderedDiv">
                        <h1 id="roleTitle" class="textCenter">BILLING</h1>
                        <p id="roleText" class="textCenter">
                          Players with this task are responsible for the
                          Billing.
                        </p>
                      </div>
                    </div>
                    <div class="configOption generatedText marginHelper">
                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_BILLING/berechnung_1.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>
                              1. Check the sender (bottom right) and receiver
                              code (bottom left)
                            </div>
                            <div>
                              2. Open up your <i class="fa fa-table"></i> Tab to
                              find the correct ROUTE TARIFF and keep it in mind
                            </div>
                            <div>3. Check the weight value</div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_BILLING/berechnung_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>
                              4. Open up your <i class="fa fa-table"></i> Tab to
                              find the correct WEIGHT TARIFF and keep it in mind
                            </div>
                            <div>
                              5. Add the ROUTE TARIFF and the WEIGHT TARIFF to
                              calculate the OVERALL TARIFF
                            </div>
                            <div>
                              6. Open up your <i class="fa fa-table"></i> Tab to
                              fill in the OVERALL TARIFF in the finance report
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                      </div>
                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_BILLING/berechnung_option_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>
                              1. Add the ROUTE TARIFF and the WEIGHT TARIFF to
                              calculate the OVERALL TARIFF
                            </div>
                            <div>
                              2. Open up your <i class="fa fa-table"></i> Tab to
                              fill in the OVERALL TARIFF in the finance report
                            </div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_BILLING/berechnung_option_3.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>1. Check the OVERALL TARIFF</div>
                            <div>
                              2. Open up your <i class="fa fa-table"></i> Tab to
                              fill in the OVERALL TARIFF in the finance report
                            </div>
                            <div></div>
                          </div>
                        </div>
                        <br />
                      </div>
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>

                  <div id="ACT_ROUTE_WI">
                    <div class="row">
                      <div class="column">
                        <img
                          class="mainImg"
                          src="./workInstructions/img/ADV_ROUTE/ROUTE.png"
                        />
                      </div>
                      <div class="column borderedDiv">
                        <h1 id="roleTitle" class="textCenter">ROUTE</h1>
                        <p id="roleText" class="textCenter">
                          Players with this task have to look up and stamp the
                          correct location for SENDER and RECEIVER on the
                          parcels.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="configOption generatedText marginHelper">
                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_ROUTE/route_1.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>1. Check the sender and receiver</div>
                            <div>
                              2. Look up the receiver's location in the
                              "List" tab
                            </div>
                            <div>
                              3. Look up the location code in the
                              <i class="fa fa-table"></i> tab
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_ROUTE/route_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>
                              4. Pick the correct stamp for the receiver
                            </div>
                            <div>5. Stamp the BOTTOM RIGHT slot</div>
                            <div>
                              6. Look up the sender's location in the
                              "List" tab
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_ROUTE/route_3.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>
                              7. Look up the location code in the
                              <i class="fa fa-table"></i> tab
                            </div>
                            <div>8. Pick the correct stamp for the sender</div>
                            <div>9. Stamp the BOTTOM LEFT slot</div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_ROUTE/route_adv_1.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>1. Check the sender and receiver</div>
                            <div>
                              2. Look up the receiver's location in the
                              "List" tab
                            </div>
                            <div>
                              3. Pick the correct stamp for the receiver
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_ROUTE/route_adv_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>4. Stamp the BOTTOM RIGHT slot</div>
                            <div>
                              5. Look up the sender's location in the
                              "List" tab
                            </div>
                            <div>6. Pick the correct stamp for the sender</div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_ROUTE/route_adv_3.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>7. Stamp the BOTTOM LEFT slot</div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_ROUTE/route_sender_number_1.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>1. Check the sender</div>
                            <div>
                              2. Look up the sender's location in the
                              <i class="fa fa-table"></i> tab
                            </div>
                            <div>
                              3. Look up the location code in the
                              <i class="fa fa-table"></i> tab
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_ROUTE/route_sender_number_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>4. Pick the correct stamp for the sender</div>
                            <div>5. Stamp the BOTTOM LEFT slot</div>
                            <div></div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_ROUTE/route_sender_letter_1.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>1. Check the sender</div>
                            <div>
                              2. Look up the sender's location in the
                              <i class="fa fa-table"></i> tab
                            </div>
                            <div>3. Pick the correct stamp for the sender</div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_ROUTE/route_sender_letter_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>4. Stamp the BOTTOM LEFT slot</div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_ROUTE/route_receiver_number_1.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>1. Check the receiver</div>
                            <div>
                              2. Look up the receiver's location in the
                              <i class="fa fa-table"></i> tab
                            </div>
                            <div>
                              3. Look up the location code in the
                              <i class="fa fa-table"></i> tab
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_ROUTE/route_receiver_number_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>
                              4. Pick the correct stamp for the receiver
                            </div>
                            <div>5. Stamp the BOTTOM RIGHT slot</div>
                            <div></div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_ROUTE/route_receiver_letter_1.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>1. Check the receiver</div>
                            <div>
                              2. Look up the receiver's location in the
                              <i class="fa fa-table"></i> tab
                            </div>
                            <div>
                              3. Pick the correct stamp for the receiver
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_ROUTE/route_receiver_letter_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>4. Stamp the BOTTOM RIGHT slot</div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <br />
                    <br />
                    <br />
                  </div>

                  <div id="ACT_PRICE_WI">
                    <div class="row">
                      <div class="column">
                        <img
                          class="mainImg"
                          src="./workInstructions/img/ADV_PRICE/PRICE.png"
                        />
                      </div>
                      <div class="column borderedDiv">
                        <h1 id="roleTitle" class="textCenter">PRICE</h1>
                        <p id="roleText" class="textCenter">
                          Players with this task have to calculate the ROUTE,
                          WEIGHT and TOTAL TARIF of the parcels.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="configOption generatedText marginHelper">
                      <div>
                        <div class="trainerHeadline">
                          <h2 class="trainer_customer">Task 1:</h2>
                        </div>
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_PRICE/bepreisung_1.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>
                              1. Drag a parcel from your inbox to your desk
                            </div>
                            <div>2. Check the sender and receiver code</div>
                            <div>3. Look up the correct ROUTE TARIF</div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_PRICE/bepreisung_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>4. Pick the correct stamp</div>
                            <div>5. Stamp the UPPER LEFT slot</div>
                            <div></div>
                          </div>
                        </div>
                        <br />
                        <div class="trainerHeadline">
                          <h2 class="trainer_customer">Task 2:</h2>
                        </div>
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_PRICE/bepreisung_3.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>1. Check the weight of the parcel</div>
                            <div>
                              2. Open up your <i class="fa fa-table"></i> Tab
                              and look up the WEIGHT tarif for the parcels
                              weight
                            </div>
                            <div>3. Pick the correct stamp</div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_PRICE/bepreisung_4.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>4. Stamp the UPPER MIDDLE slot</div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                        <br />
                        <div class="trainerHeadline">
                          <h2 class="trainer_customer">Task 3:</h2>
                        </div>
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_PRICE/bepreisung_5.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>
                              1. Add the ROUTE TARIFF and the WEIGHT TARIFF
                            </div>
                            <div>2. Pick the correct stamp</div>
                            <div>3. Stamp the UPPER RIGHT slot</div>
                          </div>
                        </div>
                        <br />
                        <br />
                      </div>

                      <div>
                        <div class="trainerHeadline">
                          <h2 class="trainer_customer">Task 1:</h2>
                        </div>
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_PRICE/bepreisung_1.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>
                              1. Drag a parcel from your inbox to your desk
                            </div>
                            <div>2. Check the sender and receiver code</div>
                            <div>
                              3. Look up the ROUTE TARIF for the parcels SENDER
                              and receiver code combination
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_PRICE/bepreisung_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>4. Pick the correct stamp</div>
                            <div>5. Stamp the UPPER LEFT slot</div>
                            <div></div>
                          </div>
                        </div>
                        <br />
                        <div class="trainerHeadline">
                          <h2 class="trainer_customer">Task 2:</h2>
                        </div>
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_PRICE/bepreisung_3.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>1. Check the weight of the parcel</div>
                            <div>
                              2. Open up your <i class="fa fa-table"></i> Tab
                              and look up the WEIGHT tarif for the parcels
                              weight
                            </div>
                            <div>3. Pick the correct stamp</div>
                          </div>
                        </div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_PRICE/Bepreisung_weight.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>4. Stamp the UPPER MIDDLE slot</div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div class="trainerHeadline">
                          <h2 class="trainer_customer">Task 1:</h2>
                        </div>
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_PRICE/bepreisung_5_1.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>1. Check the sender and receiver code</div>
                            <div>2. Look up the correct ROUTE TARIFF</div>
                            <div>3. Check the parcels weight value</div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_PRICE/bepreisung_5_2.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>4. Look up the WEIGHT TARIFF</div>
                            <div>
                              5. Add the ROUTE TARIFF and the WEIGHT TARIFF
                            </div>
                            <div>6. Pick the correct stamp</div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_PRICE/bepreisung_5_3.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>
                              7. Stamp the OVERALL TARIFF onto the UPPER RIGHT
                              slot
                            </div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <p>
                          You do not have to do anything for this task as your
                          Team decided to do it elsewhere!
                        </p>
                      </div>
                    </div>

                    <div class="configOption generatedText marginHelper">
                      <div>
                        <div class="comicStripAnchor">
                          <img
                            src="./workInstructions/img/ADV_PRICE/bepreisung_marker.png"
                            class="Center"
                          />
                          <div class="comicStripText">
                            <div>1. Pick the red marker</div>
                            <div>
                              2. Mark the OVERALL TARIFF and the SENDER CODE
                            </div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <h1 class="bar"></h1>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>

      <v-tab-item>
        <div id="worklist" class="tabcontent center">
          <div class="en">
            <h1>Tables</h1>
          </div>

          <br />

          <h3>CL INCOMING GOODS</h3>
          <div id="CL_INCOMING_GOODS">
            <table class="tg" style="max-width: 463px">
              <colgroup>
                <col style="width: 187px" />
                <col style="width: 189px" />
              </colgroup>
              <thead>
                <tr>
                  <th class="accent font whiteFont bold alignCenter">
                    Location<br />Sender/Receiver
                  </th>
                  <th class="accent font whiteFont bold alignCenter">
                    Code<br />Sender/Receiver
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="inputBox font bold alignCenter">
                    Product AG North
                  </td>
                  <td class="inputBox font bold alignCenter">1</td>
                </tr>
                <tr>
                  <td class="inputBox font bold alignCenter">
                    Product AG South
                  </td>
                  <td class="inputBox font bold alignCenter">2</td>
                </tr>
                <tr>
                  <td class="inputBox font bold alignCenter">
                    Product AG East
                  </td>
                  <td class="inputBox font bold alignCenter">3</td>
                </tr>
                <tr>
                  <td class="inputBox font bold alignCenter">
                    Product AG West
                  </td>
                  <td class="inputBox font bold alignCenter">4</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="bar"></div>
          <h3>CL ROUTE TARIF</h3>
          <div id="CL_ROUTE_TARIF">
            <table class="tg" style="width: 400px">
              <colgroup>
                <col style="width: 87px" />
                <col style="width: 76px" />
                <col style="width: 76px" />
                <col style="width: 76px" />
                <col style="width: 77px" />
              </colgroup>
              <thead>
                <tr>
                  <th
                    class="accent font fontMedium whiteFont alignCenter"
                    colspan="5"
                  >
                    ROUTE TARIFFS
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    class="tableBackgroundMain font whiteFont bold alignCenter"
                    rowspan="2"
                  >
                    Sender Code<br />(From)
                  </td>
                  <td class="greyBox font bold alignCenter" colspan="4">
                    Receiver code <br />(To)
                  </td>
                </tr>
                <tr>
                  <td class="greyBox font bold alignCenter">1</td>
                  <td class="greyBox font bold alignCenter">2</td>
                  <td class="greyBox font bold alignCenter">3</td>
                  <td class="greyBox font bold alignCenter">4</td>
                </tr>
                <tr>
                  <td
                    class="tableBackgroundMain font whiteFont bold alignCenter"
                  >
                    1
                  </td>
                  <td class="inputBox font bold alignCenter"></td>
                  <td class="inputBox font bold alignCenter">€ 25,-</td>
                  <td class="inputBox font bold alignCenter">€ 20,-</td>
                  <td class="inputBox font bold alignCenter">€ 20,-</td>
                </tr>
                <tr>
                  <td
                    class="tableBackgroundMain font whiteFont bold alignCenter"
                  >
                    2
                  </td>
                  <td class="inputBox font bold alignCenter">€ 25,-</td>
                  <td class="inputBox font bold alignCenter"></td>
                  <td class="inputBox font bold alignCenter">€ 20,-</td>
                  <td class="inputBox font bold alignCenter">€ 20,-</td>
                </tr>
                <tr>
                  <td
                    class="tableBackgroundMain font whiteFont bold alignCenter"
                  >
                    3
                  </td>
                  <td class="inputBox font bold alignCenter">€ 20,-</td>
                  <td class="inputBox font bold alignCenter">€ 20,-</td>
                  <td class="inputBox font bold alignCenter"></td>
                  <td class="inputBox font bold alignCenter">€ 25,-</td>
                </tr>
                <tr>
                  <td
                    class="tableBackgroundMain font whiteFont bold alignCenter"
                  >
                    4
                  </td>
                  <td class="inputBox font bold alignCenter">€ 20,-</td>
                  <td class="inputBox font bold alignCenter">€ 20,-</td>
                  <td class="inputBox font bold alignCenter">€ 25,-</td>
                  <td class="inputBox font bold alignCenter"></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="bar"></div>
          <h3>CL WEIGHT TARIF</h3>
          <div id="CL_WEIGHT_TARIF">
            <table class="tg" style="table-layout: fixed; width: 152px">
              <colgroup>
                <col style="width: 200px" />
                <col style="width: 200px" />
              </colgroup>
              <thead>
                <tr>
                  <th
                    class="accent font fontMedium whiteFont bold alignCenter"
                    colspan="2"
                  >
                    WEIGHT TARIFS
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="greyBox font bold alignCenter">Weight in kg</td>
                  <td class="greyBox font bold alignCenter">Weight tarif</td>
                </tr>
                <tr>
                  <td class="inputBox font bold alignCenter">1</td>
                  <td class="inputBox font bold alignCenter">€ 20,-</td>
                </tr>
                <tr>
                  <td class="inputBox font bold alignCenter">2</td>
                  <td class="inputBox font bold alignCenter">€ 25,-</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="bar"></div>
          <h3>HD OUTGOING GOODS</h3>
          <br />
          <div id="HD_OUTGOING_GOODS">
            <!-- <div id="bar"></div> -->
            <div class="de">
              <p class="tableInstruction">
                Absender- (From) sowie Empfängercodes (To) prüfen und
                richtiges Feld der Tabelle anklicken, um Anzahl zu
                erhöhen.<br />
                Tipp: Gesamtsumme der Pakete wird automatisch kalkuliert!
              </p>
            </div>
            <div class="en">
              <p class="tableInstruction">
                Check the sender and receiver codes and click the correct slot
                to increase its amount.<br />
                Hint: The overall sum of parcels is calculated automatically!
              </p>
            </div>

            <table class="borderSpacing tg center">
              <colgroup>
                <col style="width: 20%" />
                <col style="width: 16%" />
                <col style="width: 16%" />
                <col style="width: 16%" />
                <col style="width: 16%" />
                <col style="width: 16%" />
              </colgroup>
              <thead>
                <tr>
                  <th class="tableBackgroundMain border"></th>
                  <th
                    class="
                      whiteFont
                      tableBackgroundMain
                      alignCenter
                      font
                      padding
                      border
                      bold
                      font
                    "
                    colspan="4"
                  >
                    Receiver (to)
                  </th>
                  <th
                    class="undoButton"
                    onclick="restorePreviousTableState('HD_OUTGOING_GOODS', 0)"
                  >
                    <v-icon>mdi-undo-variant</v-icon>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="whiteFont tableBackgroundMain border bold font">
                    Sender (from)
                  </td>
                  <td class="greyBox border font">North (1)</td>
                  <td class="greyBox border font">South (2)</td>
                  <td class="greyBox border font">East (3)</td>
                  <td class="greyBox border font">West (4)</td>
                  <td class="greyBox border bold font">
                    Number of parcels per<br />sender location
                  </td>
                </tr>
                <tr>
                  <td class="whiteFont tableBackgroundMain border bold font">
                    North (1)
                  </td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDIncGoods')"
                  ></td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDIncGoods')"
                  ></td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDIncGoods')"
                  ></td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDIncGoods')"
                  ></td>
                  <td class="inputBox border"></td>
                </tr>
                <tr>
                  <td class="whiteFont tableBackgroundMain border bold font">
                    South (2)
                  </td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDIncGoods')"
                  ></td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDIncGoods')"
                  ></td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDIncGoods')"
                  ></td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDIncGoods')"
                  ></td>
                  <td class="inputBox border"></td>
                </tr>
                <tr>
                  <td class="whiteFont tableBackgroundMain border bold font">
                    East (3)
                  </td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDIncGoods')"
                  ></td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDIncGoods')"
                  ></td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDIncGoods')"
                  ></td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDIncGoods')"
                  ></td>
                  <td class="inputBox border"></td>
                </tr>
                <tr>
                  <td class="whiteFont tableBackgroundMain border bold font">
                    West (4)
                  </td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDIncGoods')"
                  ></td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDIncGoods')"
                  ></td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDIncGoods')"
                  ></td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDIncGoods')"
                  ></td>
                  <td class="inputBox border"></td>
                </tr>
                <tr>
                  <td class="accent border"></td>
                  <td
                    class="
                      whiteFont
                      accent
                      border
                      alignRight
                      bold
                      font
                      fontSmall
                    "
                    colspan="4"
                  >
                    Overall sum of parcels
                  </td>
                  <td
                    id="totalSumHDIncGoods"
                    class="inputBox accent border"
                  ></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="bar"></div>
          <h3>HD INCOMING GOODS</h3>

          <div id="HD_INCOMING_GOODS">
            <!-- <div id="bar"></div> -->
            <div class="de">
              <p class="tableInstruction">
                Absender- (From) sowie Empfängercodes (To) prüfen und
                richtiges Feld der Tabelle anklicken, um Anzahl zu
                erhöhen.<br />
                Tipp: Gesamtsumme der Pakete wird automatisch kalkuliert!
              </p>
            </div>
            <div class="en">
              <p class="tableInstruction">
                Check the sender and receiver codes and click the correct slot
                to increase its amount.<br />
                Hint: The overall sum of parcels is calculated automatically!
              </p>
            </div>

            <table class="borderSpacing tg center">
              <colgroup>
                <col style="width: 20%" />
                <col style="width: 16%" />
                <col style="width: 16%" />
                <col style="width: 16%" />
                <col style="width: 16%" />
                <col style="width: 16%" />
              </colgroup>
              <thead>
                <tr>
                  <th class="tableBackgroundMain border"></th>
                  <th
                    class="
                      whiteFont
                      tableBackgroundMain
                      alignCenter
                      font
                      padding
                      border
                      bold
                      font
                    "
                    colspan="4"
                  >
                    Receiver (to)
                  </th>
                  <th
                    class="undoButton"
                    onclick="restorePreviousTableState('HD_INCOMING_GOODS', 0)"
                  >
                    <v-icon>mdi-undo-variant</v-icon>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="whiteFont tableBackgroundMain border bold font">
                    Sender (from)
                  </td>
                  <td class="greyBox border font">North (1)</td>
                  <td class="greyBox border font">South (2)</td>
                  <td class="greyBox border font">East (3)</td>
                  <td class="greyBox border font">West (4)</td>
                  <td class="greyBox border bold font">SUM</td>
                </tr>
                <tr>
                  <td class="whiteFont tableBackgroundMain border bold font">
                    North (1)
                  </td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDOutGoods')"
                  ></td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDOutGoods')"
                  ></td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDOutGoods')"
                  ></td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDOutGoods')"
                  ></td>
                  <td class="inputBox border"></td>
                </tr>
                <tr>
                  <td class="whiteFont tableBackgroundMain border bold font">
                    South (2)
                  </td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDOutGoods')"
                  ></td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDOutGoods')"
                  ></td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDOutGoods')"
                  ></td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDOutGoods')"
                  ></td>
                  <td class="inputBox border"></td>
                </tr>
                <tr>
                  <td class="whiteFont tableBackgroundMain border bold font">
                    East (3)
                  </td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDOutGoods')"
                  ></td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDOutGoods')"
                  ></td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDOutGoods')"
                  ></td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDOutGoods')"
                  ></td>
                  <td class="inputBox border"></td>
                </tr>
                <tr>
                  <td class="whiteFont tableBackgroundMain border bold font">
                    West (4)
                  </td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDOutGoods')"
                  ></td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDOutGoods')"
                  ></td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDOutGoods')"
                  ></td>
                  <td
                    class="inputBox border"
                    onclick="addOne(event, true, 'totalSumHDOutGoods')"
                  ></td>
                  <td class="inputBox border"></td>
                </tr>
                <tr>
                  <td class="accent border"></td>
                  <td
                    class="
                      whiteFont
                      accent
                      border
                      alignRight
                      bold
                      font
                      fontSmall
                    "
                    colspan="4"
                  >
                    Overall sum of parcels
                  </td>
                  <td
                    id="totalSumHDOutGoods"
                    class="inputBox accent border"
                  ></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="bar"></div>
          <h3>HD FINANCE</h3>
          <div id="HD_FINANCE">
            <div class="de">
              <p class="tableInstruction">
                Empfängercode prüfen. Gesamttarif in unterste Zeile der
                Spalte des Senders eintragen. Auf
                <v-icon>mdi-send</v-icon> Button klicken. Tabelle ergänzt sich
                automatisch.
              </p>
            </div>
            <div class="en">
              <p class="tableInstruction">
                Check the receiver code. Enter the overall tarif in the bottom
                row of the corresponding column. Click the
                <v-icon>mdi-send</v-icon> icon. Table completes automatically
              </p>
            </div>
            <br />
            <table class="tg">
              <colgroup>
                <col style="width: 15%" />
                <col style="width: 15%" />
                <col style="width: 15%" />
                <col style="width: 15%" />
                <col style="width: 15%" />
                <col style="width: 7%" />
              </colgroup>
              <thead>
                <tr>
                  <th
                    class="font bold fontBig whiteFont tableBackgroundMain"
                    colspan="5"
                  >
                    Department report finance
                  </th>
                </tr>
                <tr>
                  <td class="font bold fontMedium alignCenter" colspan="5">
                    Turnover per sender code
                  </td>
                </tr>
                <tr>
                  <td
                    class="
                      tableBackgroundMain
                      font
                      whiteFont
                      bold
                      alignCenter
                      fontSmall
                    "
                  >
                    Parcel
                  </td>
                  <td class="accent whiteFont bold font fontSmall alignCenter">
                    1
                  </td>
                  <td class="accent whiteFont bold font fontSmall alignCenter">
                    2
                  </td>
                  <td class="accent whiteFont bold font fontSmall alignCenter">
                    3
                  </td>
                  <td class="accent whiteFont bold font fontSmall alignCenter">
                    4
                  </td>
                  <td
                    class="undoButton"
                    onclick="restorePreviousTableState('HD_FINANCE', 0)"
                  >
                    <v-icon>mdi-undo-variant</v-icon>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr id="hdFinInputBox">
                  <td
                    class="tableBackgroundMain whiteFont font bold alignCenter"
                  >
                    1
                  </td>
                  <td class="inputBox"><input type="number" /></td>
                  <td class="inputBox"><input type="number" /></td>
                  <td class="inputBox"><input type="number" /></td>
                  <td class="inputBox"><input type="number" /></td>
                  <td
                    class="inputBox font bold alignCenter"
                    onclick="acceptInputHDFinance(event, 'totalSumHDFinance', 'hdFinInputBox')"
                  >
                    <v-icon>mdi-send</v-icon>
                  </td>
                </tr>
                <tr>
                  <td class="font bold fontSmall alignRight" colspan="2">
                    Overall turnover
                  </td>
                  <td class="inputBox" colspan="3" id="totalSumHDFinance">0</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="bar"></div>
          <h3>CUSTOMER</h3>
          <div id="CUSTOMER">
            <div class="de">
              <p class="tableInstruction">
                Versand- (Dispatch time) und Empfangszeit (Incoming time) in die
                Tabelle eintragen. Dafür in Tabellenzeilen klicken und richtige
                Zahl eintippen. Anschließend Lieferzeit (Lead time) der Pakete
                berechnen:
                <br />
                <br />Tipp: Empfangszeit - Versandzeit = Lieferzeit
                <br />
                <br />Paket auf drei Arten von Fehlern überprüfen; Zeitfehler,
                Ortsfehler und Sternfehler. Tabellenzeile per Klick
                markieren, falls Fehler vorliegt. <br /><br />
                <i class="fa fa-clock-o" aria-hidden="true"></i> Zeitfehler:
                <br />
                Lieferzeit von über 7 Minuten.
                <br /><br />
                <i class="fa fa-map-signs" aria-hidden="true"></i> Ortfehler:
                <br />
                Lieferung zum falschen Kundenstandort.
                <br /><br />
                <i class="fa fa-star-half-o" aria-hidden="true"></i>
                Sternfehler: <br />
                Falscher (Form + Farbe) oder fehlender Sternstempel.
                <br />
              </p>
            </div>
            <div class="en">
              <p class="tableInstruction">
                Enter the Dispatch and Incoming times. Calculate the Lead
                time:
                <br />
                <br />Hint: Incoming time - Dispatch time = Lead time
                <br />
                <br />At last check the parcel for three types of errors; time
                error, location error and star error. Mark the correct table
                slots by clicking on them. <br /><br />
                <i class="fa fa-clock-o" aria-hidden="true"></i> Time error:
                <br />
                Lead time over 7 minutes.
                <br /><br />
                <i class="fa fa-map-signs" aria-hidden="true"></i> Location
                error: <br />
                Parcel sent to the wrong customer site.
                <br /><br />
                <i class="fa fa-star-half-o" aria-hidden="true"></i>Star error:
                <br />
                Wrong (form and color) or missing star stamp.
                <br />
              </p>
            </div>

            <table class="tg">
              <col style="width: 7%" />
              <col style="width: 21%" />
              <col style="width: 21%" />
              <col style="width: 21%" />
              <col style="width: 7%" />
              <col style="width: 7%" />
              <col style="width: 7%" />
              <col style="width: 7%" />
              <thead>
                <tr>
                  <th
                    class="tableBackgroundMain whiteFont font fontMedium bold"
                    colspan="4"
                  >
                    ROUND ANALYSIS
                  </th>
                  <th class="accent whiteFont font fontMedium bold" colspan="3">
                    MARK ERRORS
                  </th>
                </tr>
                <tr>
                  <td
                    class="tableBackgroundMain whiteFont font bold alignCenter"
                  >
                    No.
                  </td>
                  <td class="greyBox font bold alignCenter">
                    Dispatch<br />time
                  </td>
                  <td class="greyBox font bold alignCenter">
                    Incoming<br />time
                  </td>
                  <td class="greyBox font bold alignCenter">Lead time</td>
                  <td class="greyBox font bold alignCenter">
                    <i
                      class="fa fa-clock-o"
                      aria-hidden="true"
                      style="font-size: x-large"
                    ></i>
                  </td>
                  <td class="greyBox font bold alignCenter">
                    <i
                      class="fa fa-map-marker"
                      aria-hidden="true"
                      style="font-size: x-large"
                    ></i>
                  </td>
                  <td class="greyBox font bold alignCenter">
                    <i
                      class="fa fa-star-half-o"
                      aria-hidden="true"
                      style="font-size: x-large"
                    ></i>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    class="tableBackgroundMain whiteFont font bold alignCenter"
                  >
                    1
                  </td>
                  <td class="inputBox"><input type="number" /></td>
                  <td class="inputBox"><input type="number" /></td>
                  <td class="inputBox"><input type="number" /></td>
                  <td
                    class="inputBox markDisabled"
                    onclick="markSelected(event)"
                  ></td>
                  <td
                    class="inputBox markDisabled"
                    onclick="markSelected(event)"
                  ></td>
                  <td
                    class="inputBox markDisabled"
                    onclick="markSelected(event)"
                  ></td>
                  <td
                    class="inputBox markDisabled"
                    onclick="acceptInputCustomerTable(event)"
                  >
                    <v-icon>mdi-send</v-icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="bar"></div>
          <h3>Improvement BILLING</h3>
          <div id="ACT_FIN">
            <h3>OPTION 1:</h3>
            <div>
              <div class="de">
                <p class="tableInstruction">
                  Gesamttarif (das obere rechte Feld auf dem Paket!) sowie
                  Senderstandort für die einzelnen Pakete in Tabelle eintragen.
                  KHhierfür in die Tabellenzeilen klicken und korrekte Summe
                  eintippen. Um Tabellenzeile hinzuzufügen auf das
                  <v-icon>mdi-send</v-icon> Icon klicken! <br />Tipp:
                  Gesamtumsatz (overall turnover) wird automatisch kalkuliert!
                </p>
              </div>
              <div class="en">
                <p class="tableInstruction">
                  Type in the overall tarif (top right slot on the parcel!) as
                  well as the sender location for each parcel. Simply click on
                  the slots of the table and type in the correct number.<br />
                  Hint: The overall turnover is calculated automatically!
                </p>
              </div>

              <table class="tg" style="width: 90%">
                <colgroup>
                  <col style="width: 21%" />
                  <col style="width: 21%" />
                  <col style="width: 21%" />
                  <col style="width: 7%" />
                </colgroup>
                <thead>
                  <tr>
                    <th class="accent font whiteFont bold alignCenter">
                      Parcel
                    </th>
                    <th class="accent font whiteFont bold alignCenter">
                      Price in €
                    </th>
                    <th class="accent font whiteFont bold alignCenter">
                      Sender
                    </th>
                    <th
                      class="undoButton"
                      onclick="restorePreviousTableState('ACT_FIN', 0)"
                    >
                      <v-icon>mdi-undo-variant</v-icon>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="greyBox font whiteFont alignCenter">
                      <input type="number" value="1" disabled />
                    </td>
                    <td class="inputBox font bold alignCenter">
                      <input type="number" />
                    </td>
                    <td class="inputBox font bold alignCenter">
                      <input class="tableTextInput" />
                    </td>
                    <td
                      class="inputBox font bold alignCenter"
                      onclick="acceptInputFinTable0(event, 3, ['actFin_sumTable0'], [1])"
                    >
                      <v-icon>mdi-send</v-icon>
                    </td>
                  </tr>
                  <tr>
                    <td class="tableBackgroundMain font whiteFont alignCenter">
                      Total:
                    </td>
                    <td
                      class="inputBox font bold alignCenter"
                      id="actFin_sumTable0"
                    >
                      0
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <br />
            <h3>OPTION 2:</h3>
            <div>
              <div class="de">
                <p class="tableInstruction">
                  Strecken- und Gewichtstarife (Oben Links und Oben Mitte auf
                  dem Paket) in Tabelle eintragen, durch klicken auf die
                  richtigen Spalten. Um Tabellenzeile hinzuzufügen auf
                  <v-icon>mdi-send</v-icon> Icon klicken! <br />Tipp:
                  Gesamtumsatz (overall turnover) wird automatisch kalkuliert!
                </p>
              </div>
              <div class="en">
                <p class="tableInstruction">
                  Type in the correct route and weight tarif into the table by
                  clicking on the table slots. To add a new table row simply
                  click on the paperplane icon!<br />
                  Hint: The overall turnover is calculated automatically!
                </p>
              </div>

              <table class="tg" style="width: 90%">
                <colgroup>
                  <col style="width: 18%" />
                  <col style="width: 18%" />
                  <col style="width: 18%" />
                  <col style="width: 18%" />
                  <col style="width: 18%" />
                  <col style="width: 5%" />
                </colgroup>
                <thead>
                  <tr>
                    <th class="white"></th>
                    <th
                      class="accent font whiteFont bold alignCenter"
                      colspan="2"
                    >
                      Distance
                    </th>
                    <th
                      class="accent font whiteFont bold alignCenter"
                      colspan="2"
                    >
                      Weight
                    </th>
                    <th
                      class="undoButton"
                      onclick="restorePreviousTableState('HD_FINANCE', 1)"
                    >
                      <v-icon>mdi-undo-variant</v-icon>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="white"></td>
                    <td
                      class="
                        tableBackgroundMain
                        font
                        whiteFont
                        bold
                        alignCenter
                      "
                    >
                      Long
                    </td>
                    <td
                      class="
                        tableBackgroundMain
                        font
                        whiteFont
                        bold
                        alignCenter
                      "
                    >
                      Short
                    </td>
                    <td
                      class="
                        tableBackgroundMain
                        font
                        whiteFont
                        bold
                        alignCenter
                      "
                    >
                      Heavy
                    </td>
                    <td
                      class="
                        tableBackgroundMain
                        font
                        whiteFont
                        bold
                        alignCenter
                      "
                    >
                      Light
                    </td>
                  </tr>
                  <tr>
                    <td class="accent font whiteFont bold alignCenter">
                      Parcel
                    </td>
                    <td class="inputBox font bold alignCenter">25€</td>
                    <td class="inputBox font bold alignCenter">20€</td>
                    <td class="inputBox font bold alignCenter">25€</td>
                    <td class="inputBox font bold alignCenter">20€</td>
                  </tr>
                  <tr>
                    <td class="inputBox font bold alignCenter">1</td>
                    <td
                      class="inputBox markDisabled"
                      onclick="markSelected(event)"
                    ></td>
                    <td
                      class="inputBox markDisabled"
                      onclick="markSelected(event)"
                    ></td>
                    <td
                      class="inputBox markDisabled"
                      onclick="markSelected(event)"
                    ></td>
                    <td
                      class="inputBox markDisabled"
                      onclick="markSelected(event)"
                    ></td>
                    <td
                      class="inputBox font bold alignCenter"
                      onclick="acceptInputFinTable1(event, 'actFin_subSum3', 'actFin_sumTable1')"
                    >
                      <v-icon>mdi-send</v-icon>
                    </td>
                  </tr>
                  <tr id="actFin_subSum3">
                    <td
                      class="
                        tableBackgroundMain
                        font
                        whiteFont
                        bold
                        alignCenter
                      "
                    >
                      &#x2211;
                    </td>
                    <td class="inputBox font bold alignCenter">
                      <span>0</span><span> x </span><span>25</span>
                    </td>
                    <td class="inputBox font bold alignCenter">
                      <span>0</span><span> x </span><span>20</span>
                    </td>
                    <td class="inputBox font bold alignCenter">
                      <span>0</span><span> x </span><span>30</span>
                    </td>
                    <td class="inputBox font bold alignCenter">
                      <span>0</span><span> x </span><span>25</span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="
                        tableBackgroundMain
                        font
                        whiteFont
                        bold
                        alignCenter
                      "
                    ></td>
                    <td
                      class="inputBox font bold alignCenter"
                      id="actFin_sumTable1"
                      colspan="4"
                    >
                      0
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <br />
            <h3>OPTION 3:</h3>
            <div>
              <div class="de">
                <p class="tableInstruction">
                  Für jedes Paket entsprechende Kombination in Tabelle
                  eintragen. Unterscheide zwischen Kurz- (20€) und Langstrecke
                  (25€), sowie schwer (25€) und leicht (20€). Feld mit
                  Schnittpunkt wählen um jeweilige Menge zu erhöhen.
                </p>
              </div>
              <div class="en">
                <p class="tableInstruction">
                  For each package, enter the corresponding combination in the
                  table. You must distinguish between Short Distance (20€) and
                  Long Distance (25€), as well as heavy (25€) and light (20€).
                  Select the field with the intersection to increase its amount.
                </p>
              </div>

              <table class="tg" style="width: 90%">
                <colgroup>
                  <col style="width: 15%" />
                  <col style="width: 25%" />
                  <col style="width: 25%" />
                  <col style="width: 25%" />
                </colgroup>
                <thead>
                  <tr>
                    <th class="white"></th>
                    <th class="accent font whiteFont bold alignCenter">
                      Heavy (25€)
                    </th>
                    <th class="accent font whiteFont bold alignCenter">
                      Light (20€)
                    </th>
                    <th
                      class="
                        tableBackgroundMain
                        font
                        whiteFont
                        bold
                        alignCenter
                      "
                    >
                      Total
                    </th>
                    <th
                      class="undoButton"
                      onclick="restorePreviousTableState('HD_FINANCE', 2)"
                    >
                      <v-icon>mdi-undo-variant</v-icon>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="accent font whiteFont bold alignCenter">
                      Short Distance (20€)
                    </td>
                    <td
                      class="inputBox font bold alignCenter"
                      onclick="addOneAdvanced(event, true, 45, 'actFin_subSum1', 'actFin_sumTable2')"
                    >
                      0
                    </td>
                    <td
                      class="inputBox font bold alignCenter"
                      onclick="addOneAdvanced(event, true, 40, 'actFin_subSum2', 'actFin_sumTable2')"
                    >
                      0
                    </td>
                    <td class="inputBox font bold alignCenter">0</td>
                  </tr>
                  <tr>
                    <td class="accent font whiteFont bold alignCenter">
                      Long Distance (25€)
                    </td>
                    <td
                      class="inputBox font bold alignCenter"
                      onclick="addOneAdvanced(event, true, 50, 'actFin_subSum1', 'actFin_sumTable2')"
                    >
                      0
                    </td>
                    <td
                      class="inputBox font bold alignCenter"
                      onclick="addOneAdvanced(event, true, 45, 'actFin_subSum2', 'actFin_sumTable2')"
                    >
                      0
                    </td>
                    <td class="inputBox font bold alignCenter">0</td>
                  </tr>
                  <tr>
                    <td
                      class="
                        tableBackgroundMain
                        font
                        whiteFont
                        bold
                        alignCenter
                      "
                    >
                      Total
                    </td>
                    <td
                      class="inputBox font bold alignCenter"
                      id="actFin_subSum1"
                    >
                      0
                    </td>
                    <td
                      class="inputBox font bold alignCenter"
                      id="actFin_subSum2"
                    >
                      0
                    </td>
                    <td
                      class="
                        tableBackgroundMain
                        font
                        whiteFont
                        bold
                        alignCenter
                      "
                      id="actFin_sumTable2"
                    >
                      0
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div id="bar"></div>
        </div>
      </v-tab-item>

      <v-tab-item>
        <div id="additional" class="tabcontent">
          <div id="ADD_COURIER">
            <div class="de">
              <p class="tableInstruction">
                Pakete an den 4 Customer Sites abholen und zum Messengertisch
                bringen. Des Weiteren Pakete bei der Outbox des Head of Outgoing
                Goods abholen und anhand Empfängercodes entsprechenden Customer
                Sites übergeben.
              </p>
            </div>
            <div class="en">
              <p class="tableInstruction">
                Pick up parcels at the 4 Customer Sites and bring them to the
                messenger desk. Furthermore, you have to pick up parcels at the
                outbox of the Head of Outgoing Goods and hand them over to the
                corresponding Customer Sites according to the receiver codes.
              </p>
            </div>
            <br />
            <img class="generatedImg" />
            <br />
            <div class="de">
              <p class="tableInstruction">
                Reihenfolge beachten, in welcher Pakete bei den Customer Sites
                abgeholt werden müssen!
              </p>
            </div>
            <div class="en">
              <p class="tableInstruction">
                Mind the order in which you have to retrieve the parcels from
                the customer sites!
              </p>
            </div>
            <div class="en">
              <table class="borderSpacing tg center">
                <colgroup>
                  <col style="width: 50%" />
                  <col style="width: 50%" />
                </colgroup>
                <thead>
                  <tr>
                    <th
                      class="
                        whiteFont
                        tableBackgroundMain
                        alignCenter
                        font
                        padding
                        border
                        bold
                        font
                      "
                      colspan="2"
                    >
                      SEQUENCE OF VISITS
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="inputBox border">NORTH</td>
                    <td class="inputBox border">1</td>
                  </tr>
                  <tr>
                    <td class="inputBox border">SOUTH</td>
                    <td class="inputBox border">2</td>
                  </tr>
                  <tr>
                    <td class="inputBox border">EAST</td>
                    <td class="inputBox border">3</td>
                  </tr>
                  <tr>
                    <td class="inputBox border">WEST</td>
                    <td class="inputBox border">4</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div id="bar"></div>
          </div>
          <div id="ADD_CUSTOMER">
            <div class="de">
              <p class="tableInstruction">
                Empfängername prüfen und entsprechende Adresse in der Tabelle
                suchen (z.B. North oder South).
              </p>
            </div>
            <div class="en">
              <p class="tableInstruction">
                Mind the order in which you have to deliver the parcels to the
                customer sites!
              </p>
            </div>
            <table class="tg">
              <thead>
                <tr>
                  <th
                    class="accent font whiteFont fontMedium bold"
                    colspan="16"
                  >
                    EMPLOYEE LIST PER LOCATION/STAR COLOR
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    class="tableBackgroundMain font whiteFont bold alignCenter"
                    colspan="4"
                  >
                    NORTH
                  </td>
                  <td
                    class="tableBackgroundMain font whiteFont bold alignCenter"
                    colspan="4"
                  >
                    EAST
                  </td>
                  <td
                    class="tableBackgroundMain font whiteFont bold alignCenter"
                    colspan="4"
                  >
                    SOUTH
                  </td>
                  <td
                    class="tableBackgroundMain font whiteFont bold alignCenter"
                    colspan="4"
                  >
                    WEST
                  </td>
                </tr>
                <tr>
                  <td class="inputBox font bold alignTop" colspan="4">
                    Almeida, U.<br />
                    Bach, F.<br />
                    Bastian, M.<br />
                    Cortec, T.<br />
                    Damerau, I.<br />
                    Ditler, B.<br />
                    Eppler, A.<br />
                    Erber, A.<br />
                    Fiehl, G.<br />
                    Habedank, S.<br />
                    Jackson, A.<br />
                    Jörg, P.<br />
                    Jost, W.<br />
                    Judele, W.<br />
                    Kabbeck, O.<br />
                    Kärcher, R.<br />
                    Klauder, B.<br />
                    Kleemann, J.<br />
                    Labach, L.<br />
                    Lamper, E.<br />
                    Maar, T,<br />
                    Nemec, J.<br />
                    Oster, A.<br />
                    Paatz, G.<br />
                    Paepke, D.<br />
                    Pala, E.<br />
                    Pfaffenbach, J.<br />
                    Pfeiffer, F.<br />
                    Pilz, S.<br />
                    Preissmann, M,<br />
                    Quanz, K.<br />
                    Quartinello, P.<br />
                    Queck, L.<br />
                    Queller, D.<br />
                    Raabe, K.<br />
                    Ragusa, L.<br />
                    Rapf, L,<br />
                    Reber, R.<br />
                    Rech, G.<br />
                    Reidenbach, F.<br />
                    Richter, U.<br />
                    Roden, W.<br />
                    Ross, G.<br />
                    Ruch, U.<br />
                    Runzheimer, O.<br />
                    Wagenknecht, O.
                  </td>
                  <td class="inputBox font bold alignTop" colspan="4">
                    Ahmad, T.<br />
                    Alpert, L.<br />
                    Baumann, U,<br />
                    Charwat, O.<br />
                    Erbsner, T.<br />
                    Esterman, G.<br />
                    Ferres, A.<br />
                    Haase, E.<br />
                    Ikkert, K.<br />
                    Klag, S.<br />
                    Klaus, A.<br />
                    Klein, E.<br />
                    Losang, D.<br />
                    Mahler, G.<br />
                    Neckermann, K.<br />
                    Opp, S.<br />
                    Pachen, J.<br />
                    Pinno, N.<br />
                    Quandt, G.<br />
                    Ribic, K.<br />
                    Riffel, O.<br />
                    Roder, W.<br />
                    Rostron, S.<br />
                    Rothausen, F,<br />
                    Rumrich, I,<br />
                    Scharfenberger, D.<br />
                    Tamaris, L.<br />
                    Umminger K.<br />
                    Zaifer,R.<br />
                  </td>
                  <td class="inputBox font bold alignTop" colspan="4">
                    Ahrens, R.<br />
                    Allbrecht, U.<br />
                    Bachmann, R.<br />
                    Backes, E.<br />
                    Bäder, A.<br />
                    Baum, C.<br />
                    Cardoso, H.<br />
                    Cronenberg, L.<br />
                    Cyron, W.<br />
                    Denk, N.<br />
                    Ebert, M.<br />
                    Erbach, H.<br />
                    Eschenröder, J.<br />
                    Essig, O.<br />
                    Felder, J.<br />
                    Hadran, L.<br />
                    Herrod, D.<br />
                    Merkle, P.<br />
                    Mündel, H.<br />
                    Offermann, T.<br />
                    Okur, S.<br />
                    Olson, D.<br />
                    Pabst, I.<br />
                    Pahl, L.<br />
                    Pispers, P.<br />
                    Recknagel, D.<br />
                    Roter, K.<br />
                    Riedel, J.<br />
                    Rothkirch, E.<br />
                    Rotolo, A.<br />
                    Rugen, A.<br />
                    Tannier, T.<br />
                    Wagenmann, J.<br />
                    Wehe, J.<br />
                    Weiche, B.<br />
                    Xenos, C.<br />
                    Yashiro, T.<br />
                    Yarosh, D.<br />
                  </td>
                  <td class="inputBox font bold alignTop" colspan="4">
                    Alpert, T.<br />
                    Akrami, M.<br />
                    Baab, A.<br />
                    Bastubbe, L.<br />
                    Degenstein, K.<br />
                    Dehmer, S.<br />
                    Ebertz, U.<br />
                    Farrell, J.<br />
                    Fettes,A.<br />
                    Hencken, U.<br />
                    Herzog, F.<br />
                    Hutka, T.<br />
                    Ihme, K.<br />
                    Imig, G.<br />
                    Ingebrand, F.<br />
                    Jensch, U.<br />
                    Jodelett, T.<br />
                    Müller, H.<br />
                    Münch, K.<br />
                    Nebrich, H.<br />
                    Nees,L.<br />
                    Nepil, M.<br />
                    Orantek, S.<br />
                    Pflüger, K.<br />
                    Prazak, M.<br />
                    Quadt, L.<br />
                    Quarg, B.<br />
                    Reble, K.<br />
                    Rotemann, R.<br />
                    Reichert, G.<br />
                    Riehle, H.<br />
                    Rost, W.<br />
                    Rott, U.<br />
                    Rowold, H.<br />
                    Ruf, S.<br />
                    Sander, R.<br />
                    Tracht, M.<br />
                    Trübenbach, M.<br />
                    Weber, K.<br />
                    Xue, X.<br />
                    Yaffe, S.<br />
                  </td>
                </tr>
                <br />

                <tr>
                  <td class="tg-2egc" colspan="16"></td>
                </tr>
                <div class="de">
                  <p class="tableInstruction">
                    Anschließend anhand der 2. Tabelle prüfen, ob gültiger
                    Stempel für die Empfängeradresse verwendet wurde.
                  </p>
                </div>
                <div class="en">
                  <p class="tableInstruction">
                    After that use the 2nd table to check whether a valid stamp
                    was used for the receiver address
                  </p>
                </div>
                <tr>
                  <td
                    class="tableBackgroundMain font whiteFont bold alignCenter"
                    colspan="4"
                  >
                    Star Color NORTH
                  </td>
                  <td
                    class="tableBackgroundMain font whiteFont bold alignCenter"
                    colspan="4"
                  >
                    Star Color EAST
                  </td>
                  <td
                    class="tableBackgroundMain font whiteFont bold alignCenter"
                    colspan="4"
                  >
                    Star Color SOUTH
                  </td>
                  <td
                    class="tableBackgroundMain font whiteFont bold alignCenter"
                    colspan="4"
                  >
                    Star Color WEST
                  </td>
                </tr>
                <tr>
                  <td class="inputBox font" colspan="4">Accepted Shape:</td>
                  <td class="inputBox font" colspan="4">Accepted Shape:</td>
                  <td class="inputBox font" colspan="4">Accepted Shape:</td>
                  <td class="inputBox font" colspan="4">Accepted Shape:</td>
                </tr>
                <tr>
                  <td class="inputBox font" colspan="4">
                    <i
                      class="fa fa-star"
                      style="color: #0d0d0d"
                      aria-hidden="true"
                    ></i>
                    <i
                      class="fa fa-star"
                      style="color: #5de63e"
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td class="inputBox font" colspan="4">
                    <i
                      class="fa fa-star"
                      style="color: #5de63e"
                      aria-hidden="true"
                    ></i>
                    <i
                      class="fa fa-star"
                      style="color: #53aaed"
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td class="inputBox font" colspan="4">
                    <i
                      class="fa fa-star"
                      style="color: #fc1703"
                      aria-hidden="true"
                    ></i>
                    <i
                      class="fa fa-star"
                      style="color: #5de63e"
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td class="inputBox font" colspan="4">
                    <i
                      class="fa fa-star"
                      style="color: #53aaed"
                      aria-hidden="true"
                    ></i>
                    <i
                      class="fa fa-star"
                      style="color: #fc1703"
                      aria-hidden="true"
                    ></i>
                    <i
                      class="fa fa-star"
                      style="color: #5de63e"
                      aria-hidden="true"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div id="ADD_ACT_ROUTE" style="display: none">
            <div>
              <div class="searchBarParent">
                <input
                  type="text"
                  placeholder="Search ..."
                  oninput="searchNameAdv(event, 'act_route_table_fill')"
                />
                <i class="bi bi-search"></i>
              </div>
              <br />
              .... Namensliste / Namelist
            </div>
          </div>
          <div id="ADD_MESSENGER">
            <!-- <img src="./workInstructions/img/MESSENGER/img0.png" class="generatedImg"> -->
            <div class="de">
              <p class="tableInstruction">
                In der folgenden Tabelle siehst du die Reihenfolge, in der du
                die Pakete durchs Büro transportieren musst. Prüfe regelmäßig,
                wo sich Pakete in der Outbox befinden und bringe sie zum
                nächsten Schreibtisch auf der Liste
              </p>
            </div>
            <div class="en">
              <p class="tableInstruction">
                In the table below you can see the order in which you need to
                move the parcels around the office. Check where parcels are in
                the outbox and move them to the next desk on the list.
              </p>
            </div>
            <div class="en">
              <table class="borderSpacing tg center">
                <colgroup>
                  <col style="width: 13%" />
                  <col style="width: 30%" />
                  <col style="width: 13%" />
                  <col style="width: 30%" />
                  <col style="width: 13%" />
                </colgroup>
                <thead>
                  <tr>
                    <th
                      class="
                        whiteFont
                        tableBackgroundMain
                        alignCenter
                        font
                        padding
                        border
                        bold
                        font
                      "
                      colspan="1"
                    >
                      Correct Workstation Order
                    </th>
                    <th
                      class="
                        whiteFont
                        tableBackgroundMain
                        alignCenter
                        font
                        padding
                        border
                        bold
                        font
                      "
                      colspan="1"
                    >
                      FROM
                    </th>
                    <th
                      class="
                        whiteFont
                        tableBackgroundMain
                        alignCenter
                        font
                        padding
                        border
                        bold
                        font
                      "
                      colspan="1"
                    ></th>
                    <th
                      class="
                        whiteFont
                        tableBackgroundMain
                        alignCenter
                        font
                        padding
                        border
                        bold
                        font
                      "
                      colspan="1"
                    >
                      TO
                    </th>
                    <th
                      class="
                        whiteFont
                        tableBackgroundMain
                        alignCenter
                        font
                        padding
                        border
                        bold
                        font
                      "
                      colspan="1"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="greyBox border font">1</td>
                    <td class="inputBox border">Your Inbox</td>
                    <td class="greyBox border font"></td>
                    <td class="inputBox border">Clerk Incoming Goods</td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/CL_INCOMING_GOODS/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="greyBox border font">2</td>
                    <td class="inputBox border">Clerk Incoming Goods</td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/CL_INCOMING_GOODS/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                    <td class="inputBox border">Head of Incoming Goods</td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/HD_INCOMING_GOODS/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="greyBox border font">3</td>
                    <td class="inputBox border">Head of Incoming Goods</td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/HD_INCOMING_GOODS/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                    <td class="inputBox border">Clerk Route Tariff</td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/CL_ROUTE_TARIF/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="greyBox border font">4</td>
                    <td class="inputBox border">Clerk Route Tariff</td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/CL_ROUTE_TARIF/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                    <td class="inputBox border">Clerk Weight Tariff</td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/CL_WEIGHT_TARIF/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="greyBox border font">5</td>
                    <td class="inputBox border">Clerk Weight Tariff</td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/CL_WEIGHT_TARIF/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                    <td class="inputBox border">Clerk Accounting</td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/CL_ACCOUNTING/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="greyBox border font">6</td>
                    <td class="inputBox border">Clerk Accounting</td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/CL_ACCOUNTING/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                    <td class="inputBox border">
                      Head of Finance and Accounting
                    </td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/HD_FINANCE/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="greyBox border font">7</td>
                    <td class="inputBox border">
                      Head of Finance and Accounting
                    </td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/HD_FINANCE/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                    <td class="inputBox border">Clerk Outgoing Goods</td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/CL_OUTGOING_GOODS/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="greyBox border font">8</td>
                    <td class="inputBox border">Clerk Outgoing Goods</td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/CL_OUTGOING_GOODS/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                    <td class="inputBox border">Head of Outgoing Goods</td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/HD_OUTGOING_GOODS/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="de">
              <table class="borderSpacing tg center">
                <colgroup>
                  <col style="width: 13%" />
                  <col style="width: 30%" />
                  <col style="width: 13%" />
                  <col style="width: 30%" />
                  <col style="width: 13%" />
                </colgroup>
                <thead>
                  <tr>
                    <th
                      class="
                        whiteFont
                        tableBackgroundMain
                        alignCenter
                        font
                        padding
                        border
                        bold
                        font
                      "
                      colspan="1"
                    >
                      Arbeitsschritt Reihenfolge
                    </th>
                    <th
                      class="
                        whiteFont
                        tableBackgroundMain
                        alignCenter
                        font
                        padding
                        border
                        bold
                        font
                      "
                      colspan="1"
                    >
                      VON
                    </th>
                    <th
                      class="
                        whiteFont
                        tableBackgroundMain
                        alignCenter
                        font
                        padding
                        border
                        bold
                        font
                      "
                      colspan="1"
                    ></th>
                    <th
                      class="
                        whiteFont
                        tableBackgroundMain
                        alignCenter
                        font
                        padding
                        border
                        bold
                        font
                      "
                      colspan="1"
                    >
                      NACH
                    </th>
                    <th
                      class="
                        whiteFont
                        tableBackgroundMain
                        alignCenter
                        font
                        padding
                        border
                        bold
                        font
                      "
                      colspan="1"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="greyBox border font">1</td>
                    <td class="inputBox border">Dein Posteingang</td>
                    <td class="greyBox border font"></td>
                    <td class="inputBox border">Sachb. Wareneingang</td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/CL_INCOMING_GOODS/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="greyBox border font">2</td>
                    <td class="inputBox border">Sachb. Wareneingang</td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/CL_INCOMING_GOODS/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                    <td class="inputBox border">Leit. Wareneingang</td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/HD_INCOMING_GOODS/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="greyBox border font">3</td>
                    <td class="inputBox border">Leit. Wareneingang</td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/HD_INCOMING_GOODS/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                    <td class="inputBox border">Sachb. Routentarif</td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/CL_ROUTE_TARIF/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="greyBox border font">4</td>
                    <td class="inputBox border">Sachb. Routentarif</td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/CL_ROUTE_TARIF/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                    <td class="inputBox border">Sachb. Gewichtstarif</td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/CL_WEIGHT_TARIF/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="greyBox border font">5</td>
                    <td class="inputBox border">Sachb. Gewichtstarif</td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/CL_WEIGHT_TARIF/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                    <td class="inputBox border">Sachb. Buchhaltung</td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/CL_ACCOUNTING/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="greyBox border font">6</td>
                    <td class="inputBox border">Sachb. Buchhaltung</td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/CL_ACCOUNTING/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                    <td class="inputBox border">Leit. Finanzen</td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/HD_FINANCE/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="greyBox border font">7</td>
                    <td class="inputBox border">Leit. Finanzen</td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/HD_FINANCE/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                    <td class="inputBox border">Sachb. Warenausgang</td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/CL_OUTGOING_GOODS/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="greyBox border font">8</td>
                    <td class="inputBox border">Sachb. Warenausgang</td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/CL_OUTGOING_GOODS/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                    <td class="inputBox border">Leit. Warenausgang</td>
                    <td class="greyBox border font">
                      <img
                        src="./workInstructions/img/HD_OUTGOING_GOODS/mainImg.png"
                        class="generatedImg"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div id="bar"></div>
          </div>

          <div id="ADD_CL_INCOMING_GOODS">
            Namensliste / Namelist
            <div id="bar"></div>
          </div>
        </div>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
export default {
  name: "workInstructions",

  data() {
    return {
      showGerman: localStorage.getItem("lang") == "de" ? true : false,
      showCLINCNameList: false,
    };
  },

  methods: {
    backToFaq() {
      this.$router.push("/overview/faq");
    },
  },

  mounted() {},
};
</script>
