<template>
    <div id="mainfaqZone">
        <router-view> </router-view>
    </div>
</template>

<script>
    export default {
    name: "mainfaqZone"
    }
</script>