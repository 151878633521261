import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    accessToken: localStorage.getItem('access_token') || null, // makes sure the user is logged in even after refreshing the page
    refreshToken: localStorage.getItem('refresh_token') || null,
    name: localStorage.getItem('name') || null
  },
  mutations: {
    updateLocalStorage(state, { access, refresh, name}) {
      
      localStorage.setItem('access_token', access)
      localStorage.setItem('refresh_token', refresh)
      localStorage.setItem('name', name)

      state.accessToken = access
      state.refreshToken = refresh
      state.name = name
    },
    updateAccess(state, access) {
      state.accessToken = access
      
    },
    destroyToken(state) {
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('name')

      state.accessToken = null
      state.refreshToken = null
      state.name = null
    }
  },
  actions: {
    // run the below action to get a new access token on expiration
    refreshToken(context) {
      return new Promise((resolve, reject) => {
        axios.post(axios.defaults.baseURL + 'token/refresh/', {
          refresh: this.state.refreshToken
        }) // send the stored refresh token to the backend API
          .then(response => { // if API sends back new access and refresh token update the store
            context.commit('updateAccess', response.data.access)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access;
            resolve(response.data.refresh)
          })
          .catch(err => {
            context.commit('destroyToken')

            router.push('/')
            reject(err) // error generating new access and refresh token because refresh token has expired
          })
      })
    },
    logoutUser(context) {
        return new Promise((resolve, reject) => {

          context.commit('destroyToken')

          localStorage.setItem("data", "")
          localStorage.setItem("name", "")

          router.replace('/')
          resolve
        })
      
    },
    loginUser(context, credentials) {
      return new Promise((resolve, reject) => {
        // send the username and password to the backend API:
        axios.post(axios.defaults.baseURL + 'token/', 
        {
          username: credentials.email,
          password: credentials.password
        }
        
          )
          // if successful update local storage:
          .then(response => {
            context.commit('updateLocalStorage', { access: response.data.access, refresh: response.data.refresh }) // store the access and refresh token in localstorage
            axios.defaults.headers.common['Authorization'] =  'Bearer ' + response.data.access;
            localStorage.setItem("mail", credentials.email)
            resolve(response)

            router.push('/overview')
          })
          .catch(err => {
            reject(err)
          })
      })
    }
  }
})