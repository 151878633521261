export default {
    message: {
        newCourse: "Neuer Kurs",
        ownCourses: "Eigene Kurse",
        otherCourses: "Kurse anderer Trainer",
        course: "KURSE",
        startPlay: "SPIEL STARTEN",
        trainer: "TRAINER",
        faqZone: "FAQ BEREICH",
        start: "Start",
        end: "Ende",
        language: "Sprache",
        addCourse: "Neuen Kurs hinzufügen",
        title: "Titel",
        startDate: "Startdatum",
        endDate: "Enddatum",
        add: "Hinzufügen",
        courseDescription: "Kursbeschreibung",
        overview: "Overview",
        reviews : "BEWERTUNGEN",
        statistics: "STATISTIKEN",
        round: "RUNDEN",
        average: "Durchschnitt",
        gameLiked: "Handhabung",
        Fun: "Spielerfahrung",
        from: "Von",
        noRounds: "Keine Runden vorhanden !",
        created: "Erstellt",
        time: "Time",
        createGame: "Spiel anlegen",
        chooseCourse: "Kurs wählen",
        startGame: "SPIEL STARTEN",
        abort:"ABBRECHEN",
        copyLinkForPlayer: "Link für Spieler kopieren",
        copyLinkForTrainer: "Link für Trainer kopieren",
        enterGame: "Spiel beitreten",
        back: "Zurück",
        downloadFirstMap: " Basis-Karte herunterladen ",
        downloadSecondMap: " Adv-Karte 1 herunterladen ",
        downloadThirdMap: " Adv-Karte 2 herunterladen ",
        parcels: " Pakete ",
        technicalSupport: "TECHNISCHER SUPPORT ",
        questionsArea: "FRAGENBEREICH",
        addFeedback: "Ticket",
        send: "SENDEN",
        colors: "Farben",
        editCourse: "Kurs editieren",
        change: "Ändern",
        expiredCourses: "Abgelaufene Kurse",
        allExpiredCourses: "Alle Abgelaufene Kurse",
        allcurrentCourses: "Alle Aktuelle Kurse",
        currentCourses: "Aktuelle Kurse",
        addTrainer: "Neuen Trainer hinzufügen",
        trainerName: "Trainer Name",
        trainerFirstName: "Vorname",
        trainerLatName: "Nachname",
        password: "Passwort",
        trainersList:"Liste der Trainer",
        recentKey:"Aktuellster Game Key",
        error:"Oops, da ist etwas schief gelaufen!",
        promoteTrainerToHead: "Wollen Sie wirklich den Trainer zu Head Trainer Promoten ?",
        deleteTrainerFromCourseMessage: "Wollen Sie wirklich der Trainer von dem kurz entfernen ?",
        agree: "Ja",
        notAgree: "Nein", 
        ownExpiredCourses :"Eigene abgelaufene Kurse",
        otherExpiredCourses :"Andere abgelaufene Kurse",
        ownCurrentCourses: "Eigene aktuelle Kurse",
        roles: "ROLLEN",
        statistic_roundCreated_date: "Erstellt",
        statistic_roundCreated_time: "Uhrzeit",
        preFill: "Vorfüllen: (Hauptspiel)"
    }
}