<template>
  <div id="technicalSupport" style="height: 70vh">
    <v-container fluid>
      <v-row>
        <v-spacer />
        <v-col cols="auto">
          <v-btn @click="backToFaq()" class="white--text mr-">{{
            $t("message.back")
          }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid fill-height>
      <v-row justify="center" align="center">
        <v-col :cols="cols">
          <v-card>
            <v-form @submit.prevent="addFeedback" ref="feedbackForm">
              <v-container class="pa-6 py-15" fill-height>
                <v-row justify="center" align="center">
                  <v-col cols="12">
                    <h4>{{ $t("message.addFeedback") }}</h4>
                  </v-col>
                </v-row>

                <v-row justify="center" align="center">
                  <v-col cols="12">
                    <v-textarea
                      filled
                      auto-grow
                      v-model="feedback"
                      required
                      :rules="feedbackRules"
                      counter
                    ></v-textarea>
                  </v-col>
                </v-row>

                <v-row justify="center" align="center">
                  <v-col
                    cols="auto"
                    v-for="tag in tags"
                    :key="tag.id"
                    class="pa-1"
                  >
                    <v-chip
                      @click="switchTag(tag.id)"
                      class="chipStyle"
                      :style="{
                        'border-color': tag.enabled ? tag.color : 'gray',
                        'background-color': tag.enabled
                          ? tag.secondaryColor
                          : 'lightgray',
                      }"
                      >{{ tag.name }}</v-chip
                    >
                  </v-col>
                </v-row>

                <v-row justify="center" align="center">
                  <v-col cols="12">
                    <v-file-input
                      chips
                      accept="image/*"
                      multiple
                      label="Optional*"
                      v-model="fileUploads"
                    ></v-file-input>
                  </v-col>
                </v-row>

                <v-row justify="center" align="center">
                  <v-col cols="5">
                    <v-btn
                      @click="addFeedback()"
                      large
                      block
                      color="primary"
                      elevation="2"
                    >
                      {{ $t("message.send") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <div class="overlappingDarkBG" v-if="filesUntilUploadSuccessful > 0">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
var moment = require("moment");

export default {
  name: "technicalSupport",

  data() {
    return {
      feedback: "",
      feedbackRules: [
        (v) =>
          !!v ||
          (localStorage.getItem("lang") == "de"
            ? "Feedback angeben!"
            : "Feedback missing!"),
        (v) =>
          v.length < 1000 ||
          (localStorage.getItem("lang") == "de"
            ? "Feedback zu lang!"
            : "Feedback too long!"),
      ],
      fileUploads: [],
      filesUntilUploadSuccessful: -1,

      tags: [],
    };
  },

  computed: {
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 7;
        case "md":
          return 6;
        case "lg":
          return 4;
        default:
          return 3;
      }
    },
  },

  watch: {
    filesUntilUploadSuccessful() {
      if (this.filesUntilUploadSuccessful == 0) {
        this.filesUntilUploadSuccessful = -1;
        this.$router.push("/overview/faq");
      }
    },
  },

  methods: {
    backToFaq() {
      this.$router.push("/overview/faq");
    },
    addFeedback() {
      if (this.$refs.feedbackForm.validate()) {
        let formData = {
          users: localStorage.getItem("id"),
          created: moment(new Date()).format("YYYY-MM-DD"),
          text: this.feedback,
          feedbackimage_set: [],
          tags: [],
          finished: false
        };

        for(let i = 0; i < this.tags.length; i++){
          if(this.tags[i].enabled){
            formData.tags.push(this.tags[i].id);
          }
        }

        axios
          .post(axios.defaults.baseURL + "feedback/new", formData)
          .then((resp) => {
            this.filesUntilUploadSuccessful = this.fileUploads.length;
            if (this.fileUploads.length > 0) {
              for (let i = 0; i < this.fileUploads.length; i++) {
                let imageData = new FormData();
                imageData.append("feedback", resp.data.id);
                imageData.append("file", this.fileUploads[i]);

                axios
                  .post(axios.defaults.baseURL + "feedback/images", imageData)
                  .then(() => {
                    this.filesUntilUploadSuccessful--;
                  })
                  .catch((e) => {
                    console.log(e);
                    this.filesUntilUploadSuccessful--;
                  });
              }
            }
          })
          .catch((error) => console.log(error));
      }
    },
    switchTag(tagId) {
      for (let i = 0; i < this.tags.length; i++) {
        if (this.tags[i].id == tagId) {
          this.tags[i].enabled = !this.tags[i].enabled;

          this.$forceUpdate();
          break;
        }
      }
    },
    applySat(col, amt) {
      col = col.replace(/^#/, "");
      if (col.length === 3)
        col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];

      let [r, g, b] = col.match(/.{2}/g);
      [r, g, b] = [
        parseInt(r, 16) + amt,
        parseInt(g, 16) + amt,
        parseInt(b, 16) + amt,
      ];

      r = Math.max(Math.min(255, r), 0).toString(16);
      g = Math.max(Math.min(255, g), 0).toString(16);
      b = Math.max(Math.min(255, b), 0).toString(16);

      const rr = (r.length < 2 ? "0" : "") + r;
      const gg = (g.length < 2 ? "0" : "") + g;
      const bb = (b.length < 2 ? "0" : "") + b;

      return `#${rr}${gg}${bb}`;
    },
  },

  mounted() {
    axios
      .get(axios.defaults.baseURL + "feedback/tags")
      .then((resp) => {
        this.tags = resp.data;

        for (let i = 0; i < this.tags.length; i++) {
          this.tags[i].enabled = false;
          this.tags[i].secondaryColor = this.applySat(this.tags[i].color, 100);
        }
      })
      .catch((e) => console.log(e));

      if(this.$route.query.text){
        this.feedback = this.$route.query.text;
      }
  },
};
</script>
