<template>
  <div>
    <table class="tg mt-8" id="kpi_table" v-for="data in roundData" :key="data.id">
      <thead>
        <tr>
          <th class="main whiteFont font fontMedium bold size td" colspan="1">
            <span v-if="language == 'de'"> PaketNr. </span><span v-else>ParcelNr.</span>
          </th>
          <th class="main whiteFont font fontMedium bold size td" colspan="1">
            <span v-if="language == 'de'"> Von </span><span v-else>From</span>
          </th>
          <th class="main whiteFont font fontMedium bold size td" colspan="1">
            <span v-if="language == 'de'"> Zu </span><span v-else>To</span>
          </th>
          <th class="main whiteFont font fontMedium bold size td" colspan="1">
            <span v-if="language == 'de'"> Startzeit </span><span v-else>Start time</span>
          </th>
          <th class="main whiteFont font fontMedium bold size td" colspan="1">
            <span v-if="language == 'de'"> Endzeit </span><span v-else>End time</span>
          </th>
          <th class="main whiteFont font fontMedium bold size td" colspan="1">
            <span v-if="language == 'de'"> Differenz </span><span v-else>Difference</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(parcel, index) in data.data.finishedParcels" :key="index">
          <td class="main whiteFont font bold alignCenter">{{index + 1}}</td>
          <td class="inputBox font bold alignCenter">{{getCustomerIndex(parcel, 0)}}</td>
          <td class="inputBox font bold alignCenter">{{getCustomerIndex(parcel, 1)}}</td>
          <td class="inputBox font bold alignCenter">{{parcel.timestamp_start}}</td>
          <td class="inputBox font bold alignCenter">{{parcel.timestamp_end}}</td>
          <td class="inputBox font bold alignCenter">{{calcDifference(parcel.timestamp_start, parcel.timestamp_end)}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "parcelTimes",

  props: ["tableData"],

  data() {
    return {
      language: localStorage.getItem("lang") == "de" ? "de" : "en",

      roundData: []
    };
  },

  watch: {
    tableData(){
        console.log(this.tableData);
        this.fillDataIntoTables();
    }
  },


  methods: {
    fillDataIntoTables(){
        for(let i = 0; i < this.tableData.rounds.length; i++){
            if(this.tableData.rounds[i].id == this.tableData.round1){
                this.roundData[0] = this.tableData.rounds[i];
                this.roundData[0].data.finishedParcels.sort((a, b) => {if(a.id > b.id) {return 1} else { return -1} })
            }
            else if(this.tableData.rounds[i].id == this.tableData.round2){
                this.roundData[1] = this.tableData.rounds[i];
                this.roundData[1].data.finishedParcels.sort((a, b) => {if(a.id > b.id) {return 1} else { return -1} })
            }
            if(this.tableData.rounds[i].id == this.tableData.round3){
                this.roundData[2] = this.tableData.rounds[i];
                this.roundData[2].data.finishedParcels.sort((a, b) => {if(a.id > b.id) {return 1} else { return -1} })
            }
        }

        this.$forceUpdate()
    },
    calcDifference(start, end){
        if(isNaN(parseInt(start))){
            return end;
        }

        return parseInt(end) - parseInt(start);
    },
    getCustomerIndex(parcel, index){
      switch(parcel.id.charAt(index)){
        case "0": return "N";
        case "1": return "W";
        case "2": return "S";
        case "3": return "E";
        default: return "--";
      }
    }
  }
};
</script>
