<template>
  <div id="trainers">
    <v-container fluid>
      <v-row>
        <v-col class="mx-4 mb-4" :cols="cols">
          <v-btn @click="newTrainer()" dark>
            <v-icon left>mdi-plus</v-icon>
            {{ $t("message.addTrainer") }}
          </v-btn>
        </v-col>
        <v-spacer />
        <v-spacer />
        <v-col cols="auto">
          <v-btn @click="backToMain()" class="white--text mr-">{{
            $t("message.back")
          }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row>
        <v-col class="mx-4 mb-4">
          <v-data-table
            :headers="headers"
            :items="trainers"
            class="elevation-1"
            hide-default-footer
            :items-per-page="trainers.length"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <div style="display: flex">
                <div v-if="item.type == 'admin' || item.type == 'masterTrainer'" style="display: flex">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small class="mr-2 activeIcon" v-bind="attrs" v-on="on">
                        mdi-star
                      </v-icon>
                    </template>
                    <span>Master Trainer</span>
                  </v-tooltip>
                </div>

                <div v-else-if="item.type == 'headTrainer'" style="display: flex">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small class="mr-2" v-bind="attrs" v-on="on">
                        mdi-star
                      </v-icon>
                    </template>
                    <span>Head Trainer</span>
                  </v-tooltip>
                </div>

                <div v-else @click="openConfirmationMessage(item)" style="display: flex">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small class="mr-2" v-bind="attrs" v-on="on">
                        mdi-star-outline
                      </v-icon>
                    </template>
                    <span>Trainer</span>
                  </v-tooltip>
                </div>
                <v-icon v-if="isHeadTrainer" small class="mr-2" @click="showDeletionWarning = item.id">
                  mdi-trash-can
                </v-icon>
              </div>
            </template>
          </v-data-table>
          <v-dialog v-model="dialog" persistent max-width="420">
            <v-card v-if="dialog">
              <v-card-title class="text-h5">
                {{ $t("message.promoteTrainerToHead") }}
              </v-card-title>
              <v-card-text> </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="white" text @click="trainerToHeadTrainer()" large>
                  {{ $t("message.agree") }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="white" text @click="dialog = false" large>
                  {{ $t("message.notAgree") }}
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>

    <v-overlay :value="showDeletionWarning" :dark="false">
      <v-card class="pa-8">
        <v-container>
          <v-row>
            <v-col>
              <h1>Trainer {{deletionWarningTrainer}} löschen ?</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p>Diese Aktion kann nicht rückgängig gemacht werden.</p>
            </v-col></v-row
          >

          <v-row>
            <v-col>
              <v-btn
                @click="deleteTrainer()"
                dark
                large
                style="background-color: red !important"
                >Ja, löschen</v-btn
              >
            </v-col>
            <v-col>
              <v-btn dark large @click="showDeletionWarning = false"
                >Nein, doch nicht</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-overlay>
  </div>
</template>

<script>
const axios = require("axios");

import * as userTypeHelper from "../plugins/userTypeHelper.js";

export default {
  name: "trainers",

  data() {
    return {
      trainers: [],
      dialog: false,
      clickedTrainer: "",
      userChoices: [],
      headers: [
        {
          text: "First Name",
          align: "start",
          sortable: false,
          value: "first_name",
        },
        { text: "Last Name", value: "last_name" },
        { text: "Email", value: "email" },
        { text: "Rank", value: "actions", sortable: false },
      ],

      showDeletionWarning: false,
      deletionWarningTrainer: "",
    };
  },

  watch: {
    showDeletionWarning(val){
      if(val){
        for(let i = 0; i < this.trainers.length; i++){
          if(this.trainers[i].id == val){
            this.deletionWarningTrainer = this.trainers[i].first_name + " " + this.trainers[i].last_name;
          }
        }
      }
    }
  },

  computed: {
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 7;
        case "md":
          return 5;
        case "lg":
          return 3;
        default:
          return 2;
      }
    },
    isHeadTrainer(){
      return userTypeHelper.checkHeadTrainerPlusPerm();
    }
  },

  methods: {
    deleteTrainer() {

      axios.patch("trainers/delete/" + this.showDeletionWarning + "/", {
        is_active: false,
      })
      .then(() => {
        this.showDeletionWarning = false;
        this.fetchTrainers();
      })
    },

    newTrainer() {
      this.$router.push("/overview/trainers/new");
    },

    openConfirmationMessage(item) {
      if (userTypeHelper.checkMasterTrainerPlusPerm()) {
        this.dialog = true;
        this.clickedTrainer = item;
      }
    },

    trainerToHeadTrainer() {
      let type;
      for (let i = 0; i < this.userChoices.length; i++) {
        if (this.userChoices[i][1] == "headTrainer") {
          type = this.userChoices[i][0];
          break;
        }
      }

      axios
        .patch(axios.defaults.baseURL + "users/simple/" + this.clickedTrainer.id + "/", {
          user_type: type,
        })
        .then(() => {
          this.dialog = false;
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    backToMain() {
      this.$router.push("/overview");
    },
    fetchTrainers(){
      
      axios.get(axios.defaults.baseURL + "trainers/").then((resp) => {
      this.trainers = resp.data;
      
      console.log(this.trainers)

      axios.get(axios.defaults.baseURL + "users/choices/").then((response) => {
        this.userChoices = response.data[0].USER_TYPE_CHOICES;

        for (let i = 0; i < this.trainers.length; i++) {
          let usertype = this.trainers[i].user_type;

          for (let j = 0; j < response.data[0].USER_TYPE_CHOICES.length; j++) {
            if (usertype == response.data[0].USER_TYPE_CHOICES[j][0]) {
              this.$set(
                this.trainers[i],
                "type",
                response.data[0].USER_TYPE_CHOICES[j][1]
              );
            }
          }
        }
      });
    });
    }
  },

  mounted() {
    this.fetchTrainers()
  },
};
</script>
