<template>
  <div style="height: 70vh">
    <v-container fluid>
      <v-row>
        <v-spacer />
        <v-col cols="auto">
          <v-btn @click="backToTrainer()" class="white--text mr-">{{
            $t("message.back")
          }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="pa-6" fluid fill-height>
      <v-row justify="center" align="center">
        <v-col :cols="cols">
          <v-card>
            <v-form ref="trainerForm">
              <v-container class="pa-6 py-15" fill-height>
                <v-row justify="center" align="center">
                  <v-col cols="10">
                    <h4>
                      {{ $t("message.addTrainer") }}
                    </h4>
                  </v-col>
                </v-row>
                <v-row justify="center" align="center" v-if="trainerAddStatus == 0">
                  <v-col cols="10">
                    <v-text-field
                      label="Email"
                      v-model="email"
                      :rules="emailRules"
                      @submit="addNewTrainer()"
                    ></v-text-field>
                    <v-text-field style="display:none"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="center" align="center">
                  <v-col cols="5">
                    <v-btn
                      v-if="trainerAddStatus == 0"
                      @click="addNewTrainer()"
                      large
                      block
                      color="primary"
                      elevation="2"
                    >
                      {{ $t("message.add") }}
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row justify="center" align="center" v-if="trainerAddStatus == 1">
                  <v-col cols="10">
                    E-Mail wurde versendet!
                    <v-btn
                      @click="backToTrainer()"
                      large
                      block
                      color="primary"
                      elevation="2"
                      class="mt-4"
                    >
                      {{ $t("message.back") }}
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row justify="center" align="center" v-if="trainerAddStatus == 2">
                  <v-col cols="10">
                    <h3>Oops.. beim versenden der E-Mail ist etwas schief gegangen!</h3>
                    <p>
                      Ist der Trainer bereits im System oder hat er innerhalb der letzten
                      24h schon eine Einladung erhalten?
                    </p>
                    <v-btn
                      @click="backToTrainer()"
                      large
                      block
                      color="primary"
                      elevation="2"
                    >
                      {{ $t("message.back") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
              
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
const axios = require("axios");
export default {
  name: "newTrainer",

  data() {
    return {
      email: "",
      trainerData: [],
      trainerAddStatus: 0,
      emailRules: [
        (v) =>
          !!v ||
          (localStorage.getItem("lang") == "de"
            ? "E-Mail ist erforderlich!"
            : "E-mail is required"),
        (v) =>
          /.+@.+\..+/.test(v) ||
          (localStorage.getItem("lang") == "de"
            ? "Email muss gültig sein!"
            : "E-mail must be valid"),
      ],
      displayLanguage: localStorage.getItem("lang"),
    };
  },

  computed: {
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 7;
        case "md":
          return 6;
        case "lg":
          return 4;
        default:
          return 3;
      }
    },
  },

  methods: {
    addNewTrainer() {
      if (this.$refs.trainerForm.validate()) {
        axios
          .post(axios.defaults.baseURL + "invitationtokens/", {
            email: this.email,
          })
          .then(() => {
            this.trainerAddStatus = 1;
          })
          .catch(() => {
            this.trainerAddStatus = 2;
          });
      }
    },

    backToTrainer() {
      this.$router.push("/overview/trainers");
    },
  },
};
</script>
