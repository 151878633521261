<template>
  <div id="ticketDetail">
    <v-container fluid>
      <v-row>
        <v-spacer />
        <v-col cols="auto">
          <v-btn @click="backToTickets()" class="white--text mr-">{{
            $t("message.back")
          }}</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="mx-6 px-4" v-if="ticketData">
      <v-row v-if="ticketData.finished == false">
        <v-col cols="1">
          <v-btn dark @click="finishTicket()"
            ><v-icon>mdi-check</v-icon> Done</v-btn
          >
        </v-col>
        <v-col cols="1">
          <v-btn dark @click="cancelTicket()"
            ><v-icon>mdi-cancel</v-icon> Cancel</v-btn
          >
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="1">
          <v-btn dark @click="reOpenTicket()"
            ><v-icon>mdi-arrow-u-left-bottom</v-icon> Reopen</v-btn
          >
        </v-col>
      </v-row>
    </v-container>

    <v-card min-height="85vh" class="ma-6 pa-4">
      <v-container fluid v-if="ticketData">
        <v-row class="pb-6">
          <v-col
            cols="auto"
            v-for="tag in shownTags"
            :key="tag.id"
            style="padding: 1px"
          >
            <v-chip
              class="chipStyle"
              :style="{
                'border-color': tag.color,
                'background-color': tag.secondaryColor,
              }"
              >{{ tag.name }}</v-chip
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-card min-height="20vh">
              {{ ticketData.text }}
            </v-card>
          </v-col>
        </v-row>

        <v-row v-if="ticketData.feedbackimage_set.length > 0">
          <v-col
            cols="4"
            v-for="file in ticketData.feedbackimage_set"
            :key="file"
          >
            <v-img :src="file.file"> </v-img>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="auto">
            <p v-if="displayLanguage == 'en'">No images for this ticket!</p>
            <p v-if="displayLanguage == 'de'">
              Keine Bilder für dieses Ticket!
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
const axios = require("axios");
export default {
  name: "ticketDetail",

  data() {
    return {
      ticketData: undefined,
      shownTags: [],
      displayLanguage: localStorage.getItem("lang"),
    };
  },

  methods: {
    reOpenTicket() {
      axios
        .patch(
          axios.defaults.baseURL + "feedback/" + this.ticketData.id + "/",
          {
            finished: false,
          }
        )
        .then(() => {
          this.backToTickets();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    finishTicket() {
      axios
        .patch(
          axios.defaults.baseURL + "feedback/" + this.ticketData.id + "/",
          {
            finished: true,
          }
        )
        .then(() => {
          this.backToTickets();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancelTicket() {
      axios
        .patch(
          axios.defaults.baseURL + "feedback/" + this.ticketData.id + "/",
          {
            finished: null,
          }
        )
        .then(() => {
          this.backToTickets();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    applySat(col, amt) {
      col = col.replace(/^#/, "");
      if (col.length === 3)
        col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];

      let [r, g, b] = col.match(/.{2}/g);
      [r, g, b] = [
        parseInt(r, 16) + amt,
        parseInt(g, 16) + amt,
        parseInt(b, 16) + amt,
      ];

      r = Math.max(Math.min(255, r), 0).toString(16);
      g = Math.max(Math.min(255, g), 0).toString(16);
      b = Math.max(Math.min(255, b), 0).toString(16);

      const rr = (r.length < 2 ? "0" : "") + r;
      const gg = (g.length < 2 ? "0" : "") + g;
      const bb = (b.length < 2 ? "0" : "") + b;

      return `#${rr}${gg}${bb}`;
    },

    backToTickets() {
      this.$router.push("/overview/tickets");
    },
  },

  mounted() {
    axios
      .get(axios.defaults.baseURL + "feedback/" + this.$route.params.id)
      .then((resp) => {
        this.ticketData = resp.data;
        
        axios //authenticated get request
          .get(axios.defaults.baseURL + "feedback/tags")
          .then((r) => {
            for (let i = 0; i < r.data.length; i++) {
              if (this.ticketData.tags.indexOf(r.data[i].id) >= 0) {
                r.data[i].secondaryColor = this.applySat(r.data[i].color, 100);
                this.shownTags.push(r.data[i]);
              }
            }
          });
      })
      .catch((error) => console.log(error));
  },
};
</script>