<template>
    <div>
        
        <router-view>  </router-view>
    </div>
</template>

<script>

    export default {
    name: "mainCourse",

    data(){
        return{
        }
    }
    }

</script>
