<template>
  <div>
    <v-app-bar fixed app color="toolbar" flat>
      <v-toolbar color="toolbar" flat id="toolbar">
        <router-link to="/overview" tag="button" class="headerLink">
          <v-img
            id="headerImg"
            src="../assets/images/logo_complete.png"
            class="headerLink"
            height="64px"
            width="auto"
          ></v-img>
        </router-link>
        <v-toolbar-title class="font-weight-medium" id="headerText">{{
          titleProp
        }}</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-spacer></v-spacer>

        <v-col v-if="showSearchBar" class="pt-8">
          <searchBar v-model="searchQueue" />
        </v-col>

        <v-spacer></v-spacer>

        <v-col
          class="mt-4 pa-0"
          sm="1"
          id="langSelector"
          style="opacity: 0.5 !important"
        >
          <v-select
            v-model="language"
            :items="langs"
            @change="changeLanguage()"
          ></v-select>
        </v-col>

        <p v-if="showName" class="my-3 mr-5 text-no-wrap text-truncate">
          Hallo, {{ name }}
        </p>
        <v-icon class="pr-3" id="logoutIcon" @click="logOut">mdi-logout</v-icon>
      </v-toolbar>

      <div
        class="stickyMenuHelper"
        style="position: absolute"
        v-if="showJumpToRole"
      >
        <v-menu open-on-hover right :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="wiMenu" color="primary" dark v-bind="attrs" v-on="on">
              {{ $t("message.roles") }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in items" :key="index">
              <v-list-item-title
                class="px-4 clickable"
                @click="gotoItem(item.route)"
                >{{ item.name }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
const axios = require("axios");

import searchBar from "./SearchBar.vue";

export default {
  name: "headerComponent",
  props: ["titleProp"],

  data() {
    return {
      name: localStorage.getItem("name"),
      langs: ["en", "de"],
      language: null,
      searchQueue: "",
      userType: "",

      showJumpToRole: false,
      items: [
        {
          name: "CUSTOMER",
          route: "CUSTOMER_WI",
        },
        {
          name: "COURIER",
          route: "COURIER_WI",
        },
        {
          name: "MESSENGER",
          route: "MESSENGER_WI",
        },
        {
          name: "CL_INCOMING_GOODS",
          route: "CL_INCOMING_GOODS_WI",
        },
        {
          name: "HD_INCOMING_GOODS",
          route: "HD_INCOMING_GOODS_WI",
        },
        {
          name: "CL_WEIGHT_TARIF",
          route: "CL_WEIGHT_TARIF_WI",
        },
        {
          name: "CL_ROUTE_TARIF",
          route: "CL_ROUTE_TARIF_WI",
        },
        {
          name: "CL_ACCOUNTING",
          route: "CL_ACCOUNTING_WI",
        },
        {
          name: "CL_OUTGOING_GOODS",
          route: "CL_OUTGOING_GOODS_WI",
        },
        {
          name: "HD_OUTGOING_GOODS",
          route: "HD_OUTGOING_GOODS_WI",
        },
        {
          name: "MANAGING_DIRECTOR",
          route: "MANAGING_DIRECTOR_WI",
        },
        {
          name: "SALES_MANAGER",
          route: "SALES_MANAGER_WI",
        },
        {
          name: "SALES_REPRESENTATIVE",
          route: "SALES_REPRESENTATIVE_WI",
        },
        {
          name: "CONTROLLER",
          route: "CONTROLLER_WI",
        },
      ],
    };
  },

  components: {
    searchBar,
  },

  computed: {
    showName() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return true;
        default:
          return true;
      }
    },
    twoLines() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        default:
          return false;
      }
    },
    showSearchBar() {
      if (
        this.$route.path == "/overview/courses" ||
        this.$route.path == "/overview/courses/search"
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    searchQueue: function () {
      this.$emit("input", this.searchQueue);
    },

    $route() {
      if (this.$route.path == "/overview/faq/workInstructions") {
        this.showJumpToRole = true;
      } else {
        this.showJumpToRole = false;
      }
    },
  },

  methods: {
    logOut: function () {
      this.$store.dispatch("logoutUser");
    },
    changeLanguage() {
      localStorage.setItem("lang", this.language);
      window.location.reload();
    },
    gotoItem(val) {
      document.getElementById(val).scrollIntoView({ behavior: "smooth" });
    },
  },

  created() {
    if (localStorage.getItem("lang") == null) {
      localStorage.setItem("lang", "en");
    }
    this.language = localStorage.getItem("lang");
  },

  mounted() {
    axios //authenticated get request
      .get(
        axios.defaults.baseURL +
          "users/search/?id=" +
          localStorage.getItem("mail")
      )
      .then((response) => {
        this.name = response.data[0].first_name;
        localStorage.setItem("name", response.data[0].first_name);
        localStorage.setItem("id", response.data[0].id);
        let usertype = response.data[0].user_type;
        localStorage.setItem("data", response.data[0].is_staff);

        axios
          .get(axios.defaults.baseURL + "users/choices/")
          .then((response) => {
            for (
              let i = 0;
              i < response.data[0].USER_TYPE_CHOICES.length;
              i++
            ) {
              if (usertype == response.data[0].USER_TYPE_CHOICES[i][0]) {
                let oldVal = localStorage.getItem("userType");

                localStorage.setItem(
                  "userType",
                  response.data[0].USER_TYPE_CHOICES[i][1]
                );

                if(oldVal != response.data[0].USER_TYPE_CHOICES[i][1]){
                    window.location.reload();
                }
              }
            }
          });
      })
      .catch((error) => console.log(error));

    if (this.$route.path == "/overview/faq/workInstructions") {
      this.showJumpToRole = true;
    } else {
      this.showJumpToRole = false;
    }
  },

  updated() {
    this.$emit("input", this.searchQueue);
  },
};
</script>
