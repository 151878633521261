<template>
  <div>
    <v-container fluid class="mt-4">
       <v-row @click="switchStatisticsContent(5)" class="statisticsHeader pa-0">
        <v-col cols="1" class="pa-2">
          <v-icon v-if="showContent[5] ? false : true" color="white"
            >mdi-plus</v-icon
          >
          <v-icon v-if="showContent[5] ? true : false" color="white"
            >mdi-minus</v-icon
          >
        </v-col>
        <v-col cols="11" class="pa-2">
          <h4>General</h4>
        </v-col>
      </v-row>
      <v-row
        class="pt-0 pb-4"
        :class="
          showContent[5]
            ? 'visibleStatisticsContent'
            : 'inVisibleStatisticsContent'
        "
      >
        <v-spacer> </v-spacer>
        <v-col class="py-0" cols="10" style="margin: 0 auto">
          <parcelTimes :tableData="tableData" />
        </v-col>
        <v-spacer> </v-spacer>
      </v-row>

      <br />
      <br />

      <v-row @click="switchStatisticsContent(0)" class="statisticsHeader pa-0">
        <v-col cols="1" class="pa-2">
          <v-icon v-if="showContent[0] ? false : true" color="white"
            >mdi-plus</v-icon
          >
          <v-icon v-if="showContent[0] ? true : false" color="white"
            >mdi-minus</v-icon
          >
        </v-col>
        <v-col cols="11" class="pa-2">
          <h4>Quality KPI</h4>
        </v-col>
      </v-row>
      <v-row
        class="pt-0 pb-4"
        :class="
          showContent[0]
            ? 'visibleStatisticsContent'
            : 'inVisibleStatisticsContent'
        "
      >
        <v-spacer> </v-spacer>
        <v-col class="py-0" cols="10" style="margin: 0 auto">
          <qualityKPIChart :options="options.quality" :tableData="tableData" />
        </v-col>
        <v-spacer> </v-spacer>
      </v-row>

      <br />
      <br />

      <v-row @click="switchStatisticsContent(1)" class="statisticsHeader pa-0">
        <v-col cols="1" class="pa-2">
          <v-icon v-if="showContent[1] ? false : true" color="white"
            >mdi-plus</v-icon
          >
          <v-icon v-if="showContent[1] ? true : false" color="white"
            >mdi-minus</v-icon
          >
        </v-col>
        <v-col cols="11" class="pa-2">
          <h4>Finance KPI</h4>
        </v-col>
      </v-row>
      <v-row
        class="my-4"
        :class="
          showContent[1]
            ? 'visibleStatisticsContent'
            : 'inVisibleStatisticsContent'
        "
      >
        <v-spacer> </v-spacer>
        <v-col class="py-0" cols="10" style="margin: 0 auto">
          <financeKPIChart :options="options.finance" :tableData="tableData" />
        </v-col>
        <v-spacer> </v-spacer>
      </v-row>

      <br />
      <br />

      <v-row @click="switchStatisticsContent(2)" class="statisticsHeader pa-0">
        <v-col cols="1" class="pa-2">
          <v-icon v-if="showContent[2] ? false : true" color="white"
            >mdi-plus</v-icon
          >
          <v-icon v-if="showContent[2] ? true : false" color="white"
            >mdi-minus</v-icon
          >
        </v-col>
        <v-col cols="11" class="pa-2">
          <h4>WIP KPI</h4>
        </v-col>
      </v-row>
      <v-row
        class="my-4"
        :class="
          showContent[2]
            ? 'visibleStatisticsContent'
            : 'inVisibleStatisticsContent'
        "
      >
        <v-spacer> </v-spacer>
        <v-col class="py-0" cols="10" style="margin: 0 auto">
          <wipKPIChart :options="options.time" :tableData="tableData"
        /></v-col>
        <v-spacer> </v-spacer>
      </v-row>

      <br />
      <br />

      <v-row @click="switchStatisticsContent(3)" class="statisticsHeader pa-0">
        <v-col cols="1" class="pa-2">
          <v-icon v-if="showContent[3] ? false : true" color="white"
            >mdi-plus</v-icon
          >
          <v-icon v-if="showContent[3] ? true : false" color="white"
            >mdi-minus</v-icon
          >
        </v-col>
        <v-col cols="11" class="pa-2">
          <h4>Time KPI</h4>
        </v-col>
      </v-row>

      <v-row
        class="my-4"
        :class="
          showContent[3]
            ? 'visibleStatisticsContent'
            : 'inVisibleStatisticsContent'
        "
      >
        <v-spacer> </v-spacer>
        <v-col class="py-0" cols="10" style="margin: 0 auto">
          <timeKPIChart :options="options.time" :tableData="tableData" />
        </v-col>
        <v-spacer> </v-spacer>
      </v-row>

      <br />
      <br />

      <v-row @click="switchStatisticsContent(4)" class="statisticsHeader pa-0">
        <v-col cols="1" class="pa-2">
          <v-icon v-if="showContent[2] ? false : true" color="white"
            >mdi-plus</v-icon
          >
          <v-icon v-if="showContent[2] ? true : false" color="white"
            >mdi-minus</v-icon
          >
        </v-col>
        <v-col cols="11" class="pa-2">
          <h4>Tables</h4>
        </v-col>
      </v-row>
      <v-row
        class="my-4"
        :class="
          showContent[4]
            ? 'visibleStatisticsContent'
            : 'inVisibleStatisticsContent'
        "
      >
        <v-spacer> </v-spacer>
        <v-col class="py-0" cols="10" style="margin: 0 auto">
         <h6 class="mb-1">Round 1</h6>
          <div v-for="(table, index) in round1Tables" :key="index">
            <p>{{table.role}}</p>
            <table v-html="table.data"></table>
            <br />
            <br />
          </div>
          <br />
          <h6 class="mb-1">Round 2</h6>
          <div v-for="(table, index) in round2Tables" :key="index">
            <p>{{table.role}}</p>
            <table v-html="table.data"></table>
            <br />
            <br />
          </div>
          <br />
          <h6 class="mb-1">Round 3</h6>
          <div v-for="(table, index) in round3Tables" :key="index">
            <p>{{table.role}}</p>
            <table v-html="table.data"></table>
            <br />
            <br />
          </div>
        </v-col>
        <v-spacer> </v-spacer>
      </v-row>

      <br />
      <br />
    </v-container>
  </div>
</template>

<script>
import qualityKPIChart from "../../components/charts/QualityKPIChart.vue";
import financeKPIChart from "../../components/charts/FinanceKPIChart.vue";
import timeKPIChart from "../../components/charts/TimeKPIChart.vue";
import wipKPIChart from "../../components/charts/WIPKPIChart.vue";
import parcelTimes from "../../components/charts/ParcelTimes.vue";

const axios = require("axios");

export default {
  name: "courseStatistics",

  data() {
    return {
      showContent: [true, true, true, true, true, true],
      tableData: {},
      options: {
        quality: {
          vAxis: {
            title: "",
            viewWindowMode: "explicit",
            minValue: 20,
          },
          hAxis: {
            title: "",
          },
          seriesType: "bars",
          // series: {
          //   4: {
          //     type: "line",
          //   },
          // },
          height: 450,
          colors: ["#F2F2F2", "#E0E0E0", "#CECECE", "#BCBCBC", "#EB5930"],
        },
        finance: {
          vAxis: {
            title: "Gain",
            viewWindowMode: "explicit",
            viewWindow: {
              max: 5000,
              min: -5000,
            },
          },
          hAxis: {
            title: "Rounds",
          },
          seriesType: "bars",
          colors: ["#F2F2F2", "#E0E0E0", "#EB5930", "#CECECE", "#BCBCBC"],
        },
        wip: {},
        time: {
          height: 500,
          tooltip: {
            trigger: "none",
          },
          colors: [
            "#FF714D",
            "#AD4C33",
            "#ff0000",
            "#FF714D",
            "#AD4C33",
            "#5e5e5e",
            "#5e5e5e",
            "#5e5e5e",
            "#5e5e5e",
          ],
          timeline: {
            showBarLabels: false,
          },
          hAxis: {
            format: "m:ss",
            minValue: 0,
            maxValue: 60000,
          },
          enableInteractivity: false,
          avoidOverlappingGridLines: false,
          groupByRowLabel: true,
        },
      },
      round1Tables: [],
      round2Tables: [],
      round3Tables: [],
    };
  },

  components: {
    qualityKPIChart,
    timeKPIChart,
    financeKPIChart,
    wipKPIChart,
    parcelTimes
  },

  methods: {
    switchStatisticsContent(number) {
      this.showContent[number] = !this.showContent[number];
      this.$forceUpdate();
    },
  },

  mounted() {
    axios
      .get(
        axios.defaults.baseURL +
          "RoundsByCourse/?course=" +
          this.$route.params.id
      )
      .then((resp) => {
        this.tableData = resp.data[0];
        
        for(let i = 0; i < this.tableData.rounds.length; i++){
          if(this.tableData.rounds[i].id == this.tableData.round1){
            for(let j in this.tableData.rounds[i].tables){
              this.round1Tables.push(this.tableData.rounds[i].tables[j])
            }
          }
          else if(this.tableData.rounds[i].id == this.tableData.round2){
            for(let j in this.tableData.rounds[i].tables){
              this.round2Tables.push(this.tableData.rounds[i].tables[j])
            }
          }
          else if(this.tableData.rounds[i].id == this.tableData.round3){
            for(let j in this.tableData.rounds[i].tables){
              this.round3Tables.push(this.tableData.rounds[i].tables[j])
            }
          }
        }
      });
  },
};
</script>

