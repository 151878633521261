import { render, staticRenderFns } from "./MainFAQ.vue?vue&type=template&id=1c0f31f7&"
import script from "./MainFAQ.vue?vue&type=script&lang=js&"
export * from "./MainFAQ.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports