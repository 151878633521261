<template>
  <div id="courses">
    <v-container fluid>
      <v-row>
        <v-col class="mx-4 mb-4" :cols="cols">
          <v-btn @click="newCourse()" dark>
            <v-icon left>mdi-plus</v-icon>
            {{ $t("message.newCourse") }}
          </v-btn>
        </v-col>
        <v-spacer />
        <v-col>
          <p><v-icon v-if="isHeadPlus"> mdi-star </v-icon> = Own Course </p>
        </v-col>
        <v-spacer />
        <v-col class="mx-4" cols="auto">
          <v-btn
            icon
            @click="switchAllCourseView()"
            class="iconButton mx-4 mt-0"
            v-if="
              isHeadPlus
            "
          >
            <v-icon :class="{ activeIcon: showAllCourses }"
              >mdi-account-multiple</v-icon
            >
          </v-btn>
          <v-btn
            icon
            class="iconButton mx-4 mt-0"
            @click="switchOldCourseView()"
          >
            <v-icon :class="{ activeIcon: showOldCourses }"
              >mdi-timer-outline</v-icon
            >
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="px-10" fluid>
      <v-row class="mb-4">
        <v-col
          :cols="cols"
          v-for="course in courses"
          :key="course.id"
          class="mx-0 px-0"
        >
          <courseCard :course="course" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
const axios = require("axios");

import courseCard from "../components/CourseCard.vue";
import * as userTypeHelper from "../plugins/userTypeHelper.js";

export default {
  name: "courses",

  data() {
    return {
      courses: [],

      showAllCourses: false,
      showOldCourses: false,

      userType: localStorage.getItem("userType"),
    };
  },

  components: {
    courseCard,
  },

  computed: {
    isHeadPlus(){
      return userTypeHelper.checkHeadTrainerPlusPerm();
    },
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 6;
        case "md":
          return 6;
        case "lg":
          return 3;
        case "xl":
          return 2;
        default:
          return 4;
      }
    },
  },

  methods: {
    gotoCourse(id) {
      this.$router.push("/overview/courses/" + id + "/main");
    },

    newCourse() {
      this.$router.push("/overview/courses/new");
    },

    switchAllCourseView() {
      this.showAllCourses = !this.showAllCourses;
      localStorage.setItem("showAllCourses", this.showAllCourses);

      this.requestCourses()
    },

    switchOldCourseView() {
      this.showOldCourses = !this.showOldCourses;
      localStorage.setItem("showOldCourses", this.showOldCourses);

      this.requestCourses();
    },
    requestCourses() {
      axios
        .get(
          axios.defaults.baseURL +
            "courses/select/?own=" +
            localStorage.getItem("id") +
            "&showAll=" +
            this.showAllCourses +
            "&showOld=" +
            this.showOldCourses
        )
        .then((resp) => {
          this.courses = resp.data;
        })
        .catch((e) => console.log(e));
    },
  },

  mounted() {
    this.showAllCourses = localStorage.getItem("showAllCourses") == "true" ? true : false;
    this.showOldCourses = localStorage.getItem("showOldCourses") == "true" ? true : false;

    if (userTypeHelper.checkTrainerAndLowerPerm()) {
      this.showAllCourses = false;
      localStorage.setItem("showAllCourses", false);
    }

    this.requestCourses();

    this.$forceUpdate();
  },
};
</script>
