<template>
  <div>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col :cols="cols">
          <drop @drop="onDropFirstRound" mode="cut1">
            <drag :data="roundOne" @click="removeActiveRound(roundOne, 1)">
              <v-card v-if="roundOne">
                <v-col class="formattedRoundsHeader">
                  <h4>{{ $t("message.round") }} 1</h4>
                </v-col>
                <v-card-text>
                  <div class="mb-2">
                    <v-icon> mdi-calendar </v-icon>
                    {{ $t("message.statistic_roundCreated_date") }}:
                    {{ roundOne.created | moment("DD.MM.YY") }}
                  </div>
                  <div class="mb-2">
                    <v-icon> mdi-clock </v-icon>
                    {{ $t("message.statistic_roundCreated_time") }}:
                    {{ roundOne.created | moment("HH:mm") }}
                  </div>
                </v-card-text>
              </v-card>

              <v-card v-else>
                <v-col class="formattedRoundsHeader">
                  <h4>{{ $t("message.round") }} 1</h4>
                </v-col>
                <v-card-text>
                  <div class="mb-2">
                    {{ $t("message.noRounds") }}
                  </div>

                  <div class="mb-2">
                    <br />
                  </div>
                  <div class="mb-2"></div>
                </v-card-text>
              </v-card>
            </drag>
          </drop>
        </v-col>

        <v-col :cols="cols">
          <drop @drop="onDropSecondRound" mode="cut2">
            <drag :data="roundTwo" @click="removeActiveRound(roundTwo, 2)">
              <v-card v-if="roundTwo">
                <v-col class="formattedRoundsHeader">
                  <h4>{{ $t("message.round") }} 2</h4>
                </v-col>
                <v-card-text>
                  <div class="mb-2">
                    <v-icon> mdi-calendar </v-icon>
                    {{ $t("message.statistic_roundCreated_date") }}:
                    {{ roundTwo.created | moment("DD.MM.YY") }}
                  </div>
                  <div class="mb-2">
                    <v-icon> mdi-clock </v-icon>
                    {{ $t("message.statistic_roundCreated_time") }}:
                    {{ roundTwo.created | moment("HH:mm ") }}
                  </div>
                </v-card-text>
              </v-card>

              <v-card v-else>
                <v-col class="formattedRoundsHeader">
                  <h4>{{ $t("message.round") }} 2</h4>
                </v-col>
                <v-card-text>
                  <div class="mb-2">
                    {{ $t("message.noRounds") }}
                  </div>

                  <div class="mb-2">
                    <br />
                  </div>
                  <div class="mb-2"></div>
                </v-card-text>
              </v-card>
            </drag>
          </drop>
        </v-col>

        <v-col :cols="cols">
          <drop @drop="onDropThirdRound" mode="cut3">
            <drag :data="roundThree" @click="removeActiveRound(roundThree, 3)">
              <v-card v-if="roundThree">
                <v-col class="formattedRoundsHeader">
                  <h4>{{ $t("message.round") }} 3</h4>
                </v-col>
                <v-card-text>
                  <div class="mb-2">
                    <v-icon> mdi-calendar </v-icon>
                    {{ $t("message.statistic_roundCreated_date") }}:
                    {{ roundThree.created | moment("DD.MM.YY") }}
                  </div>
                  <div class="mb-2">
                    <v-icon> mdi-clock </v-icon>
                    {{ $t("message.statistic_roundCreated_time") }}:
                    {{ roundThree.created | moment("HH:mm") }}
                  </div>
                </v-card-text>
              </v-card>

              <v-card v-else>
                <v-col class="formattedRoundsHeader">
                  <h4>{{ $t("message.round") }} 3</h4>
                </v-col>
                <v-card-text>
                  <div class="mb-2">
                    {{ $t("message.noRounds") }}
                  </div>

                  <div class="mb-2">
                    <br />
                  </div>
                  <div class="mb-2"></div>
                </v-card-text>
              </v-card>
            </drag>
          </drop>
        </v-col>
      </v-row>
    </v-container>

    <v-divider />

    <v-container>
      <v-row style="display: flex; align-items: center">
        <v-icon style="color: #ffcbc4">mdi-square</v-icon>
        <p class="ma-0" style="color: #ffcbc4" v-if="displayLanguage != 'de'">
          0 Finished Parcels
        </p>
        <p class="ma-0" style="color: #ffcbc4" v-else>
          0 Abgeschlossene Pakete
        </p>
      </v-row>
      <v-row v-if="rounds.length" class="mt-4">
        <v-col v-for="round in rounds" :key="round.id" :cols="cols">
          <drag
            :data="round"
            @cut1="putInRoundOne(round)"
            @cut2="putInRoundTwo(round)"
            @cut3="putInRoundThree(round)"
          >
            <v-card
              :style="{
                'background-color':
                  round.data.finishedParcels.length == 0 ? '#ffcbc4' : 'white',
              }"
            >
              <v-card-text text-no-wrap text-truncate>
                <v-spacer />
                <div class="mb-4 mt-4">
                  <v-icon> mdi-calendar </v-icon>
                  {{ $t("message.created") }}:
                  {{ round.created | moment("DD.MM.YY") }}
                </div>
                <div class="mb-2">
                  <v-icon> mdi-clock </v-icon>
                  {{ $t("message.time") }}:
                  {{ round.created | moment("HH:mm") }}
                </div>
              </v-card-text>
            </v-card>
          </drag>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
const axios = require("axios");
import { Drag, Drop } from "vue-easy-dnd";

export default {
  name: "courseGames",

  components: {
    Drag,
    Drop,
  },

  data() {
    return {
      rounds: [],

      roundOne: undefined,
      roundTwo: undefined,
      roundThree: undefined,

      displayLanguage: localStorage.getItem("lang"),
    };
  },

  computed: {
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 10;
        case "sm":
          return 6;
        case "md":
          return 4;
        case "lg":
          return 3;
        case "xl":
          return 2;
        default:
          return 4;
      }
    },
  },

  mounted() {
    axios
      .get(
        axios.defaults.baseURL +
          "RoundsByCourse/?course=" +
          this.$route.params.id
      )
      .then((resp) => {
        this.rounds = resp.data[0].rounds;
        this.rounds.sort(
          (a, b) =>
            new Date(b.created).getTime() - new Date(a.created).getTime()
        );


        for (let i = this.rounds.length - 1; i >= 0; i--) {
          if (this.rounds[i].id == resp.data[0].round1) {
            this.roundOne = this.rounds[i];
            this.rounds.splice(i, 1);
          } else if (this.rounds[i].id == resp.data[0].round2) {
            this.roundTwo = this.rounds[i];
            this.rounds.splice(i, 1);
          } else if (this.rounds[i].id == resp.data[0].round3) {
            this.roundThree = this.rounds[i];
            this.rounds.splice(i, 1);
          }
        }
      });
  },

  methods: {
    onDropFirstRound(e, removeOld = true) {
      if (this.roundOne && removeOld) {
        this.rounds.push(this.roundOne);
      }

      this.roundOne = e.data;
      axios.patch(
        axios.defaults.baseURL +
          "courses/updateRounds/" +
          this.$route.params.id,
        { round1: e.data.id }
      );
    },

    onDropSecondRound(e, removeOld = true) {
      if (this.roundTwo && removeOld) {
        this.rounds.push(this.roundTwo);
      }

      if (this.roundOne) {
        this.roundTwo = e.data;
        axios.patch(
          axios.defaults.baseURL +
            "courses/updateRounds/" +
            this.$route.params.id,
          { round2: e.data.id }
        );
      } else {
        this.onDropFirstRound(e, false);
      }
    },

    onDropThirdRound(e, removeOld = true) {
      if (this.roundThree && removeOld) {
        this.rounds.push(this.roundThree);
      }

      if (this.roundTwo && this.roundOne) {
        this.roundThree = e.data;
        axios
          .patch(
            axios.defaults.baseURL +
              "courses/updateRounds/" +
              this.$route.params.id,
            { round3: e.data.id }
          );
      } else {
        this.onDropSecondRound(e, false);
      }
    },

    onDrop(e) {
      this.rounds.push(e.data);
      this.rounds.sort(
        (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
      );
    },

    putInRoundOne(n) {
      let index = this.rounds.indexOf(n);
      this.rounds.splice(index, 1);
    },

    putInRoundTwo(n) {
      if (this.roundOne) {
        let index = this.rounds.indexOf(n);
        this.rounds.splice(index, 1);
      }
    },

    putInRoundThree(n) {
      if (this.roundOne && this.roundTwo) {
        let index = this.rounds.indexOf(n);
        this.rounds.splice(index, 1);
      }
    },

    removeActiveRound(round, roundIndex) {
      if (roundIndex == 1) {
        this.roundOne = undefined;

        axios
          .patch(
            axios.defaults.baseURL +
              "courses/updateRounds/" +
              this.$route.params.id,
            { round1: null }
          )
          .then(() => this.rounds.push(round))
          .catch(() => (this.roundOne = round));
      } else if (roundIndex == 2) {
        this.roundTwo = undefined;
        axios
          .patch(
            axios.defaults.baseURL +
              "courses/updateRounds/" +
              this.$route.params.id,
            { round2: null }
          )
          .then(() => this.rounds.push(round))
          .catch(() => (this.roundOne = round));
      } else {
        this.roundThree = undefined;
        axios
          .patch(
            axios.defaults.baseURL +
              "courses/updateRounds/" +
              this.$route.params.id,
            { round3: null }
          )
          .then(() => this.rounds.push(round))
          .catch(() => (this.roundOne = round));
      }
    },
  },
};
</script>

