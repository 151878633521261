var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"trainers"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"mx-4 mb-4",attrs:{"cols":_vm.cols}},[_c('v-btn',{attrs:{"dark":""},on:{"click":function($event){return _vm.newTrainer()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("message.addTrainer"))+" ")],1)],1),_c('v-spacer'),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"white--text mr-",on:{"click":function($event){return _vm.backToMain()}}},[_vm._v(_vm._s(_vm.$t("message.back")))])],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"mx-4 mb-4"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.trainers,"hide-default-footer":"","items-per-page":_vm.trainers.length},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex"}},[(item.type == 'admin' || item.type == 'masterTrainer')?_c('div',{staticStyle:{"display":"flex"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 activeIcon",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-star ")])]}}],null,true)},[_c('span',[_vm._v("Master Trainer")])])],1):(item.type == 'headTrainer')?_c('div',{staticStyle:{"display":"flex"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-star ")])]}}],null,true)},[_c('span',[_vm._v("Head Trainer")])])],1):_c('div',{staticStyle:{"display":"flex"},on:{"click":function($event){return _vm.openConfirmationMessage(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-star-outline ")])]}}],null,true)},[_c('span',[_vm._v("Trainer")])])],1),(_vm.isHeadTrainer)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){_vm.showDeletionWarning = item.id}}},[_vm._v(" mdi-trash-can ")]):_vm._e()],1)]}}],null,true)}),_c('v-dialog',{attrs:{"persistent":"","max-width":"420"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t("message.promoteTrainerToHead"))+" ")]),_c('v-card-text'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"white","text":"","large":""},on:{"click":function($event){return _vm.trainerToHeadTrainer()}}},[_vm._v(" "+_vm._s(_vm.$t("message.agree"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"white","text":"","large":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("message.notAgree"))+" ")]),_c('v-spacer')],1)],1):_vm._e()],1)],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.showDeletionWarning,"dark":false}},[_c('v-card',{staticClass:"pa-8"},[_c('v-container',[_c('v-row',[_c('v-col',[_c('h1',[_vm._v("Trainer "+_vm._s(_vm.deletionWarningTrainer)+" löschen ?")])])],1),_c('v-row',[_c('v-col',[_c('p',[_vm._v("Diese Aktion kann nicht rückgängig gemacht werden.")])])],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticStyle:{"background-color":"red !important"},attrs:{"dark":"","large":""},on:{"click":function($event){return _vm.deleteTrainer()}}},[_vm._v("Ja, löschen")])],1),_c('v-col',[_c('v-btn',{attrs:{"dark":"","large":""},on:{"click":function($event){_vm.showDeletionWarning = false}}},[_vm._v("Nein, doch nicht")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }