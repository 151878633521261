<template>
  <div id="courseCard">
    <v-card
      class="px-0 rounded-xl"
      @click="gotoCourse(course.id)"
      :height="cardHeight"
      max-width="97%"
    >
      <v-icon v-if="isOwnCourse" class="ticketContainerFileIcon">mdi-star</v-icon>
      <br />
      <v-img :src="course.type.image" height="200px"></v-img>
      <v-card-title
        text-no-wrap
        text-truncate
        style="overflow-x: clip; width: 90%; text-overflow: ellipsis; white-space: nowrap"
      >
        {{ course.title }}
      </v-card-title>
      <v-divider />
      <v-card-text text-no-wrap text-truncate>
        <div class="mb-2">
          <v-icon> mdi-calendar </v-icon>
          {{ $t("message.start") }}:
          {{ course.startDate | moment("DD.MM.YY") }}
        </div>
        <div>
          <v-icon> mdi-calendar </v-icon>
          {{ $t("message.end") }}:
          {{ course.endDate | moment("DD.MM.YY") }}
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "courseCard",

  props: ["course"],

  data() {
    return {
      isOwnCourse: false,
    };
  },

  computed: {
    cardHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "375px";
        case "sm":
          return "385px";
        case "md":
          return "375px";
        case "lg":
          return "375px";
        case "xl":
          return "375px";
        default:
          return "395px";
      }
    },
  },

  methods: {
    gotoCourse(id) {
      this.$router.push("/overview/courses/" + id + "/main");
    },
  },

  mounted() {
    let ownID = localStorage.getItem("id");

    for (let i = 0; i < this.course.users.length; i++) {
      if (this.course.users[i] == ownID) {
        this.isOwnCourse = true;
      }
    }
  },
};
</script>
