<template>
  <div id="qualityKPI">
    <div id="statistic_KPI" class="tabcontent firstActive">
      <br />
      <table class="tg" id="kpi_table">
        <thead>
          <tr>
            <th class="main whiteFont font fontMedium bold size td" colspan="1">
              <i class="fa fa-users" aria-hidden="true"></i>
            </th>
            <th class="main whiteFont font fontMedium bold size td" colspan="3">
              #Dispatched
            </th>
            <th class="main whiteFont font fontMedium bold size td" colspan="3">
              #Incoming
            </th>
            <th class="main whiteFont font fontMedium bold size td" colspan="3">
              #Delayed
            </th>
            <th class="main whiteFont font fontMedium bold size td" colspan="3">
              #WrongLocation
            </th>
            <th class="main whiteFont font fontMedium bold size td" colspan="3">
              #DefectiveStar
            </th>
            <th
              class="main whiteFont font fontMedium bold size tdd"
              colspan="3"
            >
              #DefectiveParcel
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="main whiteFont font bold alignCenter">Round</td>
            <td class="greyBox font bold alignCenter">1</td>
            <td class="greyBox font bold alignCenter">2</td>
            <td class="greyBox font bold alignCenter">3</td>
            <td class="greyBox font bold alignCenter">1</td>
            <td class="greyBox font bold alignCenter">2</td>
            <td class="greyBox font bold alignCenter">3</td>
            <td class="greyBox font bold alignCenter">1</td>
            <td class="greyBox font bold alignCenter">2</td>
            <td class="greyBox font bold alignCenter">3</td>
            <td class="greyBox font bold alignCenter">1</td>
            <td class="greyBox font bold alignCenter">2</td>
            <td class="greyBox font bold alignCenter">3</td>
            <td class="greyBox font bold alignCenter">1</td>
            <td class="greyBox font bold alignCenter">2</td>
            <td class="greyBox font bold alignCenter">3</td>
            <td class="greyBox font bold alignCenter">1</td>
            <td class="greyBox font bold alignCenter">2</td>
            <td class="greyBox font bold alignCenter">3</td>
          </tr>
          <tr id="northRow">
            <td class="main whiteFont font bold alignCenter">North</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr id="eastRow">
            <td class="main whiteFont font bold alignCenter">East</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr id="southRow">
            <td class="main whiteFont font bold alignCenter">South</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr id="westRow">
            <td class="main whiteFont font bold alignCenter">West</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr id="sumRow">
            <td class="accent whiteFont font bold alignCenter">SUM</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
        </tbody>
      </table>

      <br />

      <!-- <div id="chart_div"></div> -->
    </div>

    <div v-if="tableData.rounds">
      <h6>Round 1</h6>
      <GChart
        v-if="tableData.rounds"
        :settings="{ packages: ['corechart'] }"
        type="ComboChart"
        :data="collectData(1)"
        :options="options"
        id="gChart"
      />
    </div>
    <div v-if="tableData.rounds && roundNumber > 1">
      <h6>Round 2</h6>
      <GChart
        v-if="tableData.rounds && roundNumber > 1"
        :settings="{ packages: ['corechart'] }"
        type="ComboChart"
        :data="collectedData ? collectedData[1] : collectData(2)"
        :options="options"
        id="gChart2"
      />
    </div>
    <div v-if="tableData.rounds && roundNumber > 2">
      <h6>Round 3</h6>
      <GChart
        v-if="tableData.rounds && roundNumber > 2"
        :settings="{ packages: ['corechart'] }"
        type="ComboChart"
        :data="collectedData ? collectedData[2] : collectData(3)"
        :options="options"
        id="gChart3"
      />
    </div>
  </div>
</template>

<script>
import { GChart } from "vue-google-charts";

export default {
  name: "qualityKPI",

  props: ["options", "tableData"],

  data() {
    return {
      roundNumber: 0,
      collectedData: undefined,
    };
  },

  components: {
    GChart,
  },
  methods: {
    collectData(number) {
      if (!this.tableData.rounds || this.tableData.rounds.length == 0) return;

      let data = [];
      let sortingHelper = [
        this.tableData.round1,
        this.tableData.round2,
        this.tableData.round3,
      ];
      for (let i = 0; i < this.tableData.rounds.length; i++) {
        if (
          this.tableData.rounds[i].id == this.tableData.round1 ||
          this.tableData.rounds[i].id == this.tableData.round2 ||
          this.tableData.rounds[i].id == this.tableData.round3
        ) {
          data[sortingHelper.indexOf(this.tableData.rounds[i].id)] =
            this.tableData.rounds[i].data;
        }
      }

      if (data.length == 0) return;
      this.roundNumber = data.length;

      let chartData = this.getChartData(data[data.length - 1]);

      let remainingData = [];
      for (let i = 0; i < data.length - 1; i++) {
        remainingData.push(this.getChartData(data[i]));
      }

      this.setupKPITable(remainingData, chartData);

      //clear out sums for google charts
      for (let i = 0; i < chartData.length; i++) {
        chartData[i].splice(chartData[i].length - 1, 1);
      }

      for (let j = 0; j < remainingData.length; j++) {
        for (let i = 0; i < remainingData[j].length; i++) {
          remainingData[j][i].splice(remainingData[j][i].length - 1, 1);
        }
      }

      let returnables = [];
      for (let i = 0; i < remainingData.length; i++) {
        returnables.push(remainingData[i]);
      }
      returnables.push(chartData);
      this.collectedData = returnables;

      if (returnables[number - 1]) return returnables[number - 1];
      else return;
    },
    getChartData(data) {
      let dispatchedParcels = ["Dispatched", 0, 0, 0, 0, 0];
      for (let i = 0; i < data.totalPerCustomer.length; i++) {
        dispatchedParcels[i + 1] = data.totalPerCustomer[i];
        dispatchedParcels[5] += data.totalPerCustomer[i];
      }

      // dispatchedParcels[5] = Math.floor(dispatchedParcels[5]); //sum
      // dispatchedParcels[5] = Math.floor(dispatchedParcels[5] / 4); //average

      //finished parcel data by location
      let finishedParcels = ["Incoming", 0, 0, 0, 0, 0];
      for (let i = 0; i < data.finishedParcels.length; i++) {
        let index =
          parseInt(
            data.finishedParcels[i].id.charAt(
              data.finishedParcels[i].id.length - 1
            )
          ) + 1;
        finishedParcels[index]++;
        finishedParcels[5]++;
      }

      // finishedParcels[5] = Math.floor(finishedParcels[5]); //sum
      // finishedParcels[5] = Math.floor(finishedParcels[5] / 4); //avg

      //defective
      let defectiveParcels = ["Defective", 0, 0, 0, 0, 0];

      //time error
      let delayed = ["Delayed", 0, 0, 0, 0, 0];
      for (let i = 0; i < data.finishedParcels.length; i++) {
        let startTime = parseInt(data.finishedParcels[i].timestamp_start);
        let endTime = parseInt(data.finishedParcels[i].timestamp_end);

        if (endTime - startTime > 7) {
          let index =
            parseInt(
              data.finishedParcels[i].id.charAt(
                data.finishedParcels[i].id.length - 1
              )
            ) + 1;
          delayed[index]++;
          data.finishedParcels[i].isDefective = true;
          delayed[5]++;
        }
      }

      // delayed[5] = Math.floor(delayed[5]);
      // delayed[5] = Math.floor(delayed[5] / 4);

      //loc error
      let location = ["Wrong Location", 0, 0, 0, 0, 0];
      for (let i = 0; i < data.finishedParcels.length; i++) {
        if (
          parseInt(
            data.finishedParcels[i].id.charAt(
              data.finishedParcels[i].id.length - 1
            )
          ) != parseInt(data.finishedParcels[i].id.charAt(1))
        ) {
          let index =
            parseInt(
              data.finishedParcels[i].id.charAt(
                data.finishedParcels[i].id.length - 1
              )
            ) + 1;
          location[index]++;
          data.finishedParcels[i].isDefective = true;
          location[5]++;
        }
      }

      // location[5] = Math.floor(location[5]);
      // location[5] = Math.floor(location[5] / 4);

      //red: fc1703, blue: 53aaed, green: 5de63e,  black: 0d0d0d
      // let preferedStars = [
      //   {
      //     colors: ["0d0d0d", "5de63e"],
      //     shapes: ["1"],
      //   }, //north
      //   {
      //     colors: ["53aaed", "fc1703", "5de63e"],
      //     shapes: ["1"],
      //   }, //west
      //   {
      //     colors: ["5de63e", "53aaed"],
      //     shapes: ["1"],
      //   }, //east
      //   {
      //     colors: ["fc1703", "5de63e"],
      //     shapes: ["1"],
      //   }, //south
      // ];

      let preferedStars = [{
                colors: ["855309", "6153790"],
                shapes: ["0"]
            }, //north
            {
                colors: ["5483245", "16520963", "6153790"],
                shapes: ["0"]
            }, //west 
            {
                colors: ["6153790", "5483245"],
                shapes: ["0"]
            }, //east 
            {
                colors: ["16520963", "6153790"],
                shapes: ["0"]
            } //south
        ];

      //star error
      let defectiveStar = ["Defective Star", 0, 0, 0, 0, 0];
      for (let i = 0; i < data.finishedParcels.length; i++) {
        let starError = true;
        for (let j = 0; j < data.finishedParcels[i].star.length; j++) {

          if (
            preferedStars[data.finishedParcels[i].id.charAt(1)] && preferedStars[data.finishedParcels[i].id.charAt(1)].colors.indexOf(
              "" + data.finishedParcels[i].star[j].color
            ) >= 0 &&
            preferedStars[data.finishedParcels[i].id.charAt(1)] && preferedStars[data.finishedParcels[i].id.charAt(1)].shapes.indexOf(
              "" + data.finishedParcels[i].star[j].type
            ) >= 0
          ) {
            starError = false;
          }
        }

        if (starError) {
          let index =
            parseInt(
              data.finishedParcels[i].id.charAt(
                data.finishedParcels[i].id.length - 1
              )
            ) + 1;
          defectiveStar[index]++;
          data.finishedParcels[i].isDefective = true;
          defectiveStar[5]++;
        }
      }

      defectiveStar[5] = Math.floor(defectiveStar[5]);
      // defectiveStar[5] = Math.floor(defectiveStar[5] / 4);

      for (let i = 0; i < data.finishedParcels.length; i++) {
        if (data.finishedParcels[i].isDefective) {
          let index =
            parseInt(
              data.finishedParcels[i].id.charAt(
                data.finishedParcels[i].id.length - 1
              )
            ) + 1;
          defectiveParcels[index]++;
          defectiveParcels[5]++;
        }
      }

      // defectiveParcels[5] = defectiveParcels[5];
      // defectiveParcels[5] = Math.floor(defectiveParcels[5] / 4);

      return [
        ["CUSTOMER", "N", "E", "S", "W", "sum"],
        dispatchedParcels,
        finishedParcels,
        delayed,
        location,
        defectiveStar,
        defectiveParcels,
      ];
    },
    setupKPITable(remainingData, currData) {
      let roundNumber = remainingData.length + 1;

      this.fillKPITable(
        currData,
        1,
        roundNumber,
        document.getElementById("northRow"),
        true
      ); //datacol detrmined by customer index!
      this.fillKPITable(
        currData,
        3,
        roundNumber,
        document.getElementById("eastRow"),
        true
      );
      this.fillKPITable(
        currData,
        4,
        roundNumber,
        document.getElementById("southRow"),
        true
      );
      this.fillKPITable(
        currData,
        2,
        roundNumber,
        document.getElementById("westRow"),
        true
      );
      this.fillKPITable(
        currData,
        5,
        roundNumber,
        document.getElementById("sumRow"),
        true
      );

      for (let i = 0; i < remainingData.length; i++) {
        this.fillKPITable(
          remainingData[i],
          1,
          i + 1,
          document.getElementById("northRow"),
          false
        ); //datacol detrmined by customer index!
        this.fillKPITable(
          remainingData[i],
          3,
          i + 1,
          document.getElementById("eastRow"),
          false
        );
        this.fillKPITable(
          remainingData[i],
          4,
          i + 1,
          document.getElementById("southRow"),
          false
        );
        this.fillKPITable(
          remainingData[i],
          2,
          i + 1,
          document.getElementById("westRow"),
          false
        );
        this.fillKPITable(
          remainingData[i],
          5,
          i + 1,
          document.getElementById("sumRow"),
          false
        );
      }
    },
    fillKPITable(data, dataCol, roundNumber, parentElement, currentRound) {
      let dataRow = 1;

      for (let i = roundNumber; i < parentElement.children.length; i += 3) {
        if (currentRound)
          parentElement.children[i].innerHTML =
            "<b>" + data[dataRow][dataCol] + "</b>";
        else parentElement.children[i].innerHTML = data[dataRow][dataCol];

        ++dataRow;
      }
    },
  },
};
</script>
