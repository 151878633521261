export default {
    message: {
        newCourse: "New Course",
        ownCourses: "Own courses",
        otherCourses: "Courses of other trainers",
        course: "COURSE",
        startPlay: "START GAME",
        trainer: "TRAINER",
        faqZone: "FAQ ZONE",
        start: "Start",
        end: "End",
        language: "Language",
        addCourse: "Add new course",
        title: "Title",
        startDate: "start Date",
        endDate: "End Date",
        add: "Add",
        courseDescription: "Course description",
        overview: "Overview",
        reviews : "REVIEWS",
        statistics: "STATISTICS",
        round: "ROUND",
        average: "Average",
        gameLiked: "Control/Handling",
        fun: "Game Experience",
        from: "From",
        noRounds: "no rounds available !",
        created: "Created",
        time: "Time",
        createGame: "Create Game",
        chooseCourse: "Choose course",
        startGame: "START GAME",
        abort:"ABORT",
        copyLinkForPlayer: "Copy Link for Player",
        copyLinkForTrainer:  "Copy Link for Trainer",
        enterGame: "Enter Game",
        back: "Back",
        downloadFirstMap: " Basic Map Download ",
        downloadSecondMap: " Adv Map 1 Download ",
        downloadThirdMap: " Adv Map 2 Download ",
        parcels: " Parcels ",
        technicalSupport: "TECHNICAL SUPPORT",
        questionsArea: "QUESTION AREA",
        addFeedback: "Ticket",
        send:"SEND",
        colors: "Colors",
        editCourse: "Edit Course",
        change: "Edit",
        expiredCourses: "Expired Courses",
        allExpiredCourses: "All Expired Courses",
        currentCourses: "Current Courses",
        allCurrentCourses: "All Current Courses",
        addTrainer: "Add new trainer",
        trainerName: "Trainer Name",
        trainerFirstName: "First Name",
        trainerLatName: "Last Name",
        password: "Password",
        trainersList:"List of Trainers",
        recentKey:"Most Recent Game Key",
        error:"Oops, something went wrong!",
        promoteTrainerToHead: "Do you really want to promote the trainer to head trainer?",
        deleteTrainerFromCourseMessage: "Do you really want to remove the trainer from that course ?",
        agree: "Yes",
        notAgree: "No", 
        ownExpiredCourses :"Own Expired Courses",
        otherExpiredCourses :"Other Expired Courses",
        otherCurrentCourses :"Other Current Courses",
        ownCurrentCourses:  "Own Current Courses",
        roles: "ROLES",
        statistic_roundCreated_date: "Created",
        statistic_roundCreated_time: "At",
        preFill: "Pre Fill: (Main Game)"
    }
}