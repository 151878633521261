<template>
  <div class="ma-6">
    <v-card min-height="85vh">
      <v-card-title>
        {{ course.title }}
        <v-icon class="pl-4" @click="gotoEditCourse()">mdi-pencil</v-icon>
        <v-icon class="pl-4" @click="showDeletionWarning = true">mdi-trash-can</v-icon>
        <v-spacer />
        <v-btn @click="backtoCourse()" class="white--text">{{
          $t("message.back")
        }}</v-btn>
      </v-card-title>

      <v-container fluid>
        <v-row>
          <v-col>
            <v-tabs>
              <v-tab @click="gotoOverview()">
                {{ $t("message.overview") }}
              </v-tab>
              <v-tab @click="gotoReview()">{{ $t("message.reviews") }} </v-tab>
              <v-tab @click="gotoStatistics()">{{ $t("message.statistics") }} </v-tab>
              <v-tab @click="gotoGame()">{{ $t("message.round") }} </v-tab>
            </v-tabs>

            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-overlay :value="showDeletionWarning" :dark="false">
      <v-card class="pa-8">
        <v-container>
          <v-row>
            <v-col>
              <h1>Kurs löschen ?</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p>Diese Aktion kann nicht rückgängig gemacht werden.</p>
            </v-col></v-row
          >

          <v-row>
            <v-col>
              <v-btn @click="deleteCourse()" dark large style="background-color: red !important">Ja, löschen</v-btn>
            </v-col>
            <v-col>
              <v-btn dark large @click="showDeletionWarning = false">Nein, doch nicht</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-overlay>
  </div>
</template>

<script>
const axios = require("axios");
const moment = require("moment");

export default {
  name: "courses",

  data() {
    return {
      course: {
        id: undefined,
      },
      showDeletionWarning: false,
    };
  },

  computed: {
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 6;
        case "sm":
          return 4;
        default:
          return 3;
      }
    },
  },

  methods: {
    gotoEditCourse() {
      this.$router.push("/overview/courses/edit/" + this.course.id);
    },
    gotoGame() {
      this.$router.push("/overview/courses/" + this.course.id + "/rounds");
    },
    gotoOverview() {
      this.$router.push("/overview/courses/" + this.course.id + "/main");
    },
    gotoReview() {
      this.$router.push("/overview/courses/" + this.course.id + "/reviews");
    },
    gotoStatistics() {
      this.$router.push("/overview/courses/" + this.course.id + "/statistics");
    },
    backtoCourse() {
      this.$router.push("/overview/courses");
    },
    deleteCourse() {
      axios
        .patch(axios.defaults.baseURL + "courses/simple/" + this.course.id + "/", {
          deleted: true,
        })
        .then(() => {
          this.$router.replace("/overview/courses")
        })
        .catch((error) => console.log(error));
    },
  },

  mounted() {
    axios
      .get(axios.defaults.baseURL + "courses/" + this.$route.params.id)
      .then((resp) => {
        this.course = resp.data;
        this.course.startDate = moment(this.course.startDate).format("DD.MM.YYYY");
        this.course.endDate = moment(this.course.endDate).format("DD.MM.YYYY");
      });

    if (!this.$route.path.includes("/main")) {
      this.$router.push("/overview/courses/" + this.$route.params.id + "/main");
    }
  },
};
</script>
