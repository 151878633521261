<template>
  <div>
    <v-container>
      <v-row no-gutters>
        <v-col class="ma-4" v-for="review in game.review" :key="review.ids">
          <v-card>
            <v-card-title>{{ review.created }}</v-card-title>

            <v-card-text>
              Spiel gefallen:
              <starComponent :num="review.stars[0] || 0" />
              Spaß:
              <starComponent :num="review.stars[1] || 0" />

              <br />
              <v-divider />
              " {{ review.additional }} "
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import starComponent from "../components/StarComponent.vue";

const axios = require("axios");
var moment = require('moment')


export default {
  name: "gamedetail",

  data: function () {
    return {
      game: {
        id: undefined,
        review: [
            {stars: ""}
        ],
      },
    };
  },

  components: {
    starComponent,
  },

  mounted() {
    axios
      .get(axios.defaults.baseURL + "games/" + this.$route.params.gameID)
      .then((resp) => {
        this.game = resp.data;

        for (let i = 0; i < this.game.review.length; i++) {
              this.game.review[i].stars = this.game.review[i].stars.split(" ");
              const date = this.game.review[i].created
              const formattedDate = moment(date).format('MMMM Do YYYY, h:mm a');
              this.game.review[i].created = formattedDate;
        }
        
      });
  },
};
</script>
