<template>
  <div id="searchBar">
    <v-text-field
      class="searchBar"
      color="tertiary"
      clearable
      label="Suche"
      single-line
      
      flat
      prepend-inner-icon="mdi-magnify"
      v-model="searchQueue"
      @click:clear="onClearClicked"
    >
    </v-text-field>
  </div>
</template>

<script>


export default {
  name: "searchBar",

  data() {
    return {
      searchQueue: "",

    };
  },

  methods: {
    onClearClicked(){
      this.searchQueue = ""
    }
  },

 
  watch: {
  searchQueue: function() {
   
        this.$emit("input", this.searchQueue)
         if (this.searchQueue && this.searchQueue.length >= 1) {
          if (this.$route.path != "/overview/courses/search")
            this.$router.push("/overview/courses/search");
     }
     else {
       if (this.$route.path != "/overview/courses"){
          this.$router.push("/overview/courses");
       }
    }
  },


 
  },

};
</script>
