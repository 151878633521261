<template>
  <div id="starComponent">
    <v-icon :style="{ color: selectedStars >= 1 ? '#eb5930' : '#bebebe' }"
      >mdi-star</v-icon
    >
    <v-icon :style="{ color: selectedStars >= 2 ? '#eb5930' : '#bebebe' }"
      >mdi-star</v-icon
    >
    <v-icon :style="{ color: selectedStars >= 3 ? '#eb5930' : '#bebebe' }"
      >mdi-star</v-icon
    >
    <v-icon :style="{ color: selectedStars >= 4 ? '#eb5930' : '#bebebe' }"
      >mdi-star</v-icon
    >
    <v-icon :style="{ color: selectedStars >= 5 ? '#eb5930' : '#bebebe' }"
      >mdi-star</v-icon
    >
  </div>
</template>

<script>
export default {
  name: "starComponent",

  data: function () {
    return {
      selectedStars: 0,
    };
  },

  props: ["num"],

  watch: {
    num: function () {
      this.updateStars();
    },
  },

  methods: {
    updateStars() {
      if (parseInt(this.num)) this.selectedStars = parseInt(this.num);
      else this.selectedStars = 0;

      this.$forceUpdate();
    },
  },

  mounted() {
    this.updateStars();
  },
};
</script>
