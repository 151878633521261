<template>
  <div id="tickets">
      <v-container fluid>
      <v-row>
        <v-spacer />
        <v-col cols="auto">
          <v-btn @click="backToDashboard()" class="white--text mr-">{{
            $t("message.back")
          }}</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col :cols="cols" v-for="ticket in tickets" :key="ticket.id">
          <ticketComponent :data="ticket" :allTags="tags" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>
import ticketComponent from "../components/TicketComponent.vue";

const axios = require("axios");
export default {
  name: "tickets",

  data() {
    return {
      tickets: [],
      tags: [],
    };
  },

  components: {
    ticketComponent,
  },

  computed: {
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 6;
        case "md":
          return 6;
        case "lg":
          return 4;
        default:
          return 2;
      }
    },
  },

  methods: {
    applySat(col, amt) {
      col = col.replace(/^#/, "");
      if (col.length === 3)
        col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];

      let [r, g, b] = col.match(/.{2}/g);
      [r, g, b] = [
        parseInt(r, 16) + amt,
        parseInt(g, 16) + amt,
        parseInt(b, 16) + amt,
      ];

      r = Math.max(Math.min(255, r), 0).toString(16);
      g = Math.max(Math.min(255, g), 0).toString(16);
      b = Math.max(Math.min(255, b), 0).toString(16);

      const rr = (r.length < 2 ? "0" : "") + r;
      const gg = (g.length < 2 ? "0" : "") + g;
      const bb = (b.length < 2 ? "0" : "") + b;

      return `#${rr}${gg}${bb}`;
    },

     backToDashboard() {
      this.$router.push("/overview");
    },
  },

  mounted() {
    axios //authenticated get request
      .get(axios.defaults.baseURL + "feedback")
      .then((resp) => {
        this.tickets = resp.data;
      });

    axios //authenticated get request
      .get(axios.defaults.baseURL + "feedback/tags")
      .then((resp) => {
        this.tags = resp.data;

        for (let i = 0; i < this.tags.length; i++) {
          this.tags[i].enabled = false;
          this.tags[i].secondaryColor = this.applySat(this.tags[i].color, 100);
        }
      });
  },
};
</script>