<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-spacer />
        <v-col cols="auto">
          <v-btn @click="backToDashboard()" class="white--text mr-">{{
            $t("message.back")
          }}</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-form ref="courseForm">
      <v-container class="pa-6" fluid fill-height style="height: 70vh">
        <v-row justify="center" align="center">
          <v-col :cols="cols">
            <v-card>
              <v-container class="pa-6 py-15" fill-height>
                <v-row justify="center" align="center">
                  <v-col cols="10">
                    <h4 v-if="!playerKey && startSuccessful == undefined">
                      {{ $t("message.createGame") }}
                    </h4>
                    <h4 v-else-if="startSuccessful == true">
                      {{ $t("message.createGame") }}!
                    </h4>
                    <h4 v-else>Oops, da ist etwas schief gelaufen!</h4>
                  </v-col>
                </v-row>
                <v-row
                  justify="center"
                  align="center"
                  v-if="!playerKey && startSuccessful == undefined"
                >
                  <v-col cols="10" class="py-0">
                    <v-combobox
                      v-if="displayLang == 'de'"
                      :items="items"
                      label="Kurs wählen"
                      v-model="activeCourse"
                      :value="activeCourse"
                      dense
                      :rules="courseRules"
                      required
                      class="my-4"
                    ></v-combobox>
                    <v-combobox
                      v-else
                      :items="items"
                      label="Choose Course"
                      v-model="activeCourse"
                      :value="activeCourse"
                      :rules="courseRules"
                      dense
                      required
                      class="my-4"
                    ></v-combobox>
                  </v-col>
                </v-row>

                <v-row
                  justify="center"
                  align="center"
                  v-if="!playerKey && startSuccessful == undefined"
                  class="pt-0"
                >
                  <v-spacer />
                  <v-col cols="10" class="pt-0 pb-4">
                    <v-switch
                      v-if="displayLang == 'de'"
                      v-model="trainerAsPlayer"
                      label="Trainer spielt mit"
                      :value="trainerAsPlayer"
                    ></v-switch>
                    <v-switch
                      v-else
                      v-model="trainerAsPlayer"
                      label="Trainer is Player"
                      :value="trainerAsPlayer"
                    ></v-switch>
                  </v-col>
                  <v-spacer />
                </v-row>

                <v-row
                  justify="center"
                  align="center"
                  v-if="!playerKey && startSuccessful == undefined"
                  class="pt-0"
                >
                  <v-spacer />
                  <v-col cols="10" class="pt-0 pb-4">
                    <v-switch
                      v-if="displayLang == 'de'"
                      v-model="tutorialGame"
                      label="Mit Tutorialrunde"
                      :value="tutorialGame"
                      :rules="tutorialAdvRules"
                    ></v-switch>
                    <v-switch
                      v-else
                      v-model="tutorialGame"
                      label="Play Tutorial Round"
                      :value="tutorialGame"
                      :rules="tutorialAdvRules"
                    ></v-switch>
                  </v-col>
                  <v-spacer />
                </v-row>

                <v-row
                  justify="center"
                  align="center"
                  v-if="!playerKey && startSuccessful == undefined"
                  class="pt-0"
                >
                  <v-spacer />
                  <v-col cols="10" class="pt-0 pb-4">
                    <v-switch
                      v-model="isAdvGame"
                      label="Improvement Game"
                      :value="isAdvGame"
                      :rules="tutorialAdvRules"
                    ></v-switch>
                  </v-col>
                  <v-spacer />
                </v-row>

                <v-row
                  justify="center"
                  align="center"
                  v-if="!playerKey && startSuccessful == undefined"
                  class="pt-0"
                >
                  <v-spacer />
                  <v-col cols="4" class="pt-0 pb-4">
                    <v-img
                      v-if="!isAdvGame"
                      :class="{
                        selectedIconButton: mapType == 0 ? true : false,
                        unSelectedIconButton: mapType != 0 ? true : false,
                      }"
                      src="../assets/images/map_basic.jpg"
                      @click="changeMapType(0)"
                    ></v-img>
                    <v-img
                      v-else
                      src="../assets/images/map_basic.jpg"
                      style="opacity: 0.5"
                    ></v-img>
                  </v-col>
                  <v-col cols="4" class="pt-0 pb-4">
                    <v-img
                      v-if="isAdvGame"
                      :class="{
                        selectedIconButton: mapType == 1 ? true : false,
                        unSelectedIconButton: mapType != 1 ? true : false,
                      }"
                      src="../assets/images/map_rows.jpg"
                      @click="changeMapType(1)"
                    ></v-img>
                    <v-img
                      v-else
                      src="../assets/images/map_rows.jpg"
                      style="opacity: 0.5"
                    ></v-img>
                  </v-col>
                  <v-col cols="4" class="pt-0 pb-4">
                    <v-img
                      v-if="isAdvGame"
                      :class="{
                        selectedIconButton: mapType == 2 ? true : false,
                        unSelectedIconButton: mapType != 2 ? true : false,
                      }"
                      src="../assets/images/map_decentral.jpg"
                      @click="changeMapType(2)"
                    ></v-img>
                    <v-img
                      v-else
                      src="../assets/images/map_decentral.jpg"
                      style="opacity: 0.5"
                    ></v-img>
                  </v-col>
                  <v-spacer />
                </v-row>

                <v-row
                  justify="center"
                  align="center"
                  v-if="!playerKey && startSuccessful == undefined"
                  class="pt-0"
                >
                  <v-spacer />
                  <v-col cols="4" class="pt-0 pb-4">
                    <v-img
                      v-if="isAdvGame"
                      :class="{
                        selectedIconButton: preConfig == 0 ? true : false,
                        unSelectedIconButton: preConfig != 0 ? true : false,
                      }"
                      src="../assets/images/config_none.jpg"
                      @click="changePreConfig(0)"
                    ></v-img>
                    <v-img
                      v-else
                      src="../assets/images/config_none.jpg"
                      style="opacity: 0.5"
                    ></v-img>
                  </v-col>
                  <v-col cols="4" class="pt-0 pb-4">
                    <v-img
                      v-if="isAdvGame"
                      :class="{
                        selectedIconButton: preConfig == 1 ? true : false,
                        unSelectedIconButton: preConfig != 1 ? true : false,
                      }"
                      src="../assets/images/config_totalAddedValue.jpg"
                      @click="changePreConfig(1)"
                    ></v-img>
                    <v-img
                      v-else
                      src="../assets/images/config_totalAddedValue.jpg"
                      style="opacity: 0.5"
                    ></v-img>
                  </v-col>
                  <v-col cols="4" class="pt-0 pb-4">
                    <v-img
                      v-if="isAdvGame"
                      :class="{
                        selectedIconButton: preConfig == 2 ? true : false,
                        unSelectedIconButton: preConfig != 2 ? true : false,
                      }"
                      src="../assets/images/config_retrieverCodes_pricingDoesBilling.jpg"
                      @click="changePreConfig(2)"
                    ></v-img>
                    <v-img
                      v-else
                      src="../assets/images/config_retrieverCodes_pricingDoesBilling.jpg"
                      style="opacity: 0.5"
                    ></v-img>
                  </v-col>
                  <v-spacer />
                </v-row>

                <v-row class="py-0 mb-0" justify="center" align="center" v-if="!playerKey && startSuccessful == undefined">
                  <v-col class="py-0 my-0" cols="10">
                    <p class="py-0 my-0">{{ $t("message.preFill") }}</p>
                  </v-col>
                </v-row>

                <v-row justify="center" align="center" class="py-0 my-0" v-if="!playerKey && startSuccessful == undefined">
                  <v-col cols="2" class="py-0"
                    ><p class="py-0 my-0">Inbox:</p>
                  </v-col>
                  <v-col cols="8" class="py-0">
                    <v-slider
                      max="3"
                      v-model="preFill_inbox"
                      thumb-label
                      ticks
                    ></v-slider>
                  </v-col>
                </v-row>

                <v-row justify="center" align="center" class="py-0 mb-5 my-0" v-if="!playerKey && startSuccessful == undefined">
                  <v-col cols="2" class="py-0">
                    <p class="py-0 my-0">Outbox:</p></v-col
                  >
                  <v-col cols="8" class="py-0">
                    <v-slider
                      max="3"
                      v-model="preFill_outbox"
                      thumb-label
                      ticks
                    ></v-slider>
                  </v-col>
                </v-row>

                <v-row
                  justify="center"
                  align="center"
                  v-if="!playerKey && startSuccessful == undefined"
                  class="py-0"
                >
                  <v-spacer />
                  <v-col cols="10" class="py-0">
                    <v-combobox
                      v-if="displayLang == 'de'"
                      :items="timeItems"
                      label="Zeiteinstellung (Minuten)"
                      :rules="timeRules"
                      v-model="time"
                      value="time"
                      dense
                      class="my-4"
                      required
                    ></v-combobox>
                    <v-combobox
                      v-else
                      :items="timeItems"
                      label="Time Setting (Minutes)"
                      :rules="timeRules"
                      v-model="time"
                      value="time"
                      dense
                      class="my-4"
                      required
                    ></v-combobox>
                  </v-col>
                  <v-spacer />
                </v-row>
                <v-row
                  justify="center"
                  align="center"
                  v-if="!playerKey && startSuccessful == undefined"
                >
                  <v-col cols="5">
                    <v-btn
                      @click="checkAndMakeGame"
                      large
                      block
                      color="primary"
                      elevation="2"
                    >
                      {{ $t("message.startGame") }}
                    </v-btn>
                  </v-col>
                  <v-col cols="5">
                    <v-btn
                      @click="backToMain"
                      large
                      block
                      color="grey"
                      elevation="2"
                    >
                      {{ $t("message.abort") }}
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row
                  justify="center"
                  align="center"
                  v-if="startSuccessful == true"
                >
                  <v-col cols="10">
                    <v-text-field
                      v-if="displayLang == 'de'"
                      :value="playerKey"
                      label="Teilnehmerschlüssel"
                      readonly
                    >
                    </v-text-field>
                    <v-text-field
                      v-if="displayLang == 'en'"
                      :value="playerKey"
                      label="Participant key"
                      readonly
                    >
                    </v-text-field>

                    <v-btn
                      @click="copyPlayerUrl"
                      large
                      block
                      color="primary"
                      elevation="2"
                    >
                      {{ $t("message.copyLinkForPlayer") }}
                    </v-btn>

                    <br>
                    <br>
                    <br>

                    <v-text-field
                      v-if="displayLang == 'de'"
                      :value="trainerKey"
                      label="Trainerschlüssel"
                      readonly
                    >
                    </v-text-field>
                    <v-text-field
                      v-if="displayLang == 'en'"
                      :value="trainerKey"
                      label="Trainer key"
                      readonly
                    >
                    </v-text-field>
                    
                    <v-btn
                      @click="copyTrainerUrl"
                      large
                      block
                      color="primary"
                      elevation="2"
                    >
                      {{ $t("message.copyLinkForTrainer") }}
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row
                  justify="center"
                  align="center"
                  v-if="startSuccessful == true"
                >
                  <v-col cols="10">
                    <v-btn
                      @click="openNewGameTab(trainerKey)"
                      large
                      block
                      color="primary"
                      elevation="2"
                    >
                      {{ $t("message.enterGame") }}
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row
                  justify="center"
                  align="center"
                  v-if="startSuccessful == false"
                >
                  <v-col cols="10">
                    <v-btn
                      @click="backToNewGame()"
                      large
                      block
                      color="primary"
                      elevation="2"
                    >
                      {{ $t("message.back") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
const axios = require("axios");

export default {
  name: "gameStartComponent",

  data() {
    return {
      items: [],
      courses: [],
      activeCourse: undefined,
      activeCourseLang: "EN",

      trainerKey: "",
      playerKey: "",

      startSuccessful: undefined,
      displayLang: localStorage.getItem("lang"),

      trainerAsPlayer: false,
      tutorialGame: false,
      isAdvGame: false,
      mapType: 0,
      preConfig: 0,

      preFill_inbox: 0,
      preFill_outbox: 0,

      time: "20",
      timeItems: [
        5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
        24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
      ],

      timeRules: [
        (v) =>
          !!v ||
          (localStorage.getItem("lang") == "de"
            ? "Wähle die Zeit!"
            : "Set Playtime!"),
        (v) =>
          !isNaN(parseInt(v)) ||
          (localStorage.getItem("lang") == "de"
            ? "Gewählte Zeit nicht erkennbar!"
            : "Cannot use this Time!"),
        (v) =>
          parseInt(v) <= 35 ||
          (localStorage.getItem("lang") == "de"
            ? "Gewählte Zeit zu hoch!"
            : "Too many Minutes!"),
      ],

      courseRules: [
        (v) =>
          !!v ||
          (localStorage.getItem("lang") == "de"
            ? "Kurs wählen!"
            : "Choose a Course"),
      ],

      tutorialAdvRules: [
        () =>
          !(this.isAdvGame && this.tutorialGame) ||
          (localStorage.getItem("lang") == "de"
            ? "Kein Tutorial im Improvement Game!"
            : "No Tutorial in Improvement Game!"),
      ],
    };
  },

  computed: {
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 7;
        case "md":
          return 6;
        case "lg":
          return 4;
        default:
          return 3;
      }
    },
  },

  watch: {
    isAdvGame: function (val) {
      if (val) {
        if (this.mapType == 0) {
          this.mapType = 1;
        }
      } else {
        if (this.mapType > 0) {
          this.mapType = 0;
        }
      }
    },
  },

  methods: {
    backToMain() {
      this.$router.push("/overview");
    },
    changeMapType(val) {
      this.mapType = val;
    },
    changePreConfig(val) {
      this.preConfig = val;
    },
    checkAndMakeGame() {
      if (this.$refs.courseForm.validate()) {
        let courseID;
        let i;
        for (i = 0; i < this.courses.length; i++) {
          if (this.courses[i].title == this.activeCourse) {
            courseID = this.courses[i].id;
            this.activeCourseLang = this.courses[i].language;

            break;
          }
        }

        this.trainerKey = this.generateRandomKey(16);
        this.playerKey = this.generateRandomKey(16);

        let newGame = {
          playerKey: this.playerKey,
          trainerKey: this.trainerKey,
          active: true,
          course: courseID,
          trainerAsPlayer: this.trainerAsPlayer ? true : false, //may be null
          tutorialGame: this.tutorialGame ? true : false, //may be null
          advGame: this.isAdvGame ? true : false, //may be null
          mapType: this.mapType,
          preConfig: this.preConfig,
          timeSetting: this.time,
          preFilled: this.preFill_inbox +  "," + this.preFill_outbox
        };

        axios
          .post(axios.defaults.baseURL + "games/simple/", newGame)
          .then(() => {
            this.startSuccessful = true;
          })
          .catch(() => {
            this.startSuccessful = false;
          });
      }
    },
    generateRandomKey(length) {
      var result = "";
      var characters =
        "ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    copyPlayerUrl() {
      navigator.clipboard
        .writeText(
          "https://umsjourfixe.de/login/main/?room=" +
            this.playerKey +
            "&lang=" +
            this.activeCourseLang
        )
        .catch((error) => console.log(error));
    },
    copyTrainerUrl() {
      navigator.clipboard
        .writeText(
          "https://umsjourfixe.de/login/main/?room=" +
            this.trainerKey +
            "&lang=" +
            this.activeCourseLang
        )
        .catch((error) => console.log(error));
    },
    openNewGameTab(key) {
      window
        .open(
          "https://umsjourfixe.de/login/main/?room=" +
            key +
            "&lang=" +
            this.activeCourseLang,
          "_blank"
        )
        .focus();
    },
    backToNewGame() {
      this.$router.push("/overview");
    },
    backToDashboard() {
      this.$router.push("/overview");
    },
  },

  mounted() {
    let coursePreset = this.$route.params.courseID;

    axios
      .get(
        axios.defaults.baseURL +
          "courses/CoursesByUser/?name=" +
          localStorage.getItem("id")
      )
      .then((resp) => {
        this.courses = resp.data;

        this.items = [];
        for (let i = 0; i < this.courses.length; i++) {
          this.items.push(this.courses[i].title);

          if (this.courses[i].id == coursePreset) {
            this.activeCourse = this.courses[i].title;
          }
        }
      });
  },
};
</script>
