<template>
  <div id="mainPage">
    <headerComponent :titleProp="pageTitle"  v-model="searchQueue" />
    
    <router-view :searchQueue="searchQueue" :usertype="userType"/>
    <br>
  </div>
</template>

<script>
import headerComponent from "../components/Header.vue"

export default{
  name: "mainPage",

  
  components: {
      headerComponent
  },
  data() {
    return{
      pageTitle: "Trainer Backend",
      searchQueue: "",
      userType:""
    };
  },

  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    }
  },

};
</script>