<template>
  <div>
    <v-container fluid class="mt-4" v-if="course">
      <v-row>
        <v-col :cols="cols" style="display: flex">
          <v-card style="flex-grow: 1">
            <v-container fluid>
              <v-row class="formattedDateHeader">
                <v-spacer />
                <v-col cols="12" class="formattedDateHeader">
                  <h1 class="text-center">
                    {{ getReadableMonth(course.startDate) }}
                  </h1>
                </v-col>
                <v-spacer />
              </v-row>

              <v-row>
                <v-spacer />
                <v-col cols="12">
                  <h1 class="text-center">{{ getDay(course.startDate) }}</h1>
                </v-col>
                <v-spacer />
              </v-row>
            </v-container>
          </v-card>
        </v-col>

        <v-col :cols="cols" style="display: flex">
          <v-card style="flex-grow: 1">
            <v-container fluid>
              <v-row class="formattedDateHeader">
                <v-spacer />
                <v-col cols="12" class="formattedDateHeader">
                  <h1 class="text-center">
                    {{ getReadableMonth(course.endDate) }}
                  </h1>
                </v-col>
                <v-spacer />
              </v-row>

              <v-row>
                <v-spacer />
                <v-col cols="12" style="height: 100%">
                  <h1 class="text-center">{{ getDay(course.endDate) }}</h1>
                </v-col>
                <v-spacer />
              </v-row>
            </v-container>
          </v-card>
        </v-col>

        <v-spacer></v-spacer>

        <v-col :cols="cols" style="display: flex"
          ><v-card
            class="mx-0"
            @click="gotoStartGame"
            height="100%"
            style="flex-grow: 1"
          >
            <v-img
              src="../../assets/images/startBackground.png"
              class="primary--text align-center justify-center imageCover halfTransparent"
              height="100%"
              ><v-card-title
                text-no-wrap
                text-truncate
                style="justify-content: center; font-size: x-large"
              >
                {{ $t("message.startPlay") }}
              </v-card-title></v-img
            >
          </v-card>
        </v-col>

        <v-spacer></v-spacer>

        <v-col :cols="cols">
          <v-btn @click="downloadMap(1)" dark block class="my-2">
            <v-icon left>mdi-download</v-icon>
            {{ $t("message.downloadFirstMap") }}
          </v-btn>
          <v-btn @click="downloadMap(2)" dark block class="my-2">
            <v-icon left>mdi-download</v-icon>
            {{ $t("message.downloadSecondMap") }}
          </v-btn>
          <v-btn @click="downloadMap(3)" dark block class="my-2">
            <v-icon left>mdi-download</v-icon>
            {{ $t("message.downloadThirdMap") }}
          </v-btn>
          <br />
          <v-btn @click="downloadParcels()" dark block class="my-2">
            <v-icon left>mdi-download</v-icon>
            {{ $t("message.parcels") }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <br />
          <v-textarea
            v-if="displayLanguage == 'de'"
            outlined
            min-height="20vh"
            label="Kursbeschreibung"
            name="input-7-4"
            @change="postCourseDescription()"
            v-model="course.description"
            :value="course.description || ''"
          ></v-textarea>
          <v-textarea
            v-else
            outlined
            min-height="20vh"
            label="Course Description"
            name="input-7-4"
            @change="postCourseDescription()"
            v-model="course.description"
            :value="course.description || ''"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <h4>
          {{ $t("message.recentKey") }}
          <span v-if="course && course.game && course.game.length >= 1"
            >({{
              course.game[course.game.length - 1].created | moment("DD.MM.YY HH:mm")
            }})</span
          >
        </h4>
      </v-row>
      <v-row v-if="course && course.game  && course.game.length >= 1">
        <v-col class="mx-4 mb-0"> Trainer: </v-col>
        <v-col class="mx-4 mb-0"> Player: </v-col>
      </v-row>
      <v-row v-if="course && course.game  && course.game.length >= 1">
        <v-col class="mx-4 mb-4">
          <b
            ><a @click="openNewGameTab(course.game[course.game.length - 1].trainerKey)">{{
              course.game[course.game.length - 1].trainerKey
            }}</a></b
          >
        </v-col>
        <v-col class="mx-4 mb-4">
          <b>{{ course.game[course.game.length - 1].playerKey }}</b>
        </v-col>
      </v-row>
      <v-row>
        <h4>{{ $t("message.trainersList") }}</h4>
        <v-icon
          class="pl-4"
          @click="showAddNewTrainerToCourse()"
          :class="{ activeIcon: showSearchBar }"
          >mdi-plus</v-icon
        >
      </v-row>
      <v-row>
        <v-col class="mx-4 mb-4">
          <v-card>
            <v-card-title>
              <v-text-field
                v-if="showSearchBar"
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              v-if="!showSearchBar && showAlert == false"
              :headers="headers"
              :items="allUsers"
              class="elevation-1"
              hide-default-footer
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click="openConfirmationMessage(item)"> mdi-delete </v-icon>
              </template>
            </v-data-table>

            <v-data-table
              v-if="showSearchBar && search.length > 1"
              :headers="headers"
              :items="searchResults"
              :search="search"
              class="elevation-1"
              hide-default-footer
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="addTrainerToCourse(item)">
                  mdi-plus
                </v-icon>
              </template>
            </v-data-table>

            <v-dialog v-model="dialog" persistent max-width="480">
              <v-card v-if="dialog">
                <v-card-title class="text-h5">
                  {{ $t("message.deleteTrainerFromCourseMessage") }}
                </v-card-title>
                <v-card-text> </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="white" text @click="dialog = false">
                    {{ $t("message.notAgree") }}
                  </v-btn>
                  <v-btn color="white" text @click="deleteTrainerFromCourse()">
                    {{ $t("message.agree") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
const axios = require("axios");
const moment = require("moment");

import map_basic from "../../assets/images/map_basic.jpg";
import map_adv_1 from "../../assets/images/map_rows.jpg";
import map_adv_2 from "../../assets/images/map_decentral.jpg";
import parcels from "../../assets/images/Parcels.jpg";

export default {
  name: "courseOverview",

  data() {
    return {
      search: "",
      course: undefined,
      users: [],
      allUsers: [],
      trainers: [],
      newUsers: [],
      showSearchBar: false,
      showAlert: false,
      searchResults: [],
      dialog: false,
      clickedTrainer: "",
      displayLanguage: localStorage.getItem("lang"),
      headers: [
        {
          text: "First Name",
          align: "start",
          sortable: false,
          value: "first_name",
        },
        { text: "Last Name", value: "last_name" },
        // { text: "Admin", value: "is_staff" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },

  computed: {
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 6;
        case "md":
          return 6;
        case "lg":
          return 3;
        case "xl":
          return 2;
        default:
          return 4;
      }
    },
  },

  methods: {
    openNewGameTab(key) {
      window
        .open(
          "https://umsjourfixe.de/login/main/?room=" +
            key +
            "&lang=" +
            this.course.language,
          "_blank"
        )
        .focus();
    },
    getReadableMonth(val) {
      if (!val) return "";
      if (this.displayLanguage == "de") {
        let month = val.split(".")[1];
        switch (month) {
          case "01":
            return "Januar";
          case "02":
            return "Februar";
          case "03":
            return "März";
          case "04":
            return "April";
          case "05":
            return "Mai";
          case "06":
            return "Juni";
          case "07":
            return "Juli";
          case "08":
            return "August";
          case "09":
            return "September";
          case "10":
            return "Oktober";
          case "11":
            return "November";
          case "12":
            return "Dezember";
        }
      } else {
        let month = val.split(".")[1];
        switch (month) {
          case "01":
            return "January";
          case "02":
            return "February";
          case "03":
            return "March";
          case "04":
            return "April";
          case "05":
            return "May";
          case "06":
            return "June";
          case "07":
            return "July";
          case "08":
            return "August";
          case "09":
            return "September";
          case "10":
            return "October";
          case "11":
            return "November";
          case "12":
            return "December";
        }
      }
    },

    getDay(val) {
      if (!val) return "";

      return val.split(".")[0];
    },

    gotoStartGame() {
      this.$router.push("/overview/game/" + this.course.id);
    },

    postCourseDescription() {
      let formattedEndDate = this.course.endDate.split(".");
      formattedEndDate =
        formattedEndDate[2] + "-" + formattedEndDate[1] + "-" + formattedEndDate[0];

      let formattedStartDate = this.course.endDate.split(".");
      formattedStartDate =
        formattedStartDate[2] + "-" + formattedStartDate[1] + "-" + formattedStartDate[0];

      let formData = {
        id: this.course.id,
        description: this.course.description,
        title: this.course.title,
        customer: this.course.customer,
        users: this.course.users,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      };

      axios
        .put(axios.defaults.baseURL + "courses/simple/" + this.course.id + "/", formData)
        .then(() => {})
        .catch((error) => console.log(error));
    },

    async downloadMap(val) {
      try {
        let response;
        let documentName;

        switch (val) {
          case 1:
            response = await fetch(map_basic);
            documentName = "Map_Basic.jpg";
            break;
          case 2:
            response = await fetch(map_adv_1);
            documentName = "Map_Impr_1.jpg";
            break;
          case 3:
            response = await fetch(map_adv_2);
            documentName = "Map_Impr_2.jpg";
            break;
        }

        let blob = await response.blob();
        let url = await URL.createObjectURL(blob);

        let a = document.createElement("a");
        a.href = url;
        a.download = documentName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (err) {
        console.log({ err });
      }
    },
    async downloadParcels() {
      try {
        let response;
        let documentName;

        response = await fetch(parcels);
        documentName = "Parcels.jpg";

        let blob = await response.blob();
        let url = await URL.createObjectURL(blob);

        let a = document.createElement("a");
        a.href = url;
        a.download = documentName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (err) {
        console.log({ err });
      }
    },
    showAddNewTrainerToCourse() {
      this.showSearchBar = !this.showSearchBar;
    },

    addTrainerToCourse(item) {
      axios
        .get(axios.defaults.baseURL + "courses/" + this.$route.params.id)
        .then((resp) => {
          this.newUsers = resp.data.users;
          this.newUsers.push(item.id);
          axios
            .patch(
              axios.defaults.baseURL + "courses/simple/" + this.$route.params.id + "/",
              { users: this.newUsers }
            )
            .then(() => {
              this.showSearchBar = !this.showSearchBar;
              window.location.reload();
            })
            .catch((error) => console.log(error));
        });
    },

    deleteTrainerFromCourse() {
      axios
        .get(axios.defaults.baseURL + "courses/" + this.$route.params.id)
        .then((resp) => {
          this.newUsers = resp.data.users;
          this.newUsers.splice(this.newUsers.indexOf(this.clickedTrainer.id), 1);
          axios
            .patch(
              axios.defaults.baseURL + "courses/simple/" + this.$route.params.id + "/",
              { users: this.newUsers }
            )
            .then(() => {
              window.location.reload();
            })
            .catch((error) => console.log(error));
        });
    },

    openConfirmationMessage(item) {
      this.dialog = true;
      this.clickedTrainer = item;
    },
  },

  watch: {
    search: function () {
      if (this.search && this.search.length > 1) {
        axios
          .get(axios.defaults.baseURL + "users/searchByName/?name=" + this.search)
          .then((response) => {
            this.searchResults = response.data;
          })
          .catch((error) => console.log(error));
      }
    },
  },

  mounted() {
    axios
      .get(axios.defaults.baseURL + "courses/" + this.$route.params.id)
      .then((resp) => {
        this.course = resp.data;
        this.users = resp.data.users;

        console.log(resp.data)

        console.log(resp.data);
        for (let i = 0; i < this.users.length; i++) {
          axios
            .get(axios.defaults.baseURL + "users/simple/" + this.users[i])
            .then((response) => {
              this.allUsers.push(response.data);
            });
        }
        this.course.startDate = moment(this.course.startDate).format("DD.MM.YYYY");
        this.course.endDate = moment(this.course.endDate).format("DD.MM.YYYY");

        this.$forceUpdate();
      });
  },
};
</script>
