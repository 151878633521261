import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/Login.vue'
import mainPage from '../views/MainPage.vue'
import mainCourse from '../views/MainCourse.vue'
import mainTrainers from '../views/MainTrainers.vue'
import courses from '../views/Courses.vue'
import courseDetail from '../views/CourseDetail.vue'
import leaderboards from '../views/Leaderboards.vue'
import gameDetail from '../views/GameDetail.vue'
import startGame from '../components/NewGame.vue'
import newCourse from '../components/NewCourse.vue'
import newTrainer from '../components/NewTrainer.vue'
import trainers from '../views/Trainers.vue'
import overview from '../components/Overview.vue'
import faqZone from '../views/Faq.vue'
import tickets from '../views/Tickets.vue'
import ticketDetail from '../views/TicketDetail.vue'
import technicalSupport from '../views/FAQViews/TechnicalSupport.vue'
import workInstructions from '../views/FAQViews/WorkInstructions.vue'
import mainfaqZone from '../views/MainFAQ.vue'
import fragenbereich from '../views/FAQViews/FAQ.vue'

import courseGames from '../views/courseViews/CourseRounds.vue'
import courseOverview from '../views/courseViews/CourseOverview.vue'
import courseReview from '../views/courseViews/CourseReview.vue'
import courseStatistics from '../views/courseViews/CourseStatistics.vue'

import searchView from '../views/courseViews/SearchView.vue'

import store from '../store'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  component: login,
  beforeEnter(to, from, next) {
    document.title = 'UMS Backend'
    next()
  }
},

{
  path: '/overview',
  component: mainPage,
  beforeEnter(to, from, next) {
    document.title = 'UMS - GenEx'
    if (store.state.accessToken) {
      next()
    } else {
      next('/')
    }
  },
  children: [{
    path: '',
    component: overview,
    beforeEnter(to, from, next) {
      if (store.state.accessToken) {
        next()
      } else {
        next('/')
      }
    },
  },
  {
    path: '/overview/courses/new',
    component: newCourse,
    beforeEnter(to, from, next) {
      if (store.state.accessToken) {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/overview/courses/edit/:id',
    component: newCourse,
    beforeEnter(to, from, next) {
      if (store.state.accessToken) {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/overview/courses/search',
    component: searchView,
    beforeEnter(to, from, next) {
      if (store.state.accessToken) {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/overview/courses',
    component: mainCourse,
    beforeEnter(to, from, next) {
      if (store.state.accessToken) {
        next()
      } else {
        next('/')
      }
    },
    children: [

      {
        path: '',
        component: courses,
      },
      {
        path: '/overview/courses/:id',
        component: courseDetail,
        children: [{
          path: '/overview/courses/:id/main',
          component: courseOverview
        },
        {
          path: '/overview/courses/:id/reviews',
          component: courseReview
        },
        {
          path: '/overview/courses/:id/statistics',
          component: courseStatistics
        },
        {
          path: '/overview/courses/:id/rounds',
          component: courseGames
        },
        ]
      },
    ]
  },
  {
    path: '/overview/courses/game/:gameID',
    component: gameDetail,
    beforeEnter(to, from, next) {
      if (store.state.accessToken) {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/overview/trainers',
    component: mainTrainers,
    beforeEnter(to, from, next) {
      if (store.state.accessToken) {
        next()
      } else {
        next('/')
      }
    }, children: [
      {
        path: '',
        component: trainers,
      }, 
      {
        path: '/overview/trainers/new',
        component: newTrainer,
        beforeEnter(to, from, next) {
          if (store.state.accessToken) {
            next()
          } else {
            next('/')
          }
        }
      },
      {
        path: '/overview/trainers/edit/:id',
        component: newTrainer,
        beforeEnter(to, from, next) {
          if (store.state.accessToken) {
            next()
          } else {
            next('/')
          }
        }
      },
      
    ]
  },
  {
    path: '/overview/game/:courseID?',
    component: startGame,
    beforeEnter(to, from, next) {
      if (store.state.accessToken) {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/overview/leaderboards',
    component: leaderboards,
    beforeEnter(to, from, next) {
      if (store.state.accessToken) {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/overview/tickets/:id',
    component: ticketDetail,
    beforeEnter(to, from, next) {
      if (store.state.accessToken) {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/overview/tickets',
    component: tickets,
    beforeEnter(to, from, next) {
      if (store.state.accessToken) {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/overview/faq',
    component: mainfaqZone,
    beforeEnter(to, from, next) {
      if (store.state.accessToken) {
        next()
      } else {
        next('/')
      }
    },
    children: [{
      path: '',
      component: faqZone,
    },
    {
      path: '/overview/faq/technicalSupport',
      component: technicalSupport,
      beforeEnter(to, from, next) {
        if (store.state.accessToken) {
          next()
        } else {
          next('/')
        }
      }
    },
    {
      path: '/overview/faq/questionarea',
      component: fragenbereich,
      beforeEnter(to, from, next) {
        if (store.state.accessToken) {
          next()
        } else {
          next('/')
        }
      }
    },
    {
      path: '/overview/faq/workInstructions',
      component: workInstructions,
      beforeEnter(to, from, next) {
        if (store.state.accessToken) {
          next()
        } else {
          next('/')
        }
      }
    },
    ]
  },

  ]
},

{
  path: "*",
  component: login
}

]

const router = new VueRouter({
  mode: 'history',
  routes: routes,
})


export default router