<template>
  <div id="searchResult">
    <v-container class="px-10" fluid>
      <v-row>
        <v-col
          :cols="cols"
          v-for="course in searchResults"
          :key="course.id"
          class="mx-0 px-0"
        >
          <courseCard :course="course" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
const axios = require("axios");

import courseCard from "../../components/CourseCard.vue";
import * as userTypeHelper from "../../plugins/userTypeHelper.js";

export default {
  name: "searchResult",

  props: ["searchQueue"],

  data() {
    return {
      searchResults: [],
    };
  },

  components: {
    courseCard,
  },

  computed: {
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 6;
        case "md":
          return 6;
        case "lg":
          return 3;
        case "xl":
          return 2;
        default:
          return 4;
      }
    },

    cardHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "360px";
        case "sm":
          return "380px";
        case "md":
          return "360px";
        case "lg":
          return "390px";
        case "xl":
          return "390px";
        default:
          return "405px";
      }
    },
  },

  watch: {
    searchQueue: function (val) {
      if (userTypeHelper.checkHeadTrainerPlusPerm()) {
        axios
          .get(
            axios.defaults.baseURL +
              "courses/search/?search=" +
              val
          )
          .then((response) => {
            this.searchResults = response.data;
          })
          .catch((error) => console.log(error));
      } else {
        axios
          .get(
            axios.defaults.baseURL +
              "courses/searchCourseByUser/?search=" +
              val +
              "&user=" +
              localStorage.getItem("id")
          )
          .then((response) => {
            this.searchResults = response.data;
          })
          .catch((error) => console.log(error));
      }
    },
  },

  //  mounted(){
  //     if (this.searchQueue == "" ){
  //       this.$router.push("/overview/courses");
  //     }
  // }
};
</script>
