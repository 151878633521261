<template>
  <div style="height: 70vh">
    <v-container class="pa-6" fluid fill-height>
      <v-row justify="center" align="center">
        <v-col :cols="cols">
          <v-card>
            <v-form ref="courseForm">
              <v-container class="pa-6 py-15" fill-height>
                <v-row justify="center" align="center">
                  <v-col cols="10">
                    <h4 v-if="addCourseSuccessful && isEditView">
                      {{ $t("message.editCourse") }}
                    </h4>
                    <h4 v-else-if="addCourseSuccessful && !isEditView">
                      {{ $t("message.addCourse") }}
                    </h4>
                    <h4 v-else>{{ $t("message.error") }}</h4>
                  </v-col>
                </v-row>
                <v-row
                  justify="center"
                  align="center"
                  v-if="addCourseSuccessful"
                >
                  <v-col cols="10">
                    <v-text-field
                      v-if="displayLanguage == 'de'"
                      label="Titel"
                      :rules="titleRules"
                      required
                      v-model="title"
                    ></v-text-field>
                    <v-text-field
                      v-else
                      label="Title"
                      :rules="titleRules"
                      required
                      v-model="title"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  justify="center"
                  align="center"
                  v-if="addCourseSuccessful"
                >
                  <v-col
                    cols="10"
                    lg="5"
                    style="display: flex; align-items: center"
                  >
                    <v-menu>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          class="pr-3"
                          style="width: 30px; height: 30px"
                        >
                          mdi-calendar
                        </v-icon>
                        <v-text-field
                          v-if="displayLanguage == 'de'"
                          :value="startDateFormatted"
                          :rules="dateRules"
                          v-on="on"
                          label="Startdatum"
                          required
                        ></v-text-field>
                        <v-text-field
                          v-else
                          :value="startDateFormatted"
                          :rules="dateRules"
                          v-on="on"
                          label="Startdate"
                          required
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        color="primary"
                        header-color="accent"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="10"
                    lg="5"
                    style="display: flex; align-items: center"
                  >
                    <v-menu>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          class="pr-3"
                          style="width: 30px; height: 30px"
                        >
                          mdi-calendar
                        </v-icon>
                        <v-text-field
                          v-if="displayLanguage == 'de'"
                          :value="endDateFormatted"
                          :rules="endDateRules"
                          v-on="on"
                          label="Enddatum"
                          required
                        ></v-text-field>
                        <v-text-field
                          v-else
                          :value="endDateFormatted"
                          :rules="endDateRules"
                          v-on="on"
                          label="Enddate"
                          required
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        color="primary"
                        header-color="accent"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <!-- <v-row justify="center" align="center">
                  <v-col cols="10" style="display: flex; align-items: center">
                  </v-col>
                </v-row> -->
                <v-row
                  justify="center"
                  align="center"
                  v-if="addCourseSuccessful"
                >
                  <v-col cols="10" lg="5">
                    <v-select
                      v-if="displayLanguage == 'de'"
                      v-model="language"
                      :value="language"
                      :items="items"
                      :rules="languageRules"
                      label="Sprache"
                      required
                      dense
                    ></v-select>
                    <v-select
                      v-else
                      v-model="language"
                      :items="items"
                      :rules="languageRules"
                      :value="language"
                      label="Language"
                      required
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="10" lg="5">
                    <template>
                      <v-select
                        v-model="badge"
                        :items="badges"
                        :rules="badgesRules"
                        label="Badges"
                        item-text="name"
                        required
                        dense
                      >
                        <template v-slot:item="Props">
                          <img height="90px" :src="Props.item.image" />
                          {{ Props.item.name }}
                        </template>
                      </v-select>
                    </template>
                  </v-col>
                </v-row>
                <v-row
                  justify="center"
                  align="center"
                  v-if="addCourseSuccessful"
                >
                  <v-col cols="10" style="display: flex; align-items: center">
                    <p style="color: rgba(0, 0, 0, 0.6)">
                      {{ $t("message.colors") }}:
                    </p>
                    <v-spacer />
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :color="main"
                          icon
                          @click="showPicker(1)"
                          v-bind="attrs"
                          v-on="on"
                          class="iconButton mx-4"
                          :class="{
                            selectedIconButton:
                              showColorPicker == 1 ? true : false,
                          }"
                        >
                          <v-icon>mdi-marker</v-icon>
                        </v-btn>
                      </template>
                      <span v-if="displayLanguage == 'de'">Hauptfarbe</span>
                      <span v-else>Main Color</span>
                    </v-tooltip>
                    <v-spacer />
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :color="accent"
                          icon
                          @click="showPicker(2)"
                          v-bind="attrs"
                          v-on="on"
                          class="iconButton mx-4"
                          :class="{
                            selectedIconButton:
                              showColorPicker == 2 ? true : false,
                          }"
                        >
                          <v-icon>mdi-marker</v-icon>
                        </v-btn>
                      </template>
                      <span v-if="displayLanguage == 'de'">Akzentfarbe</span>
                      <span v-else>Accent Color</span>
                    </v-tooltip>
                    <v-spacer />
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :color="font"
                          icon
                          @click="showPicker(3)"
                          v-bind="attrs"
                          v-on="on"
                          class="iconButton mx-4"
                          :class="{
                            selectedIconButton:
                              showColorPicker == 3 ? true : false,
                            darkBG:
                              font == '#ffffff' || font == '#FFFFFF'
                                ? true
                                : false,
                          }"
                        >
                          <v-icon>mdi-marker</v-icon>
                        </v-btn>
                      </template>
                      <span v-if="displayLanguage == 'de'">Schriftfarbe</span>
                      <span v-else>Font Color</span>
                    </v-tooltip>

                    <v-spacer />
                  </v-col>
                </v-row>
                <v-row v-if="addCourseSuccessful">
                  <v-spacer />
                  <v-col cols="10" style="display: flex; align-items: center">
                    <v-card width="100%">
                      <v-toolbar :color="main" class="mb-2">
                        <v-icon :color="accent" large>mdi-star</v-icon>
                        <v-icon :color="accent" large>mdi-star</v-icon>
                        <v-icon :color="accent" large>mdi-star</v-icon>
                      </v-toolbar>
                      <v-container fluid>
                        <v-row>
                          <v-spacer />
                          <v-col>
                            <v-btn :style="{
                                'background-color': main + '!important',
                              }" class="mb-2">
                              <div :style="{ color: font + '!important' }">
                                TEXT
                              </div>
                            </v-btn>
                          </v-col>
                          <v-spacer />
                          <v-spacer />
                          <v-col>
                            <v-btn
                              :style="{
                                'background-color': accent + '!important',
                              }"
                              class="mb-2"
                            >
                              <div :style="{ color: font + '!important' }">
                                TEXT
                              </div>
                            </v-btn>
                          </v-col>
                          <v-spacer />
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-col>
                  <v-spacer />
                </v-row>
                <v-row justify="center" align="center" v-if="showColorPicker">
                  <v-spacer />
                  <v-col cols="auto" style="display: flex; align-items: center">
                    <v-color-picker
                      dot-size="10"
                      v-model="color"
                      hide-inputs
                      hide-mode-switch
                    ></v-color-picker>
                  </v-col>
                  <v-spacer />
                </v-row>
                <v-row
                  style="color: red"
                  class="pa-0"
                  v-if="colorRules && isValidated"
                >
                  <v-spacer />
                  <v-col
                    cols="auto"
                    class="pa-0"
                    v-if="displayLanguage == 'de'"
                  >
                    Wähle alle Farben aus!
                  </v-col>
                  <v-col cols="auto" class="pa-0" v-else>
                    Please select all colors!
                  </v-col>
                  <v-spacer />
                </v-row>
                <v-row
                  justify="center"
                  align="center"
                  v-if="addCourseSuccessful"
                >
                  <v-col cols="5">
                    <v-btn
                      v-if="isEditView"
                      @click="editCourse()"
                      large
                      block
                      color="primary"
                      elevation="2"
                    >
                      {{ $t("message.change") }}
                    </v-btn>
                    <v-btn
                      v-else
                      @click="addNewCourse()"
                      large
                      block
                      color="primary"
                      elevation="2"
                    >
                      {{ $t("message.add") }}
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row
                  justify="center"
                  align="center"
                  v-if="!addCourseSuccessful"
                >
                  <v-col cols="5">
                    <v-btn
                      @click="backToCourses()"
                      large
                      block
                      color="primary"
                      elevation="2"
                    >
                      {{ $t("message.back") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
const axios = require("axios");
var moment = require("moment");

export default {
  name: "newCourse",

  data() {
    return {
      startDate: "",
      endDate: "",
      startDateFormatted: "",
      endDateFormatted: "",
      title: "",
      language: "",
      badge: "",
      selectedUser: localStorage.getItem("name"),
      selectedCustomer: localStorage.getItem("id"),
      items: ["EN", "DE"],
      badges: [],
      addCourseSuccessful: true,
      titleRules: [
        (v) =>
          !!v ||
          (localStorage.getItem("lang") == "de"
            ? "Titel angeben!"
            : "Specify title!"),
      ],
      languageRules: [
        (v) =>
          !!v ||
          (localStorage.getItem("lang") == "de"
            ? "Sprache angeben!"
            : "Enter language!"),
      ],
      dateRules: [
        (v) =>
          !!v ||
          (localStorage.getItem("lang") == "de"
            ? "Startdatum angeben!"
            : "Specify start date!"),
      ],
      endDateRules: [
        (v) =>
          !!v ||
          (localStorage.getItem("lang") == "de"
            ? "Enddatum angeben!"
            : "Specify end date!"),
        (v) =>
          (v &&
            (moment(this.endDate).isAfter(this.startDate) ||
              this.endDate == this.startDate)) ||
          (localStorage.getItem("lang") == "de"
            ? "Enddatum muss nach Startdatum sein!"
            : "End date must be after start date!"),
      ],
      badgesRules: [
        (v) =>
          !!v ||
          (localStorage.getItem("lang") == "de"
            ? "Badges angeben!"
            : "Specify Badges!"),
      ],

      isValidated: false,

      displayLanguage: localStorage.getItem("lang"),

      // index color picker
      showColorPicker: 0, //0 means hidden

      type: "hex",
      hex: "#FF00FF",

      main: "#eb5930",
      accent: "#ff997d",
      font: "#ffffff",

      courseData: undefined, //this view is also "edit course view"
      isEditView: false,
    };
  },

  watch: {
    startDate: function (val) {
      let temp = val.split("-");
      this.startDateFormatted = temp[2] + "." + temp[1] + "." + temp[0];
    },
    endDate: function (val) {
      let temp = val.split("-");
      this.endDateFormatted = temp[2] + "." + temp[1] + "." + temp[0];
    },
  },

  computed: {
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 7;
        case "md":
          return 6;
        case "lg":
          return 4;
        default:
          return 3;
      }
    },
    color: {
      get() {
        let val;
        switch (this.showColorPicker) {
          case 1:
            val = this.main;
            break;
          case 2:
            val = this.accent;
            break;
          case 3:
            val = this.font;
            break;
          default:
            val = this.main;
            break;
        }

        // this["hex"] = val;
        return val;
      },
      set(v) {
        this.isValidated = false;
        switch (this.showColorPicker) {
          case 1:
            this["hex"] = v;
            this.main = v;
            break;
          case 2:
            this["hex"] = v;
            this.accent = v;
            break;
          case 3:
            this["hex"] = v;
            this.font = v;
            break;
          default:
            return;
        }
      },
    },
    colorRules() {
      if (this.main != "" && this.accent != "" && this.font != "") {
        return false;
      }

      return true;
    },
  },

  methods: {
    changeLanguage(i) {
      this.language = i;
    },

    showPicker(index) {
      if (this.showColorPicker == index) {
        this.showColorPicker = 0;
      } else {
        this.showColorPicker = index;
      }
    },

    addNewCourse() {
      let typeID;

      for (let i = 0; i < this.badges.length; i++) {
        if (this.badges[i].name == this.badge) {
          typeID = this.badges[i].id;
          break;
        }
      }

      const formData = {
        users: [this.selectedCustomer],
        customer: this.selectedCustomer,
        startDate: this.startDate,
        title: this.title,
        endDate: this.endDate,
        language: this.language,
        mainColor: this.main,
        accentColor: this.accent,
        fontColor: this.font,
        type: typeID,
      };
      if (this.$refs.courseForm.validate()) {
        this.isValidated = true;

        axios
          .post(axios.defaults.baseURL + "courses/simple/", formData)
          .then(() => {
            this.$router.push("/overview/courses");
            this.addCourseSuccessful = true;
          })
          .catch((error) => {
            this.addCourseSuccessful = false;
            console.log(error);
          });
      } else {
        this.isValidated = true;
      }
    },

    editCourse() {
      let typeID;

      for (let i = 0; i < this.badges.length; i++) {
        if (this.badges[i].name == this.badge) {
          typeID = this.badges[i].id;
          break;
        }
      }

      const formData = {
        startDate: this.startDate,
        title: this.title,
        endDate: this.endDate,
        language: this.language,
        mainColor: this.main,
        accentColor: this.accent,
        fontColor: this.font,
        id: this.courseData.id,
        type: typeID,
      };

      if (this.$refs.courseForm.validate()) {
        this.isValidated = true;

        axios
          .patch(
            axios.defaults.baseURL +
              "courses/simple/" +
              this.courseData.id +
              "/",
            formData
          )
          .then(() => {
            this.$router.push("/overview/courses/" + this.courseData.id);
            this.addCourseSuccessful = true;
          })
          .catch((error) => {
            this.addCourseSuccessful = false;
            console.log(error);
          });
      } else {
        this.isValidated = true;
      }
    },
    backToCourses() {
      this.$router.push("/overview/courses");
    },
  },

  mounted() {
    this.language = this.displayLanguage.toUpperCase();

    axios.get(axios.defaults.baseURL + "courseTypes/").then((resp) => {
      this.badges = resp.data;
    });

    if (this.$route.params.id != undefined) {
      //edit view
      axios
        .get(axios.defaults.baseURL + "courses/" + this.$route.params.id)
        .then((resp) => {
          this.courseData = resp.data;

          this.selectedCustomer = this.courseData.customer;
          this.startDate = this.courseData.startDate;
          this.endDate = this.courseData.endDate;
          this.title = this.courseData.title;
          this.language = this.courseData.language.toUpperCase();
          this.main = this.courseData.mainColor;
          this.accent = this.courseData.accentColor;
          this.font = this.courseData.fontColor;
          this.badge = this.courseData.type.name;

          this.$refs.courseForm.validate();

          this.isEditView = true;
        });
    }
  },
};
</script>