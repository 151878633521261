<template>
  <div class="ma-6">
    <h2>Best performing Teams</h2>
  </div>
</template>

<script>

export default {

  name: "Leaderboard",
  
  components: {
  },

  data() {
    return {
      loaded: false,
      topTeams: null,
      styles: {
        width: "90vw",
        height: "400px",
        position: "relative"
      }
    };
  },

  created() {
    let data = {
      "topTeams": [
        { "name": "Team von Kurs xyz", "correctPackages": 76, "incorrectPackages": 1 },
        { "name": "anderes Team", "correctPackages": 55, "incorrectPackages": 2 },
        { "name": "noch ein Team", "correctPackages": 34, "incorrectPackages": 7 },
        { "name": "weitere", "correctPackages": 22, "incorrectPackages": 11 },
        { "name": "Teamnamen", "correctPackages": 12, "incorrectPackages": 5 },
        { "name": "folgen", "correctPackages": 5, "incorrectPackages": 7 }
      ]
    }

    this.topTeams = data.topTeams;
    this.loaded = true;
  }

};

</script>