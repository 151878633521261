<template>
  <div id="faqZone" style="height: 70vh">
    <v-container fluid>
      <v-row>
        <v-spacer />
        <v-col cols="auto">
          <v-btn @click="backToDashboard()" class="white--text mr-">{{
            $t("message.back")
          }}</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col :cols="cols">
          <v-card class="mx-4 rounded-xl" @click="gotoTechnicalSupport()">
            <v-img
              height="300px"
              src="../assets/images/OPTIONS.png"
              class="primary--text align-end imageCover"
            >
            </v-img>
            <v-card-title text-no-wrap text-truncate>
              {{ $t("message.technicalSupport") }}
            </v-card-title>
          </v-card>
        </v-col>

        <v-col :cols="cols">
          <v-card class="mx-4 rounded-xl" @click="gotoQuestionArea()">
            <v-img
              height="300px"
              class="primary--text align-end imageCover"
              src="../assets/images/Brett.png"
            ></v-img>
            <v-card-title text-no-wrap text-truncate>
              {{ $t("message.questionsArea") }}
            </v-card-title>
          </v-card>
        </v-col>

        <v-col :cols="cols">
          <v-card class="mx-4 rounded-xl" @click="gotoWorkInstructions()">
            <v-img
              height="300px"
              class="primary--text align-end"
              src="../assets/images/WORKINSTRUCTIONS.png"
            ></v-img>
            <v-card-title text-no-wrap text-truncate>
              WORK INSTRUCTIONS
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "faqZone",

  data() {
    return {};
  },
  computed: {
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 7;
        case "md":
          return 5;
        case "lg":
          return 3;
        default:
          return 2;
      }
    },
  },

  methods: {
    backToDashboard() {
      this.$router.push("/overview");
    },
    gotoTechnicalSupport() {
      this.$router.push("/overview/faq/technicalsupport");
    },

    gotoQuestionArea() {
      this.$router.push("/overview/faq/questionarea");
    },

    gotoWorkInstructions() {
      this.$router.push("/overview/faq/workInstructions");
    },
  },
};
</script>
