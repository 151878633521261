<template>
  <div id="overviewComponent" style="height: 70vh">

    <!-- <maintenanceComponent /> -->

    <v-container fill-height :fluid="maxWidth">
      <v-row align="center" justify="center">
        <v-col :cols="cols">
          <v-card class="mx-4 rounded-xl" @click="gotoCourses">
            <v-img
              src="../assets/images/UMS_TrainerBackend_KURSE_Pic.png"
              height="300px"
              class="primary--text align-end imageCover"
            >
            </v-img>
            <v-divider />
            <v-card-title text-no-wrap text-truncate>
              {{ $t("message.course") }}
            </v-card-title>
          </v-card>
        </v-col>

        <v-col :cols="cols">
          <v-card class="mx-4 rounded-xl" @click="gotoStartGame">
            <v-img
              src="../assets/images/startBackground.png"
              height="300px"
              class="primary--text align-end imageCover"
            ></v-img>
            <v-divider />
            <v-card-title text-no-wrap text-truncate>
              {{ $t("message.startPlay") }}
            </v-card-title>
          </v-card>
        </v-col>

        <v-col :cols="cols">
          <v-card class="mx-4 rounded-xl" @click="gotoFAQ">
            <v-img
              src="../assets/images/Lupe.png"
              height="300px"
              class="primary--text align-end imageCover"
            >
            </v-img>
            <v-divider />
            <v-card-title text-no-wrap text-truncate>
              {{ $t("message.faqZone") }}
            </v-card-title>
          </v-card>
        </v-col>
        <v-col :cols="cols" v-if="isHeaderTrainerPlus">
          <v-card class="mx-4 rounded-xl" @click="gotoTrainers">
            <v-img
              src="../assets/images/trainers.png"
              height="300px"
              class="primary--text align-end imageCover"
            ></v-img>
            <v-card-title text-no-wrap text-truncate>
              {{ $t("message.trainer") }}
            </v-card-title>
          </v-card>
        </v-col>

        <v-col :cols="cols" v-if="isHeaderTrainerPlus">
          <v-card class="mx-4 rounded-xl" @click="gotoTickets">
            <v-img
              src="../assets/images/icon_tickets.png"
              height="300px"
              class="primary--text align-end imageCover"
            ></v-img>
            <v-card-title text-no-wrap text-truncate> TICKETS </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import * as userTypeHelper from "../plugins/userTypeHelper.js";
// import maintenanceComponent from "./Maintenance.vue"

export default {
  name: "overviewComponent",

  data() {
    return {
      
    };
  },

  components: {
    // maintenanceComponent
  },

  computed: {
    isHeaderTrainerPlus() {
      let temp = userTypeHelper.checkHeadTrainerPlusPerm();
      if (temp) {
        this.$forceUpdate();
      }

      return temp;
    },
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 7;
        case "md":
          return 5;
        case "lg":
          return 4;
        default:
          return 4;
      }
    },
    maxWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    gotoCourses() {
      this.$router.push("/overview/courses");
    },
    gotoStartGame() {
      this.$router.push("/overview/game");
    },
    gotoLeaderboards() {
      this.$router.push("/overview/leaderboards");
    },
    gotoTrainers() {
      this.$router.push("/overview/trainers");
    },
    gotoFAQ() {
      this.$router.push("/overview/faq");
    },
    gotoTickets() {
      this.$router.push("/overview/tickets");
    },
  },

  mounted() {
    if (userTypeHelper.checkHeadTrainerPlusPerm()) {
      this.$forceUpdate();
    }
  },
};
</script>
