<template>
  <div id="login">
    <v-app id="loginBackgroundImage">

        <v-container fluid class="ma-auto" >
          <v-row md="auto" justify="center" no-gutters>
            <v-spacer v-if="disableSpacing < 1" />
            <v-spacer v-if="disableSpacing < 2"/>
            <v-spacer v-if="disableSpacing == 0"/>
            <v-col :cols="cols" class="pa-0">
              <v-container fluid>
                <v-row>
                  <v-col class="pa-0">
                    <v-img
                      id="loginLogo"
                      src="../assets/images/LOGO.svg"
                    ></v-img>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-spacer v-if="disableSpacing < 2" />
          </v-row>

          <v-form ref="form" lazy-validation>
            <v-row md="auto" justify="center" no-gutters>
              <v-spacer v-if="disableSpacing < 1" />
              <v-spacer v-if="disableSpacing < 2"/>
              <v-spacer v-if="disableSpacing == 0"/>
              <v-col :cols="cols">
                <v-card
                  style="background-color: white !important"
                  class="my-4 pt-2 px-4 rounded-xl"
                  align="center"
                >
                  <v-text-field
                    id="email"
                    :rules="emailRules"
                    label="E-Mail"
                    v-model="email"
                    required
                  ></v-text-field>
                </v-card>
                <v-card
                  style="background-color: white !important"
                  class="my-4 pt-2 px-4 rounded-xl"
                  align="center"
                >
                  <v-text-field

                    id="password"
                    :rules="passwordRules"
                    :type="showEyeOpen ? 'password' : 'regular'"
                    label="Passwort"
                    v-model="password"
                    required
                    :append-icon="showEyeOpen ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="toggleShowPassword"
                  >
                  </v-text-field>
                </v-card>
              </v-col>
            <v-spacer v-if="disableSpacing < 2"/>
            </v-row>

            <v-row md="auto" justify="center" no-gutters>
              <v-spacer v-if="disableSpacing < 1" />
              <v-spacer v-if="disableSpacing < 2"/>
              <v-spacer v-if="disableSpacing == 0"/>
              <v-col :cols="cols">
                <v-card
                  class="pa-2"
                  background-color="unset"
                  flat
                  align="center"
                >
                  <v-btn
                    @click="submit"
                    block
                    depressed
                    large
                    color="primary"
                    elevation="2"
                    >anmelden</v-btn
                  >
                </v-card>
              </v-col>
            <v-spacer v-if="disableSpacing < 2"/>
            </v-row>
          </v-form>

          <v-row md="auto" justify="center" no-gutters>
            <v-spacer v-if="disableSpacing < 1" />
            <v-spacer v-if="disableSpacing < 2"/>
            <v-spacer v-if="disableSpacing == 0"/>
            <v-col :cols="cols">
              <v-card
                class="pa-2"
                background-color="rgba(0,0,0,0)"
                flat
                align="center"
              >
                <a :href="url" target="_blank">Password vergessen?</a>
              </v-card>
            </v-col>
            <v-spacer v-if="disableSpacing < 2"/>
          </v-row>
        </v-container>
    </v-app>
  </div>
</template>

<script>
export default {
  name: "login",

  data() {
    return {
      isValid: true,
      email: "",
      emailRules: [
        (v) => (v && this.isValid) || "E-Mail oder Passwort falsch!",
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      password: "",
      passwordRules: [
        (v) => (v && this.isValid) || "E-Mail oder Passwort falsch!",
      ],
      url: 'https://db.umsjourfixe.de/accounts/password_reset/',
      // url: 'http://localhost:8000/accounts/password_reset/',
      showEyeOpen: true,
    };
  },

   computed: {
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 8;
        case "md": 
          return 8;
        case "lg": 
          return 4;
        case "xl": 
          return 3;
        default:
          return 4;
      }
    },

    disableSpacing() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 2;
        case "sm":
          return 1;
        case "md": 
          return 1;
        default:
          return 0;
      }
    },
  },

  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    submit() {
      this.email = this.email.toLowerCase();
      const formData = {
        email: this.email,
        password: this.password,
      };
      this.$store.dispatch("loginUser", formData)
      .catch((err => {
        if(err.response && err.response.status == 401){
          this.isValid = false
          this.$refs.form.validate()
        }
      }));
    },
    toggleShowPassword(){
      this.showEyeOpen = !this.showEyeOpen;
      

    }
  },
  mounted() {
    window.addEventListener("keypress", (e) => {
      if(e.key == "Enter") this.submit()
    })
  },
};
</script>
