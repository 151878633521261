import axios from 'axios';
import store from '../store'

const max_requests = 3
var pending_requests = 0

export default function setup() {
    axios.interceptors.response.use(function(config) {
        const token = store.state.accessToken;
        if(token) {
            pending_requests = 0;
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    }, function(err) {
        const token = store.state.refreshToken;
        const originalRequest = err.config;

        // Reject promise if usual error
        if (token && err.response && err.response.status == 403 && pending_requests < max_requests) {
            pending_requests++

            return store.dispatch("refreshToken").then(() => {
                originalRequest._retry = true;
                originalRequest.headers['Authorization'] = 'Bearer ' + store.state.accessToken;
                return axios(originalRequest);
            }) 
            
        }
        else if(token && err.response.status == 403 && pending_requests >= max_requests){
            store.dispatch("logoutUser");
        }

        return Promise.reject(err);
    });
}