//TODO: use axios to get this stuff, do it dynamically!
export function checkTrainerPlusPerm() {
    const userType = localStorage.getItem("userType");
    if (userType == "trainer" || userType == "headTrainer" || userType == "masterTrainer" || userType == "admin") {
        return true;
    }

    return false;
}

export function checkHeadTrainerPlusPerm() {
    const userType = localStorage.getItem("userType");
    if (userType == "headTrainer" || userType == "masterTrainer" || userType == "admin") {
        return true;
    }

    return false;
}

export function checkMasterTrainerPlusPerm() {
    const userType = localStorage.getItem("userType");
    if (userType == "masterTrainer" || userType == "admin") {
        return true;
    }

    return false;
}

export function checkAdminPerm() {
    const userType = localStorage.getItem("userType");
    if (userType == "admin") {
        return true;
    }

    return false;
}

export function checkTrainerAndLowerPerm() {
    const userType = localStorage.getItem("userType");
    if (userType == "trainer") {
        return true;
    }

    return false;
}

export function checkHeadTrainerAndLowerPerm() {
    const userType = localStorage.getItem("userType");
    if (userType == "trainer" || userType == "headTrainer") {
        return true;
    }

    return false;
}

export function checkMaserTrainerAndLowerPerm() {
    const userType = localStorage.getItem("userType");
    if (userType == "masterTrainer" || userType == "headTrainer" || userType == "trainer") {
        return true;
    }

    return false;
}

export function checkAdminAndLowerPerm() {
    const userType = localStorage.getItem("userType");
    if (userType == "trainer" || userType == "headTrainer" || userType == "masterTrainer" || userType == "admin") {
        return true;
    }

    return false;
}

