<template>
  <div>
    <v-container fluid class="mt-4">
      <v-row class="mb-4">
        <v-col :cols="cols * 2">
          <v-card>
            <v-card-title>{{ $t("message.average") }} </v-card-title>

            <v-card-text>
              {{ $t("message.gameLiked") }}:
              <starComponent :num="avgReview.stars[0] || 0" />
              {{ $t("message.fun") }} :
              <starComponent :num="avgReview.stars[1] || 0" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-divider />

      <v-row class="mt-4">
        <v-col v-for="review in reviews" :key="review.id" :cols="cols">
          <v-card>
            <v-card-title
              >{{ $t("message.from") }}:
              {{ review.created | moment("DD.MM.YY") }}
              <v-spacer />
              <v-btn dark @click="gotoNewTicket(review.additional)">Make Ticket!</v-btn>
            </v-card-title>

            <v-card-text>
              {{ $t("message.gameLiked") }}:
              <starComponent :num="review.stars[0] || 0" />
              {{ $t("message.fun") }}:
              <starComponent :num="review.stars[1] || 0" />

              <br />
              <v-divider />
              " {{ review.additional }} "

  <div style="display: flex">
              <p class="pr-2" v-if="review.player_name">{{review.player_name}}</p>
              <p v-if="review.player_role">{{review.player_role}}</p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import starComponent from "../../components/StarComponent.vue";

const axios = require("axios");

export default {
  name: "courseReview",

  data() {
    return {
      reviews: [],
      avgReview: {
        stars: [0, 0],
      },
    };
  },

  components: {
    starComponent,
  },

  computed: {
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 6;
        case "md":
          return 6;
        case "lg":
          return 3;
        case "xl":
          return 2;
        default:
          return 4;
      }
    },
  },

  methods: {
    gotoNewTicket(val){
      this.$router.push("/overview/faq/technicalsupport/?text=" + val)
    }
  },

  mounted() {
    axios
      .get(
        axios.defaults.baseURL +
          "ReviewsByCourse/?course=" +
          this.$route.params.id
      )
      .then((resp) => {
        this.reviews = resp.data;

        let stars0 = 0;
        let stars1 = 0;

        for (let i = 0; i < this.reviews.length; i++) {
          this.reviews[i].stars = this.reviews[i].stars.split(" ");

          if (parseInt(this.reviews[i].stars[0]))
            stars0 += parseInt(this.reviews[i].stars[0]);

          if (parseInt(this.reviews[i].stars[1]))
            stars1 += parseInt(this.reviews[i].stars[1]);
        }

        stars0 = Math.floor(stars0 / this.reviews.length);
        stars1 = Math.floor(stars1 / this.reviews.length);

        this.avgReview.stars = [stars0, stars1];
      });
  },
};
</script>
