<template>
  <div id="financeKPI">
    <div id="finance_kpi" class="tabcontent">
      <table class="tg">
        <tbody>
          <tr>
            <td class="transparentBox"></td>
            <td class="greyBox font bold alignCenter">Round 1</td>
            <td class="greyBox font bold alignCenter">Round 2</td>
            <td class="greyBox font bold alignCenter">Round 3</td>
          </tr>
          <tr id="invoicedRow">
            <td class="main whiteFont font bold alignCenter">
              Parcels Invoiced
            </td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr id="deliveredRow">
            <td class="main whiteFont font bold alignCenter">
              Parcels Delivered
            </td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr id="turnoverRow">
            <td class="main whiteFont font bold alignCenter">Turnover</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr id="costRow">
            <td class="main whiteFont font bold alignCenter">Costs</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
          <tr id="totalRow">
            <td class="accent whiteFont font bold alignCenter">Total</td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
            <td class="inputBox"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <GChart
      v-if="tableData.rounds"
      :settings="{ packages: ['corechart'] }"
      type="ComboChart"
      :data="collectData()"
      :options="options"
      id="gChart"
    />
  </div>
</template>

<script>
import { GChart } from "vue-google-charts";

export default {
  name: "financeKPI",

  props: ["options", "tableData"],

  data() {
    return {};
  },

  components: {
    GChart,
  },

  methods: {
    collectData() {
      //rent + equipment + sallaries

      if (!this.tableData.rounds || this.tableData.rounds.length == 0) return;

      let data = [];
      let sortingHelper = [
        this.tableData.round1,
        this.tableData.round2,
        this.tableData.round3,
      ];
      for (let i = 0; i < this.tableData.rounds.length; i++) {
        if (
          this.tableData.rounds[i].id == this.tableData.round1 ||
          this.tableData.rounds[i].id == this.tableData.round2 ||
          this.tableData.rounds[i].id == this.tableData.round3
        ) {
          data[sortingHelper.indexOf(this.tableData.rounds[i].id)] =
            this.tableData.rounds[i].data;
        }
      }

      let costs;
      if (data[0] && data[0].billingData)
        costs =
          1000 +
          450 +
          data[0].billingData["CL"] * 100 +
          data[0].billingData["HD"] * 150;
      else costs = 1600;

      let round1 = ["1", 0, 0, 0, 0, 0];
      let round2 = ["2", 0, 0, 0, 0, 0];
      let round3 = ["3", 0, 0, 0, 0, 0];

      for (let i = 0; i < data.length; i++) {
        let turnover = 0;
        for (let j = 0; j < data[i].finishedParcels.length; j++) {
          if (!isNaN(parseInt(data[i].finishedParcels[j].slots[2]))) {
            turnover += parseInt(data[i].finishedParcels[j].slots[2]);
          } else {
            turnover += 50;
          }
        }

        let totalCost = (costs + (data[i].additionalCosts ? data[i].additionalCosts : 0));

        switch (i) {
          case 0:
            round1 = [
              "1",
              totalCost,
              turnover,
              turnover - totalCost,
              data[i].invoicedParcels,
              data[i].finishedParcels.length,
            ];
            break;
          case 1:
            round2 = [
              "2",
              -1 *
                (totalCost +
                  (data[i].additionalCosts ? data[i].additionalCosts : 0)),
              turnover,
              turnover - totalCost,
              data[i].invoicedParcels,
              data[i].finishedParcels.length,
            ];
            break;
          case 2:
            round3 = [
              "3",
              -1 *
                (totalCost +
                  (data[i].additionalCosts ? data[i].additionalCosts : 0)),
              turnover,
              turnover - totalCost,
              data[i].invoicedParcels,
              data[i].finishedParcels.length,
            ];
            break;
        }
      }

      let result = [
        ["Rounds", "Kosten", "Umsatz", "Summe", "Invoiced", "Delivered"],
        round1,
        round2,
        round3,
      ];

      for (let j = 1; j <= data.length; j++) {
        this.setupFinanceTable(result, j, j == data.length ? true : false);
      }

      return result;
    },
    setupFinanceTable(dataTable, roundNumber, currentRound) {
      //order of datatable!!
      let htmlParents = [
        "costRow",
        "turnoverRow",
        "totalRow",
        "invoicedRow",
        "deliveredRow",
      ];

      for (let i = 0; i < htmlParents.length; i++) {
        if (currentRound)
          document.getElementById(htmlParents[i]).children[
            roundNumber
          ].innerHTML = "<b>" + dataTable[roundNumber][i + 1] + "</b>";
        else
          document.getElementById(htmlParents[i]).children[
            roundNumber
          ].innerHTML = dataTable[roundNumber][i + 1];
      }
    },
  },
};
</script>
